import { MessageBarType } from '@fluentui/react';
import { useMediaQuery, useToast } from '@h2oai/ui-kit';
import { useCallback, useEffect, useState } from 'react';

import { Alias, AppInstance, AppInstance_Visibility, UpdateAppInstanceRequest } from '../../ai.h2o.cloud.appstore';
import { RoutePaths } from '../../pages/Routes';
import { AdminAliasService, AdminAppService, AppService } from '../../services/api';
import { useError, useInstance } from '../../utils/hooks';
import { InstanceListType } from '../../utils/models';
import { InstancePauseResumeMap, InstancePauseResumeOpEnum, handleErrMsg } from '../../utils/utils';
import { InstanceList } from '../InstanceList/InstanceList';
import ListPage from '../ListPages/ListPage';
import { InstanceSearchAndFilter } from './InstanceSearchAndFilter';

interface InstanceListPageProps {
  type: InstanceListType;
}

export default function InstanceListPage(props: InstanceListPageProps) {
  const { type } = props,
    { isDesktop } = useMediaQuery(),
    [instances, setInstances] = useState<AppInstance[]>(),
    [refinedInstances, setRefinedInstances] = useState<AppInstance[]>(),
    [aliases, setAliases] = useState<Alias[]>(),
    [loading, setLoading] = useState(false),
    [loadingInstance, setLoadingInstance] = useState<[string, boolean]>(),
    [terminatingInstance, setTerminatingInstance] = useState<[string, boolean]>(),
    [err, setErr] = useError(),
    { getInstancesList, getAdminInstancesList, setInstanceSuspension, terminateInstance } = useInstance(),
    { addToast } = useToast(),
    isAdmin = type === InstanceListType.admin,
    getUserInstanceList = isAdmin ? getAdminInstancesList : getInstancesList,
    loadInstances = useCallback(
      async (id?: string) => {
        if (!id) setLoading(true);
        try {
          if (isAdmin) {
            const { aliases } = await AdminAliasService.listAliases({ instanceId: '', parent: '' });
            setAliases(aliases);
          }
          const appInstances = await getUserInstanceList({
            appId: '',
            includeAppDetails: true,
            visibility: AppInstance_Visibility.VISIBILITY_UNSPECIFIED,
            allUsers: isAdmin,
            allWorkspaces: false,
            parent: '',
          });
          setInstances(appInstances);
        } catch (error: unknown) {
          if (error instanceof Error) setErr({ ...error });
        } finally {
          setLoading(false);
        }
      },
      [setErr, getInstancesList]
    ),
    setSuspension = useCallback(
      (appInstance: AppInstance, opEnum: InstancePauseResumeOpEnum) => async () => {
        const { id } = appInstance;
        const op = InstancePauseResumeMap.get(opEnum);
        if (!op) return;
        setLoadingInstance([id, true]);
        try {
          await setInstanceSuspension(appInstance, op, isAdmin);
          await loadInstances(id);
          addToast({
            message: `${op.completedDescription} app instance with id ${id}`,
            messageBarType: MessageBarType.success,
          });
        } catch (error: any) {
          addToast({
            message: `Could not ${op.description} app instance: ${handleErrMsg(error?.message as string)}`,
            messageBarType: MessageBarType.error,
          });
        } finally {
          setLoadingInstance([id, false]);
        }
      },
      [loadInstances, setInstanceSuspension]
    ),
    terminate = useCallback(
      (instance: AppInstance) => async () => {
        const { id } = instance;
        setTerminatingInstance([id, true]);
        try {
          await terminateInstance(instance, isAdmin);
          await loadInstances(id);
          addToast({ message: `Terminated app instance with id ${id}`, messageBarType: MessageBarType.success });
        } catch (error: any) {
          addToast({
            message: `Could not terminate app instance: ${handleErrMsg(error?.message as string)}`,
            messageBarType: MessageBarType.error,
          });
        } finally {
          setTerminatingInstance([id, false]);
        }
      },
      [loadInstances, terminateInstance]
    ),
    updateVisibility = useCallback(
      (updatedInstance: UpdateAppInstanceRequest) => async () => {
        setLoadingInstance([updatedInstance.id, true]);
        try {
          if (isAdmin) {
            await AdminAppService.updateAppInstance(updatedInstance);
          } else if (type === InstanceListType.my) {
            await AppService.updateAppInstance(updatedInstance);
          }
          await loadInstances(updatedInstance.id);
          addToast({
            message: `Edited app instance with id ${updatedInstance.id}`,
            messageBarType: MessageBarType.success,
          });
        } catch (error: any) {
          addToast({
            message: `Could not update app instance visibility: ${handleErrMsg(error?.message as string)}`,
            messageBarType: MessageBarType.error,
          });
        } finally {
          setLoadingInstance([updatedInstance.id, false]);
        }
      },
      [loadInstances, addToast]
    );
  useEffect(() => {
    loadInstances();
  }, [loadInstances]);

  return (
    <ListPage
      copy={{
        title: isAdmin ? 'Admin Instances' : type === InstanceListType.my ? 'My Instances' : '',
        subtitle: `${isAdmin ? 'There are' : 'You have'} ${refinedInstances?.length || 0} instances`,
        noDataTitle: 'No instances found',
        noDataMessage: '',
        loadingMessage: 'Loading App Instances...',
        noFilterResultsMessage: 'No instances match these filters',
        searchPlaceholder: isDesktop ? 'Search by app name, title, ID, version, description' : 'Search Engine',
      }}
      error={err?.message}
      loading={loading}
      primaryButtonProps={
        isAdmin
          ? {
              text: 'Manage Aliases',
              href: RoutePaths.ADMIN_ALIASES,
            }
          : undefined
      }
      showSearchInput={false}
      showNoDataPage={instances?.length === 0}
    >
      <InstanceSearchAndFilter instances={instances || []} onRefine={(data) => setRefinedInstances(data)} />
      <InstanceList
        aliases={isAdmin ? aliases : undefined}
        instances={refinedInstances}
        loadingInstance={loadingInstance}
        terminatingInstance={terminatingInstance}
        type={type}
        terminateInstance={terminate}
        setInstanceSuspension={setSuspension}
        updateVisibility={updateVisibility}
      />
    </ListPage>
  );
}
