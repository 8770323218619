import type { IH2OTheme } from '@h2oai/ui-kit';

export const manageWorkspacesWrapperStyles = (theme: IH2OTheme) => ({
  root: {},
  table: {
    'td:not(:first-child)': {
      textAlign: 'center',
      maxWidth: 80,
    },
    'td:first-child': {
      width: '40%',
      fontSize: 14,
      fontWeight: 400,
      color: theme.palette?.gray500,
    },
    tbody: {
      'tr:not(:last-child)': {
        'td + td': {
          borderBottom: `1px solid ${theme.palette?.gray300}`,
        },
      },
      td: {
        minWidth: 40,
        paddingTop: 8,
        paddingBottom: 8,
      },
      'td + td:not(:last-child)': {
        position: 'relative',
        ':after': {
          content: '""',
          width: 1,
          height: '50%',
          position: 'absolute',
          left: '100%',
          top: '50%',
          backgroundColor: theme.palette?.gray300,
          transform: 'translateY(-50%)',
        },
      },
    },
  },
});
