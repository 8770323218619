import { fetchWrapRPC } from '../services/api';
import {
  DAIEngineProfileService_GetDAIEngineProfile,
  DAIEngineProfileService_ListDAIEngineProfiles,
  GetDAIEngineProfileRequest,
  ListDAIEngineProfilesRequest,
} from './gen/ai/h2o/engine/v1/dai_engine_profile_service_pb';
import {
  GetH2OEngineProfileRequest,
  H2OEngineProfileService_GetH2OEngineProfile,
  H2OEngineProfileService_ListH2OEngineProfiles,
  ListH2OEngineProfilesRequest,
} from './gen/ai/h2o/engine/v1/h2o_engine_profile_service_pb';

export class EngineProfilesServiceImpl {
  private basePath: string;
  constructor(basePath?: string) {
    this.basePath = basePath || '';
  }

  public setBasePath = (path: string) => {
    this.basePath = path;
  };

  public async listDAIEngineProfiles(req: ListDAIEngineProfilesRequest) {
    return await fetchWrapRPC(DAIEngineProfileService_ListDAIEngineProfiles, { basePath: this.basePath })({ ...req });
  }

  public async getDAIEngineProfile(req: GetDAIEngineProfileRequest) {
    return await fetchWrapRPC(DAIEngineProfileService_GetDAIEngineProfile, { basePath: this.basePath })({ ...req });
  }

  public async listH2OEngineProfiles(req: ListH2OEngineProfilesRequest) {
    return await fetchWrapRPC(H2OEngineProfileService_ListH2OEngineProfiles, { basePath: this.basePath })({ ...req });
  }

  public async getH2OEngineProfile(req: GetH2OEngineProfileRequest) {
    return await fetchWrapRPC(H2OEngineProfileService_GetH2OEngineProfile, { basePath: this.basePath })({ ...req });
  }
}
