import { PanelType, Stack } from '@fluentui/react';
import { Panel, TextField } from '@h2oai/ui-kit';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { stylesPanel } from '../../../components/AIEnginesPage/components/AIEMPanel/AIEMPanel';
import { EditablePanelFooter } from '../../../components/EditablePanelFooter/EditablePanelFooter';

type SecretEditPanelProps = {
  onDismiss: () => void;
  onSave: (secretValue: string, secretId: string) => void;
  checkIfExist: (secretName: string) => Promise<boolean>;
  isOpen: boolean;
};

export const SecretEditPanel = (props: SecretEditPanelProps) => {
  const [secretVersionValue, setSecretVersionValue] = useState<string>('');
  const [secretId, setSecretId] = useState<string>('');
  const [secretNameError, setSecretNameError] = useState<string>('');

  const validateSecret = async (value: string) => {
    if (!value) {
      setSecretNameError('');
      return;
    }

    const secretNameRegex = /^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$/;
    if (!secretNameRegex.test(value)) {
      setSecretNameError(`
        Secret name must start with a lowercase letter,
        contain only lowercase letters, numbers, or hyphens,
        and end with a letter or number (1-63 characters)
      `);
      return;
    }
    setSecretNameError('');

    const exists = await props.checkIfExist(value);

    if (exists) setSecretNameError('Secret with this ID exists');
  };

  const handleSecretIdChange = (_ev: any, value = '') => {
    setSecretId(value);
    debouncedValidation(value);
  };

  const debouncedValidation = useMemo(() => debounce((value: string) => void validateSecret(value), 300), []);

  useEffect(() => {
    return () => {
      debouncedValidation.cancel();
    };
  }, [debouncedValidation]);

  useEffect(() => {
    return () => {
      setSecretVersionValue('');
      setSecretId('');
      setSecretNameError('');
    };
  }, [props.isOpen]);

  return (
    <Panel
      isLightDismiss
      customWidth="500px"
      headerText="New Secret"
      isFooterAtBottom
      isOpen={props.isOpen}
      onDismiss={props.onDismiss}
      type={PanelType.custom}
      styles={stylesPanel}
    >
      <Stack horizontal>
        <TextField
          label="Secret ID"
          description="If not specified, the server will generate one."
          value={secretId}
          errorMessage={secretNameError}
          styles={{ root: { width: '100%' } }}
          onChange={handleSecretIdChange}
        />
      </Stack>
      <Stack horizontal>
        <TextField
          required
          label="Secret value"
          value={secretVersionValue}
          styles={{ root: { width: '100%' } }}
          onChange={(_ev, newValue) => setSecretVersionValue(newValue || '')}
        />
      </Stack>
      <EditablePanelFooter
        onCancel={props.onDismiss!}
        onSave={() => props.onSave(secretVersionValue, secretId)}
        closeButtonText="Cancel"
        saveButtonText="Save"
        saveButtonDisabled={!secretVersionValue.length || !!secretNameError}
      />
    </Panel>
  );
};
