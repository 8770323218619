import { ISpinButtonStyles, ITextStyles, Label, SpinButton, Stack, Text } from '@fluentui/react';
import { FontSizes, IH2OTheme, Sizes, useThemeStyles } from '@h2oai/ui-kit';
import { SyntheticEvent } from 'react';

import { LabelIconTooltip } from '../LabelIconTooltip/LabelIconTooltip';

const fluentNumberFieldDefault = (theme: IH2OTheme): Partial<ISpinButtonStyles> => ({
  spinButtonWrapper: {
    border: `1px solid ${theme.semanticColors?.inputBorder}`,
    borderRadius: Sizes.borderRadius,
    height: Sizes.height,
    ':hover': {
      borderColor: theme.semanticColors?.inputActiveBorder,
    },
    ':focus': {
      borderColor: theme.semanticColors?.inputActiveBorder,
    },
    '::after': {
      display: 'none',
    },
    '.is-active': { borderColor: theme.semanticColors?.inputActiveBorder },
  },
  input: {
    color: theme.semanticColors?.inputText,
    lineHeight: Sizes.height - 2,
    paddingLeft: Sizes.padding,
    paddingRight: Sizes.padding,
    '::placeholder': {
      color: theme.semanticColors?.inputPlaceholder,
    },
  },
  arrowButtonsContainer: { color: theme.semanticColors?.inputText },
});

const helpTextDefault = (theme: IH2OTheme): Partial<ITextStyles> => ({
  root: {
    color: theme.palette?.gray500,
    display: 'block',
    fontSize: FontSizes.xxsmall,
    position: 'absolute',
    right: 0,
    textAlign: 'right',
  },
});

export interface IFluentNumberFieldProps {
  disabled?: boolean;
  id?: string;
  fixed?: boolean;
  label?: string;
  min?: number;
  max?: number;
  onChange: (event: SyntheticEvent<HTMLElement, Event>, newValue?: string | undefined) => void;
  onIncrement?: (value: string, event: SyntheticEvent<HTMLElement, Event>) => void;
  onDecrement?: (value: string, event: SyntheticEvent<HTMLElement, Event>) => void;
  value?: string;
  tooltip?: string;
  styles?: any;
  step?: number;
  required?: boolean;
}

export const FluentNumberField = (props: IFluentNumberFieldProps) => {
  const { label, max, min, styles, tooltip, fixed, required } = props,
    fprops = {
      ...props,
      label: undefined,
      disabled: fixed ? true : props.disabled,
      ...{
        styles: useThemeStyles<Partial<ISpinButtonStyles>>(fluentNumberFieldDefault, styles),
      },
      onKeyDown: (e: any) => {
        // allow only backspace, tab, arrow keys and numbers
        if ([8, 9, 37, 38, 39, 40, 41].includes(e.keyCode)) return;
        if (!/^[0-9]+$/.test(e.key)) e.preventDefault();
      },
    };
  const textStyles = useThemeStyles<Partial<ITextStyles>>(helpTextDefault, {});

  return (
    <Stack>
      {label && (
        <>
          <Label styles={{ root: { position: 'relative' } }} required={required}>
            {!fixed ? (
              <Text styles={textStyles} data-test="aiem-fluent-number-field-help-text">
                {min !== undefined && `Min: ${min}`}
                {min !== undefined && max !== undefined && ' | '}
                {max !== undefined && `Max: ${max}`}
              </Text>
            ) : null}
            {tooltip ? <LabelIconTooltip label={label} tooltip={tooltip} /> : label}
          </Label>
        </>
      )}
      <SpinButton {...(fprops as any)} />
    </Stack>
  );
};
