import { Stack } from '@fluentui/react';
import { IPivotItem, PageHeader, Pivot } from '@h2oai/ui-kit';

import { daiEngineProfileEntity } from '../../aiem/entity/DAIEngineProfile.entity';
import { daiProfileEntity } from '../../aiem/entity/DAIProfile.entity';
import { defaultDAISetupEntity } from '../../aiem/entity/DefaultDAISetup.entity';
import { defaultH2OSetupEntity } from '../../aiem/entity/DefaultH2OSetup.entity';
import { h2oEngineProfileEntity } from '../../aiem/entity/H2OEngineProfile.entity';
import { daiVersionEntity } from '../../aiem/entity/InternalDAIVersion.entity';
import { h2oVersionEntity } from '../../aiem/entity/InternalH2OVersion.entity';
import { DAIEngineProfile } from '../../aiem/gen/ai/h2o/engine/v1/dai_engine_profile_pb';
import { DAIProfile } from '../../aiem/gen/ai/h2o/engine/v1/dai_profile_pb';
import { DefaultDAISetup } from '../../aiem/gen/ai/h2o/engine/v1/default_dai_setup_pb';
import { DefaultH2OSetup } from '../../aiem/gen/ai/h2o/engine/v1/default_h2o_setup_pb';
import { H2OEngineProfile } from '../../aiem/gen/ai/h2o/engine/v1/h2o_engine_profile_pb';
import { InternalDAIVersion } from '../../aiem/gen/ai/h2o/engine/v1/internal_dai_version_pb';
import { InternalH2OVersion } from '../../aiem/gen/ai/h2o/engine/v1/internal_h2o_version_pb';
import { stackStylesNoLeftNav } from '../../themes/themes';
import EntityDataContainer from './components/EntityDataContainer';
import { LargeEntityModelForm } from './components/LargeEntityModelForm';
import { ListWithCollapsedSettings } from './components/ListWithCollapsedSettings/ListWithCollapsedSettings';

export default function AIEMAdminSettingsPage() {
  const items: IPivotItem[] = [
    {
      key: `pivot-item-content-dai-profiles`,
      headerText: 'DAI Profiles',
      content: <EntityDataContainer<DAIProfile> entity={daiProfileEntity} />,
      loading: false,
    },
    {
      key: `pivot-item-content-dai-version`,
      headerText: 'DAI Version',
      content: <EntityDataContainer<InternalDAIVersion> entity={daiVersionEntity} />,
      loading: false,
    },
    {
      key: `pivot-item-content-h2o-version`,
      headerText: 'H2O Version',
      content: <EntityDataContainer<InternalH2OVersion> entity={h2oVersionEntity} />,
      loading: false,
    },
    {
      key: `pivot-item-content-default-dai-setup`,
      headerText: 'Default DAI Setup',
      content: <LargeEntityModelForm<DefaultDAISetup> entity={defaultDAISetupEntity} />,
      loading: false,
    },
    {
      key: `pivot-item-content-default-h2o-setup`,
      headerText: 'Default H2O Setup',
      content: <LargeEntityModelForm<DefaultH2OSetup> entity={defaultH2OSetupEntity} />,
      loading: false,
    },
    {
      key: `pivot-item-content-default-dai-engine-profile`,
      headerText: 'DAI Engine Profiles',
      content: <ListWithCollapsedSettings<DAIEngineProfile> entity={daiEngineProfileEntity} />,
      loading: false,
    },
    {
      key: `pivot-item-content-default-h2o-engine-profile`,
      headerText: 'H2O Engine Profiles',
      content: <ListWithCollapsedSettings<H2OEngineProfile> entity={h2oEngineProfileEntity} />,
      loading: false,
    },
  ];
  return (
    <Stack styles={stackStylesNoLeftNav}>
      <Stack horizontal horizontalAlign="space-between" style={{ marginBottom: '20px' }}>
        <Stack.Item disableShrink>
          <PageHeader pageTitle={'AI Engine Manager Admin Settings'} />
        </Stack.Item>
      </Stack>
      <Pivot items={items}></Pivot>
    </Stack>
  );
}
