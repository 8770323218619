import { useEffect } from 'react';

import { EngineProfiles } from '../services/api';
import { useCloudPlatformDiscovery } from '../utils/hooks';

export const useEngineProfilesService = () => {
  const cloudPlatformDiscovery = useCloudPlatformDiscovery();
  const engineProfilesUrl = cloudPlatformDiscovery?.aiEngineManagerApiUrl || '';

  useEffect(() => {
    EngineProfiles.setBasePath(engineProfilesUrl);
  }, [engineProfilesUrl]);

  return EngineProfiles;
};
