import { Deployment } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_pb';
import { ListProjectDeploymentsRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_service_pb';
import {
  DeploymentState,
  DeploymentStatus,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_status_pb';
import { ListDeploymentStatusesRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_status_service_pb';
import { PassphraseHashSecurityType } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/security_pb';
import { DeploymentService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_service_connect';
import { DeploymentStatusService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_status_service_connect';
import { DeploymentEnvironment } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/deployment_environment_pb';
import { ListDeploymentEnvironmentsRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/deployment_environment_service_pb';
import { DeploymentEnvironmentService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/deployment_environment_service_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { MessageBarType, PivotItem } from '@fluentui/react';
import { Pivot, useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Header from '../../components/Header/Header';
import { NoItemView } from '../../components/NoItemView/NoItemView';
import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { formatError } from '../../utils/utils';
// TODO: Move to global context.
import { LoaderView, fillStyles } from '../Orchestrator/WorkflowDetail';
import { ENDPOINTS } from './constants';
import { getFilter } from './Deployments';
import { DeploymentTabDetail, IDeploymentTabDetailProps } from './DeploymentTabDetail';
import { DeploymentTabEndpoints, IDeploymentTabEndpointsProps } from './DeploymentTabEndpoints';
import { DeploymentTabScoring, IDeploymentTabScoringProps } from './DeploymentTabScoring';
import PageWrapper from './PageWrapper';
import { useProjects } from './ProjectProvider';

export type SecurityType = 'passphrase_plain' | 'passphrase_hashed' | 'passphrase_hashed_deprecated' | 'no_security';
type DeploymentDetailParams = { deployment_id: string; project_id: string; tab_id: string };

const getTabEmptyView = (isNotHealthy: boolean) =>
  NoItemView({
    title: isNotHealthy ? 'Deployment is not in the healthy state' : 'Create the deployment first',
    description: isNotHealthy
      ? 'To access the scoring, deployment must be in the healthy state.'
      : 'Please return to the Details tab and save the deployment.',
  });

const DeploymentDetail = () => {
  const history = useHistory(),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID } = useProjects(),
    params = useParams<DeploymentDetailParams>(),
    [deployment, setDeployment] = React.useState<Deployment>(),
    [selectedKey, setSelectedKey] = React.useState<'0' | '1' | '2'>('0'),
    isNew = params.deployment_id === 'create-new',
    [deploymentStatus, setDeploymentStatus] = React.useState<DeploymentStatus>(),
    [scorerEndpoint, setScorerEndpoint] = React.useState<string>(),
    [deploymentEnvironmentId, setDeploymentEnvironmentId] = React.useState<string>(),
    [deploymentStatusItems, setDeploymentStatusItems] = React.useState<DeploymentStatus[]>(),
    [defaultSecurityLevel, setDefaultSecurityLevel] = React.useState<SecurityType>(),
    [defaultScoringPassphrase, setDefaultScoringPassphrase] = React.useState<string>(),
    [loading, setLoading] = React.useState(true),
    loadStateRef = React.useRef({
      fetchDeploymentEnvironments: true,
      fetchDeployments: true,
      fetchDeploymentStatuses: true,
      fetchingExistingDeploymentDetails: true,
      modelSelector: false,
    }),
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    deploymentTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.deployment}/`,
    }),
    deploymentClient = createPromiseClient(DeploymentService, deploymentTransport),
    deploymentEnvironmentClient = createPromiseClient(DeploymentEnvironmentService, storageTransport),
    deploymentStatusClient = createPromiseClient(DeploymentStatusService, deploymentTransport),
    isNotHealthy = React.useMemo(
      () => !isNew && deploymentStatus?.state !== DeploymentState.HEALTHY,
      [params.deployment_id, deploymentStatus, isNew]
    ),
    evaluateLoading = () => {
      if (
        !loadStateRef.current.fetchDeploymentEnvironments &&
        !loadStateRef.current.fetchDeployments &&
        !loadStateRef.current.fetchDeploymentStatuses &&
        !loadStateRef.current.fetchingExistingDeploymentDetails &&
        !loadStateRef.current.modelSelector
      ) {
        setLoading(false);
      }
    },
    getDeploymentEnvironments = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID) return;
      loadStateRef.current.fetchDeploymentEnvironments = true;
      setLoading(true);
      try {
        const listDeploymentEnvironmentsRequest = new ListDeploymentEnvironmentsRequest({
          projectId: ACTIVE_PROJECT_ID,
        });
        const response = await deploymentEnvironmentClient.listDeploymentEnvironments(
          listDeploymentEnvironmentsRequest
        );
        const deploymentEnvironments: DeploymentEnvironment[] | undefined = response?.deploymentEnvironment;
        const prodEnvId = deploymentEnvironments?.filter((env) => env.displayName === 'PROD')?.[0]?.id;
        setDeploymentEnvironmentId(prodEnvId);
        if (response && !deploymentEnvironments) console.error('No deployment environments found in the response.');
      } catch (err) {
        const message = `Failed to fetch deployment environments: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setDeploymentEnvironmentId(undefined);
      } finally {
        loadStateRef.current.fetchDeploymentEnvironments = false;
        evaluateLoading();
      }
    }, [ACTIVE_PROJECT_ID, addToast]),
    fetchDeploymentStatuses = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID) return;
      loadStateRef.current.fetchDeploymentStatuses = true;
      try {
        const listDeploymentStatusesBody = new ListDeploymentStatusesRequest({
          projectId: ACTIVE_PROJECT_ID,
        });
        const response = await deploymentStatusClient.listDeploymentStatuses(listDeploymentStatusesBody);
        const deploymentStatusItems: DeploymentStatus[] | undefined = response?.deploymentStatus;
        if (response && !deploymentStatusItems) console.error('No deployment statuses found in the response.');
        setDeploymentStatusItems(deploymentStatusItems);
      } catch (err) {
        const message = `Failed to fetch deployment statuses: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setDeploymentStatusItems(undefined);
      } finally {
        loadStateRef.current.fetchDeploymentStatuses = false;
        evaluateLoading();
      }
    }, [addToast, ACTIVE_PROJECT_ID]),
    fetchDeployments = React.useCallback(
      async (filter?: string) => {
        if (!ACTIVE_PROJECT_ID) return;
        loadStateRef.current.fetchDeployments = true;
        setLoading(true);
        try {
          const listDeploymentsBody = new ListProjectDeploymentsRequest({
            projectId: ACTIVE_PROJECT_ID,
            filter: filter ? getFilter(filter, 'id') : undefined,
          });
          const response = await deploymentClient.listProjectDeployments(listDeploymentsBody);
          const deploymentItems: Deployment[] | undefined = response?.deployment;
          if (response && !deploymentItems) console.error('No deployments found in the response.');
          // As we are fetching with the filter targeting a single deployment, we can assume the first item is the one we are looking for.
          setDeployment(deploymentItems?.[0]);
        } catch (err) {
          const message = `Failed to fetch deployments: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setDeployment(undefined);
        } finally {
          loadStateRef.current.fetchDeployments = false;
          evaluateLoading();
        }
      },
      [addToast, ACTIVE_PROJECT_ID]
    ),
    onItemClick = React.useCallback(
      (item?: PivotItem) => {
        if (!item) return;
        const tabId = item.props.itemKey === '1' ? 'endpoints' : item.props.itemKey === '2' ? 'scoring' : '';
        // TODO: Ask about unsaved changes.
        history.push(`/mlops/projects/${params.project_id}/deployments/${params.deployment_id}/${tabId}`);
      },
      [history, params.project_id]
    ),
    detailTabProps: IDeploymentTabDetailProps = {
      deployment,
      deploymentStatus,
      deploymentEnvironmentId,
      deploymentClient,
      deploymentEnvironmentClient,
      defaultSecurityLevel,
      isNew,
      isNotHealthy,
    },
    endpointsTabProps: IDeploymentTabEndpointsProps = {
      isNew,
      scorerEndpoint,
      deploymentId: deployment?.id,
      deploymentEnvironmentId,
    },
    scoringTabProps: IDeploymentTabScoringProps = {
      defaultSecurityLevel,
      scorerEndpoint,
      defaultScoringPassphrase,
      isNew,
    };

  React.useEffect(() => void getDeploymentEnvironments(), [getDeploymentEnvironments]);

  React.useEffect(() => void fetchDeploymentStatuses(), [fetchDeploymentStatuses]);

  React.useEffect(() => {
    if (params.deployment_id && !isNew) {
      void fetchDeployments(params.deployment_id);
    } else {
      loadStateRef.current.fetchDeployments = false;
      loadStateRef.current.fetchingExistingDeploymentDetails = false;
      evaluateLoading();
    }
  }, [params.deployment_id, params.project_id, fetchDeployments, isNew]);

  React.useEffect(() => {
    if (ACTIVE_PROJECT_ID && deployment && deploymentStatusItems) {
      setLoading(true);
      loadStateRef.current.fetchingExistingDeploymentDetails = true;
      const deploymentStatusItem =
          deploymentStatusItems?.find((status) => status.deploymentId === deployment?.id) || ({} as DeploymentStatus),
        defaultKey = deploymentStatusItem?.scorer?.score?.url || '';
      setScorerEndpoint(defaultKey);
      setDeploymentStatus(deploymentStatusItem);
      const securityLevel =
        deployment.security?.authentication?.case === 'passphrase'
          ? deployment.security.authentication.value.passphraseHashType ===
            PassphraseHashSecurityType.PASSPHRASE_HASH_TYPE_PLAINTEXT
            ? 'passphrase_plain'
            : deployment.security.authentication.value.passphraseHashType ===
              PassphraseHashSecurityType.PASSPHRASE_HASH_TYPE_BCRYPT
            ? 'passphrase_hashed_deprecated'
            : 'passphrase_hashed'
          : 'no_security';
      if (!defaultSecurityLevel) setDefaultSecurityLevel(securityLevel);
      if (!defaultScoringPassphrase && securityLevel === 'passphrase_plain') {
        setDefaultScoringPassphrase(
          deployment.security?.authentication?.case === 'passphrase'
            ? deployment.security.authentication.value.hash
            : ''
        );
      }
      loadStateRef.current.fetchingExistingDeploymentDetails = false;
      evaluateLoading();
    }
  }, [deployment, deploymentStatusItems, ACTIVE_PROJECT_ID]);

  React.useEffect(() => {
    if (!params.tab_id && selectedKey !== '0') setSelectedKey('0');
    if (params.tab_id === 'endpoints' && selectedKey !== '1') setSelectedKey('1');
    if (params.tab_id === 'scoring' && selectedKey !== '2') setSelectedKey('2');
  }, [params.tab_id]);

  return (
    <PageWrapper>
      <Header customPageTitle={isNew ? 'Create new deployment' : deployment?.displayName || 'Deployment detail'} />
      <Pivot
        {...fillStyles}
        onLinkClick={onItemClick}
        selectedKey={selectedKey}
        placeholder={undefined}
        items={[
          {
            headerText: 'Details',
            content: loading ? (
              <LoaderView loaderText="Loading deployment detail..." />
            ) : (
              <DeploymentTabDetail {...detailTabProps} />
            ),
          },
          {
            headerText: 'Endpoints',
            content:
              !isNew && deploymentStatus?.state === DeploymentState.HEALTHY ? (
                loading ? (
                  <LoaderView loaderText="Loading endpoints..." />
                ) : (
                  <DeploymentTabEndpoints {...endpointsTabProps} />
                )
              ) : (
                getTabEmptyView(isNotHealthy)
              ),
          },
          {
            headerText: 'Quick scoring',
            content:
              !isNew && deploymentStatus?.state === DeploymentState.HEALTHY ? (
                loading ? (
                  <LoaderView loaderText="Loading scoring UI..." />
                ) : (
                  <DeploymentTabScoring {...scoringTabProps} />
                )
              ) : (
                getTabEmptyView(isNotHealthy)
              ),
          },
        ]}
      />
    </PageWrapper>
  );
};

export default DeploymentDetail;
