import { DeploymentComposition } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_composition_pb';
import {
  Deployment,
  ShadowDeployment,
  ShadowElement,
  SingleDeployment,
  SplitDeployment,
  SplitElement,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_pb';
import {
  CreateDeploymentRequest,
  UpdateModelDeploymentRequest,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_service_pb';
import { DeploymentStatus } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/deployment_status_pb';
import { KubernetesConfigurationShortcut } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/kubernetes_configuration_pb';
import {
  KubernetesResourceRequirement,
  KubernetesResourceSpec,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/kubernetes_resource_spec_pb';
import { Monitor } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/monitor_pb';
import {
  AuthenticationPassphrase,
  DisabledStateSecurityType,
  PassphraseHashSecurityType,
  Security,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/security_pb';
import { DeploymentService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/deployment_service_connect';
import { DeployRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/deployment_environment_service_pb';
import { DeploymentType } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/deployment_pb';
import { RegisteredModel } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_pb';
import { GetModelVersionForExperimentRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_version_service_pb';
import { DeploymentEnvironmentService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/deployment_environment_service_connect';
import { RegisteredModelVersionService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_version_service_connect';
import { PartialMessage } from '@bufbuild/protobuf';
import { PromiseClient, createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { IDropdownOption, IStyle, MessageBarType, Toggle } from '@fluentui/react';
import {
  BasicCard,
  Button,
  Dropdown,
  IH2OTheme,
  Info,
  TextField,
  buttonStylesPrimary,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
// TODO: Move to global context.
import { Tag } from '../Orchestrator/WorkflowTabExecutions';
import { ENDPOINTS, ROUTES } from './constants';
import { SecurityType } from './DeploymentDetail';
import { getStateProps } from './Deployments';
import { DeploymentOptions } from './Models';
import ModelSelector from './ModelSelector';
import { useProjects } from './ProjectProvider';

export interface IDeploymentTabDetailProps {
  deployment?: Deployment;
  deploymentStatus?: DeploymentStatus;
  deploymentEnvironmentId?: string;
  deploymentClient: PromiseClient<typeof DeploymentService>;
  deploymentEnvironmentClient: PromiseClient<typeof DeploymentEnvironmentService>;
  defaultSecurityLevel?: SecurityType;
  isNew: boolean;
  isNotHealthy: boolean;
}

export type DeploymentTypeKey = 'single_model' | 'ab_test' | 'champion_challenger';
export type DropdownOption = { key: string | number; text: string };

interface IDeploymentTabDetailStyles {
  form: IStyle;
  formTitle?: IStyle;
  metadata: IStyle;
}

const deploymentTabDetailStyles = (theme: IH2OTheme): IDeploymentTabDetailStyles => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: 20,
      margin: '0px 40px',
      // TODO: Remove once theme is used.
      borderColor: theme.semanticColors?.bodyBackground,
    },
    formTitle: {
      marginBottom: 0,
    },
    metadata: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 16,
    },
  }),
  dropdownOptions: DropdownOption[] = [
    { key: 'single_model', text: 'Single model' },
    { key: 'ab_test', text: 'A/B Test' },
    { key: 'champion_challenger', text: 'Champion/Challenger' },
  ],
  getDeploymentType = (deploymentCase?: string) => {
    switch (deploymentCase) {
      case 'singleDeployment':
        return 'single_model';
      case 'splitDeployment':
        return 'ab_test';
      case 'shadowDeployment':
        return 'champion_challenger';
      default:
        return 'single_model';
    }
  },
  getSecurityDropdownOptions = (securityLevel?: SecurityType, isNew?: boolean) => {
    const item_plain = { key: 'passphrase_plain', text: 'Passphrase (Stored as plain text)' },
      item_hashed = { key: 'passphrase_hashed', text: 'Passphrase (Stored hashed)' },
      item_no_security = { key: 'no_security', text: 'No security' };

    if (isNew) {
      return [item_plain, item_hashed, item_no_security] as DropdownOption[];
    } else if (securityLevel === 'passphrase_hashed_deprecated') {
      return [
        item_hashed,
        { key: 'passphrase_hashed_deprecated', text: 'Passphrase (BCRYPT-Hashed) [DEPRECATED]', disabled: true },
      ] as DropdownOption[];
    } else if (securityLevel === 'passphrase_hashed') {
      return [item_hashed, item_plain] as DropdownOption[];
    } else if (securityLevel === 'passphrase_plain') {
      return [item_plain, item_hashed] as DropdownOption[];
    } else {
      return [item_plain, item_hashed, item_no_security] as DropdownOption[];
    }
  },
  getRandomHash = () =>
    new Array(20)
      .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
      .map((x) =>
        (function (chars) {
          const umax = Math.pow(2, 32),
            r = new Uint32Array(1),
            max = umax - (umax % chars.length);
          do {
            crypto.getRandomValues(r);
          } while (r[0] > max);
          return chars[r[0] % chars.length];
        })(x)
      )
      .join(''),
  getPBKDF2Hash = async (passphrase: string) => {
    const encoder = new TextEncoder(),
      passwordBuffer = encoder.encode(passphrase),
      salt = crypto.getRandomValues(new Uint8Array(16)),
      iterations = 100000,
      key = await crypto.subtle.importKey('raw', passwordBuffer, 'PBKDF2', false, ['deriveBits', 'deriveKey']),
      hashFunc = 'sha256',
      derivedKey = await crypto.subtle.deriveKey(
        {
          name: 'PBKDF2',
          salt,
          iterations,
          hash: 'SHA-256',
        },
        key,
        { name: 'AES-GCM', length: 256 },
        true,
        ['encrypt', 'decrypt']
      ),
      b64Salt = btoa(String.fromCharCode(...salt)),
      b64DerivedKey = btoa(String.fromCharCode(...new Uint8Array(await crypto.subtle.exportKey('raw', derivedKey))));
    return `pbkdf2:${hashFunc}:${iterations}$${b64Salt}$${b64DerivedKey}`;
  };

export const DeploymentTabDetail = ({
  deployment,
  deploymentStatus,
  deploymentEnvironmentId,
  deploymentClient,
  deploymentEnvironmentClient,
  defaultSecurityLevel,
  isNew,
  isNotHealthy,
}: IDeploymentTabDetailProps) => {
  const theme = useTheme(),
    classNames = useClassNames<IDeploymentTabDetailStyles, ClassNamesFromIStyles<IDeploymentTabDetailStyles>>(
      'deploymentTabDetail',
      deploymentTabDetailStyles(theme)
    ),
    history = useHistory(),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID, permissions } = useProjects(),
    [deploymentType, setDeploymentType] = React.useState<DeploymentTypeKey>(
      dropdownOptions[0].key as DeploymentTypeKey
    ),
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    registeredModelVersionClient = createPromiseClient(RegisteredModelVersionService, storageTransport),
    [selectedModels, setSelectedModels] = React.useState<(RegisteredModel | undefined)[]>([]),
    [defaultArtifactTypeKey, setDefaultArtifactTypeKey] = React.useState(''),
    [defaultRuntimeKey, setDefaultRuntimeKey] = React.useState(''),
    [defaultSelectedModelIds, setDefaultSelectedModelIds] = React.useState<string[]>(),
    [isWidgetLoading, setIsWidgetLoading] = React.useState(true),
    [isMonitoringEnabled, setIsMonitoringEnabled] = React.useState<boolean>(false),
    [deploymentOptions, setDeploymentOptions] = React.useState<Partial<DeploymentOptions> | undefined>(undefined),
    [deploymentName, setDeploymentName] = React.useState<string>(''),
    [deploymentDescription, setDeploymentDescription] = React.useState<string>(''),
    [passphrase, setPassphrase] = React.useState(getRandomHash()),
    [securityLevel, setSecurityLevel] = React.useState<SecurityType>(defaultSecurityLevel || 'passphrase_plain'),
    // TODO: Handle loading.
    [, setLoading] = React.useState(true),
    loadStateRef = React.useRef({
      createDeployment: false,
      updateDeployment: false,
      deploying: false,
      fetchingExistingDeploymentDetails: false,
    }),
    evaluateLoading = () => {
      if (
        !loadStateRef.current.createDeployment &&
        !loadStateRef.current.updateDeployment &&
        !loadStateRef.current.deploying &&
        !loadStateRef.current.fetchingExistingDeploymentDetails
      ) {
        setLoading(false);
      }
    },
    onChangeDeploymentType = React.useCallback((_event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption) => {
      if (item) {
        setDeploymentType(item.key as DeploymentTypeKey);
        setSelectedModels([]);
      }
    }, []),
    deploymentDetailProps = React.useMemo(
      () => ({
        createdBy: deployment?.userInfo?.ownerName || deployment?.userInfo?.ownerId || '',
        createdTime: deployment?.createdTime
          ? new Date(Number(deployment?.createdTime?.seconds) * 1000).toLocaleString()
          : '',
        lastModifiedTime: deployment?.lastModifiedTime
          ? new Date(Number(deployment?.lastModifiedTime?.seconds) * 1000).toLocaleString()
          : '',
      }),
      [deployment]
    ),
    deploy = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID || !deploymentEnvironmentId) return;
      loadStateRef.current.deploying = true;
      setLoading(true);
      try {
        deploymentEnvironmentClient.deploy(
          new DeployRequest({
            experimentId: deploymentOptions?.firstModelExperimentId || '',
            deploymentEnvironmentId,
            type:
              deploymentType === 'single_model'
                ? DeploymentType.SINGLE_MODEL
                : deploymentType === 'ab_test'
                ? DeploymentType.SPLIT_TRAFFIC
                : DeploymentType.SHADOW_TRAFFIC,
            secondaryScorer:
              deploymentType !== 'single_model'
                ? [
                    {
                      experimentId: deploymentOptions?.secondModelExperimentId || '',
                      // TODO: What is fraction?
                      fraction: 0.5,
                    },
                  ]
                : undefined,
          })
        );
        const message = `Deployment "${deploymentName}" created successfully.`;
        addToast({
          messageBarType: MessageBarType.success,
          message,
        });
        history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}`);
      } catch (err) {
        const message = `Failed to deploy deployment: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      }
      loadStateRef.current.deploying = false;
      evaluateLoading();
    }, [ACTIVE_PROJECT_ID, addToast, deploymentEnvironmentId, deploymentOptions, deploymentName]),
    createDeployment = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID || !deploymentOptions || !deploymentEnvironmentId) {
        return;
      }
      loadStateRef.current.createDeployment = true;
      setLoading(true);
      // TODO: Rename options to deploymentOptions.
      const options = deploymentOptions;
      try {
        const primaryElement = {
          deploymentComposition: new DeploymentComposition({
            experimentId: options.firstModelExperimentId,
            deployableArtifactTypeName: options.firstModelDeployableArtifactTypeName,
            artifactProcessorName: options.firstModelArtifactProcessorName,
            runtimeName: options.firstModelRuntimeName,
          }),
          // TODO: Make weight configurable in the UI.
          weight: deploymentType === 'ab_test' ? 50 : undefined,
          environmentSpec: {
            case: 'kubernetesResourceSpec',
            value: new KubernetesResourceSpec({
              replicas: 1, // TODO: Add replicas into the UI.
              kubernetesResourceRequirement: new KubernetesResourceRequirement({
                // TODO: Add suport for limits and requests into the UI.
                requests: {},
                limits: {},
              }),
            }),
          },
          environmentShortcut: {
            case: 'kubernetesConfigurationShortcut',
            value: new KubernetesConfigurationShortcut({
              // TODO: Add support for toleration and affinity into the UI.
              kubernetesAffinityShortcutName: '',
              kubernetesTolerationShortcutName: '',
            }),
          },
        };
        const secondaryElement = {
          deploymentComposition: new DeploymentComposition({
            experimentId: options.secondModelExperimentId,
            deployableArtifactTypeName: options.secondModelDeployableArtifactTypeName,
            artifactProcessorName: options.secondModelArtifactProcessorName,
            runtimeName: options.secondModelRuntimeName,
          }),
          // TODO: Make weight configurable in the UI.
          weight: deploymentType === 'ab_test' ? 50 : undefined,
          environmentSpec: {
            case: 'kubernetesResourceSpec',
            value: new KubernetesResourceSpec({
              replicas: 1, // TODO: Add replicas into the UI.
              kubernetesResourceRequirement: new KubernetesResourceRequirement({
                requests: {},
                limits: {},
              }),
            }),
          },
          environmentShortcut: {
            case: 'kubernetesConfigurationShortcut',
            value: new KubernetesConfigurationShortcut({
              kubernetesAffinityShortcutName: '',
              kubernetesTolerationShortcutName: '',
            }),
          },
        };
        const deployCreateDeploymentRequest = new CreateDeploymentRequest({
          deployment: new Deployment({
            projectId: ACTIVE_PROJECT_ID,
            deploymentEnvironmentId,
            deployment:
              deploymentType === 'single_model'
                ? {
                    case: 'singleDeployment',
                    value: new SingleDeployment(primaryElement as PartialMessage<SingleDeployment>),
                  }
                : deploymentType === 'ab_test'
                ? {
                    case: 'splitDeployment',
                    value: new SplitDeployment({
                      splitElements: [
                        primaryElement as PartialMessage<SplitElement>,
                        secondaryElement as PartialMessage<SplitElement>,
                      ],
                    }),
                  }
                : {
                    case: 'shadowDeployment',
                    value: new ShadowDeployment({
                      primaryElement: primaryElement as PartialMessage<ShadowElement>,
                      secondaryElement: secondaryElement as PartialMessage<ShadowElement>,
                    }),
                  },
            security:
              // TODO: Add support for token auth.
              securityLevel === 'no_security'
                ? new Security({
                    authentication: {
                      case: 'disabledSecurity',
                      value: DisabledStateSecurityType.DISABLED,
                    },
                  })
                : new Security({
                    authentication: {
                      case: 'passphrase',
                      value: new AuthenticationPassphrase({
                        // TODO: Check correct use of security option.
                        passphraseHashType:
                          securityLevel === 'passphrase_plain'
                            ? PassphraseHashSecurityType.PASSPHRASE_HASH_TYPE_PLAINTEXT
                            : securityLevel === 'passphrase_hashed_deprecated'
                            ? PassphraseHashSecurityType.PASSPHRASE_HASH_TYPE_BCRYPT
                            : PassphraseHashSecurityType.PASSPHRASE_HASH_TYPE_PBKDF2,
                        hash: securityLevel === 'passphrase_hashed' ? await getPBKDF2Hash(passphrase) : passphrase,
                      }),
                    },
                  }),
            displayName: deploymentName,
            description: deploymentDescription,
            monitor: new Monitor({
              enable: isMonitoringEnabled,
              // TODO: Add drift monitoring configuration in the UI - driftMonitoringEnabled,
              storeScoringTransactionEnable: false,
            }),
            // TODO: Add monitoring options.
          }),
        });

        const response = await deploymentClient.createDeployment(deployCreateDeploymentRequest);

        if (response && response.deployment) {
          void deploy();
        } else {
          console.error('Failed to create deployment: No deployment information in response.');
        }
      } catch (err) {
        const message = `Failed to create deployment: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.createDeployment = false;
        evaluateLoading();
      }
    }, [
      ACTIVE_PROJECT_ID,
      addToast,
      deploymentEnvironmentId,
      deploymentOptions,
      deploymentName,
      deploymentDescription,
      isMonitoringEnabled,
      deploymentType,
      deploy,
      passphrase,
      securityLevel,
    ]),
    // TODO: Add support for updating non-single-model deployments.
    updateDeployment = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID || !deploymentOptions || !deploymentEnvironmentId) {
        return;
      }
      loadStateRef.current.updateDeployment = true;
      setLoading(true);
      try {
        const updateModelDeploymentRequest = new UpdateModelDeploymentRequest({
          deployment: new Deployment({
            id: deployment?.id,
            projectId: ACTIVE_PROJECT_ID,
            deploymentEnvironmentId,
            deployment: {
              case: 'singleDeployment',
              value: new SingleDeployment({
                deploymentComposition: new DeploymentComposition({
                  experimentId: deploymentOptions.firstModelExperimentId,
                  deployableArtifactTypeName: deploymentOptions.firstModelDeployableArtifactTypeName,
                  artifactProcessorName: deploymentOptions.firstModelArtifactProcessorName,
                  runtimeName: deploymentOptions.firstModelRuntimeName,
                }),
                environmentSpec: {
                  case: 'kubernetesResourceSpec',
                  value: new KubernetesResourceSpec({
                    replicas: 1, // TODO: Add replicas into the UI.
                    kubernetesResourceRequirement: new KubernetesResourceRequirement({
                      // TODO: Add suport for limits and requests into the UI.
                      requests: {},
                      limits: {},
                    }),
                  }),
                },
                environmentShortcut: {
                  case: 'kubernetesConfigurationShortcut',
                  value: new KubernetesConfigurationShortcut({
                    // TODO: Add support for toleration and affinity into the UI.
                    kubernetesAffinityShortcutName: '',
                    kubernetesTolerationShortcutName: '',
                  }),
                },
              }),
            },
            security: new Security({
              authentication: {
                case: 'passphrase',
                value: new AuthenticationPassphrase({
                  // TODO: Check correct use of security option.
                  passphraseHashType: PassphraseHashSecurityType.PASSPHRASE_HASH_TYPE_PLAINTEXT,
                  hash: 'plain_text',
                }),
              },
            }),
            // TODO: Add support for updating deployment name and description when supported by the API.
            displayName: deploymentName,
            description: deploymentDescription,
            createdTime: deployment?.createdTime,
            lastModifiedTime: deployment?.lastModifiedTime,
            revisionId: deployment?.revisionId,
            revisionCreateTime: deployment?.revisionCreateTime,
            userInfo: deployment?.userInfo,
            customCors: deployment?.customCors,
            monitor: new Monitor({
              enable: isMonitoringEnabled,
              // TODO: Add drift monitoring configuration in the UI - driftMonitoringEnabled,
              storeScoringTransactionEnable: true,
            }),
            // TODO: Add support for updating deployment monitoring options.
            monitoringOptions: deployment?.monitoringOptions,
          }),
        });

        const response = await deploymentClient.updateModelDeployment(updateModelDeploymentRequest);

        if (response && response.deployment) {
          const deploymentId = response.deployment.id;
          const message = `Deployment "${deploymentName}" (${deploymentId}) updated successfully.`;
          addToast({
            messageBarType: MessageBarType.success,
            message,
          });
          history.push(`/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.DEPLOYMENTS}`);
        } else {
          console.error('Failed to update deployment: No deployment information in response.');
        }
      } catch (err) {
        const message = `Failed to update deployment: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.updateDeployment = false;
        evaluateLoading();
      }
    }, [
      ACTIVE_PROJECT_ID,
      addToast,
      deploymentEnvironmentId,
      deploymentOptions,
      deploymentName,
      deploymentDescription,
      isMonitoringEnabled,
      deployment?.id,
    ]);

  React.useEffect(() => {
    if (!ACTIVE_PROJECT_ID) return;
    if (deployment) {
      setLoading(true);
      loadStateRef.current.fetchingExistingDeploymentDetails = true;

      setDeploymentName(deployment.displayName || '');
      setDeploymentType(getDeploymentType(deployment.deployment?.case));
      setPassphrase(
        deployment.security?.authentication?.case === 'passphrase' && securityLevel === 'passphrase_plain'
          ? deployment.security.authentication.value.hash
          : getRandomHash()
      );
      setDeploymentDescription(deployment.description || '');
      if (deployment.deployment?.case === 'singleDeployment') {
        setDefaultArtifactTypeKey(deployment.deployment.value.deploymentComposition?.deployableArtifactTypeName || '');
        setDefaultRuntimeKey(deployment.deployment.value.deploymentComposition?.runtimeName || '');

        registeredModelVersionClient
          .getModelVersionForExperiment(
            new GetModelVersionForExperimentRequest({
              experimentId: deployment.deployment.value.deploymentComposition?.experimentId || '',
            })
          )
          .then((response) => {
            const registeredModelId = response?.registeredModelVersion?.[0]?.registeredModelId;
            setDefaultSelectedModelIds([registeredModelId || '']);
          })
          .catch((err) => {
            const message = `Failed to fetch model version for experiment: ${formatError(err)}`;
            console.error(message);
            addToast({
              messageBarType: MessageBarType.error,
              message,
            });
          });
      }
      if (deployment.deployment?.case === 'splitDeployment') {
        setDefaultArtifactTypeKey('');
        setDefaultRuntimeKey('');

        const firstRequest = registeredModelVersionClient
          .getModelVersionForExperiment(
            new GetModelVersionForExperimentRequest({
              experimentId: deployment.deployment.value.splitElements[0].deploymentComposition?.experimentId || '',
            })
          )
          .then((response) => response?.registeredModelVersion?.[0]?.registeredModelId || '')
          .catch((err) => {
            const message = `Failed to fetch model version for experiment: ${formatError(err)}`;
            console.error(message);
            addToast({
              messageBarType: MessageBarType.error,
              message,
            });
            return '';
          });

        const secondRequest = registeredModelVersionClient
          .getModelVersionForExperiment(
            new GetModelVersionForExperimentRequest({
              experimentId: deployment.deployment.value.splitElements[1].deploymentComposition?.experimentId || '',
            })
          )
          .then((response) => response?.registeredModelVersion?.[0]?.registeredModelId || '')
          .catch((err) => {
            const message = `Failed to fetch second model version for experiment: ${formatError(err)}`;
            console.error(message);
            addToast({
              messageBarType: MessageBarType.error,
              message,
            });
            return '';
          });

        Promise.all([firstRequest, secondRequest]).then(([firstModelId, secondModelId]) => {
          setDefaultSelectedModelIds([firstModelId, secondModelId]);
        });
      }

      if (deployment.deployment?.case === 'shadowDeployment') {
        setDefaultArtifactTypeKey('');
        setDefaultRuntimeKey('');

        const primaryRequest = registeredModelVersionClient
          .getModelVersionForExperiment(
            new GetModelVersionForExperimentRequest({
              experimentId: deployment.deployment.value.primaryElement?.deploymentComposition?.experimentId || '',
            })
          )
          .then((response) => response?.registeredModelVersion?.[0]?.registeredModelId || '')
          .catch((err) => {
            const message = `Failed to fetch model version for experiment: ${formatError(err)}`;
            console.error(message);
            addToast({
              messageBarType: MessageBarType.error,
              message,
            });
            return '';
          });

        const secondaryRequest = registeredModelVersionClient
          .getModelVersionForExperiment(
            new GetModelVersionForExperimentRequest({
              experimentId: deployment.deployment.value.secondaryElement?.deploymentComposition?.experimentId || '',
            })
          )
          .then((response) => response?.registeredModelVersion?.[0]?.registeredModelId || '')
          .catch((err) => {
            const message = `Failed to fetch second model version for experiment: ${formatError(err)}`;
            console.error(message);
            addToast({
              messageBarType: MessageBarType.error,
              message,
            });
            return '';
          });

        Promise.all([primaryRequest, secondaryRequest]).then(([firstModelId, secondModelId]) => {
          setDefaultSelectedModelIds([firstModelId, secondModelId]);
        });
      }

      setIsMonitoringEnabled(deployment.monitor?.enable || false);
      loadStateRef.current.fetchingExistingDeploymentDetails = false;
      evaluateLoading();
    }
  }, [deployment, ACTIVE_PROJECT_ID]);

  React.useEffect(() => {
    setSecurityLevel(securityLevel);
  }, [defaultSecurityLevel]);

  return (
    <>
      <div className={classNames.form}>
        {deploymentStatus ? (
          <BasicCard
            data={{
              id: deploymentStatus?.deploymentId || '',
              longDescription: deploymentStatus?.message || 'No details available.',
              status: getStateProps(deploymentStatus?.state).name,
              statusColor: getStateProps(deploymentStatus?.state).color,
              title: 'Deployment status',
            }}
            data-test="basic-card-id0"
            idField="id"
            styles={{
              root: {
                backgroundColor: theme.palette?.white,
                borderColor: getStateProps(deploymentStatus?.state).color,
              },
            }}
            height="auto"
            onRenderDescription={({ status, statusColor, longDescription }) => (
              <div>
                <Tag title={status} color={statusColor} />
                <p>{longDescription}</p>
              </div>
            )}
            titleField="title"
          />
        ) : null}
        {
          <div className={classNames.metadata}>
            {deploymentDetailProps.createdBy ? (
              <p>
                <b>Created by </b>
                {deploymentDetailProps.createdBy}
              </p>
            ) : null}
            {deploymentDetailProps.createdTime ? (
              <p>
                <b>Created at </b>
                {deploymentDetailProps.createdTime}
              </p>
            ) : null}
            {deploymentDetailProps.createdTime &&
            deploymentDetailProps.lastModifiedTime &&
            deploymentDetailProps.createdTime !== deploymentDetailProps.lastModifiedTime ? (
              <p>
                <b>Last modified </b>
                {deploymentDetailProps.lastModifiedTime}
              </p>
            ) : null}
          </div>
        }
        <TextField
          label="Deployment name"
          required
          onChange={(_ev, value) => {
            setDeploymentName(value || '');
          }}
          value={deploymentName}
          disabled={!isNew || !permissions.canWrite}
        />
        <TextField
          label="Deployment description"
          onChange={(_ev, value) => {
            setDeploymentDescription(value || '');
          }}
          value={deploymentDescription}
          disabled={!isNew || !permissions.canWrite}
        />
        <Dropdown
          label="Deployment type"
          selectedKey={deploymentType}
          onChange={onChangeDeploymentType}
          options={dropdownOptions}
          disabled={isNotHealthy || !permissions.canWrite}
        />
        <p className={classNames.formTitle}>{`Selected model${deploymentType !== 'single_model' ? 's' : ''}`}</p>
        <ModelSelector
          selectedModels={selectedModels}
          onSelectedModelsChange={setSelectedModels}
          defaultSelectedModelIds={defaultSelectedModelIds}
          defaultArtifactTypeKey={defaultArtifactTypeKey}
          defaultRuntimeKey={defaultRuntimeKey}
          deploymentType={deploymentType}
          onChangeOptions={(options) => {
            setDeploymentOptions((prevOptions) => {
              return {
                ...prevOptions,
                ...options,
              };
            });
          }}
          onContentLoadStart={() => setIsWidgetLoading(true)}
          onContentLoadFinish={() => setIsWidgetLoading(false)}
          readOnly={!isNew || !permissions.canWrite}
        />
        <h4>Monitoring</h4>
        <Toggle
          label="Enable monitoring"
          onText="Yes"
          offText="No"
          checked={isMonitoringEnabled}
          disabled={isNotHealthy || !permissions.canWrite}
          onChange={(_ev, checked) => {
            setIsMonitoringEnabled(checked || false);
          }}
        />
        <h4>Security</h4>
        <Dropdown
          label="Level"
          disabled={isNotHealthy || !permissions.canWrite}
          options={getSecurityDropdownOptions(defaultSecurityLevel, isNew)}
          selectedKey={securityLevel}
          onChange={(_ev, item) => {
            setSecurityLevel(item?.key as SecurityType);
            if (isNew && securityLevel !== 'no_security') setPassphrase(getRandomHash());
          }}
          required
        />
        {(securityLevel === 'passphrase_plain' ||
          (securityLevel === 'passphrase_hashed' && (isNew || defaultSecurityLevel !== 'passphrase_hashed'))) && (
          <TextField
            label="Passphrase"
            disabled={isNotHealthy || !permissions.canWrite}
            type={!isNew ? 'password' : undefined}
            canRevealPassword={!isNew}
            value={passphrase}
            onChange={(_ev, value) => {
              setPassphrase(value || '');
            }}
          />
        )}
        {securityLevel !== 'no_security' && isNew && (
          <Info style={{ marginTop: 10 }}>
            {securityLevel === 'passphrase_plain'
              ? 'The passphrase is stored in plain text in the database. If you forget the passphrase, it will still be visible in the UI after deployment.'
              : 'The passphrase is stored as a hashed value in the database. You must save this passphrase somewhere else for your future use. If you forgot the passphrase, it will not be visible in the UI after deployment.'}
          </Info>
        )}
        <div style={{ height: 25 }}></div>
        {permissions.canWrite ? (
          <Button
            // TODO: Add onCreateDeploymentClick function with form validation.
            onClick={isNew ? createDeployment : updateDeployment}
            // TODO: Handle disabled based on form validation.
            disabled={
              isWidgetLoading ||
              (deploymentType === 'single_model' ? !selectedModels.length : selectedModels.length < 2) ||
              isNotHealthy
            }
            styles={buttonStylesPrimary}
          >
            {isNew ? 'Deploy' : 'Save changes'}
          </Button>
        ) : null}
      </div>
    </>
  );
};
