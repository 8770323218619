export const defaultPort = 9001;
export const defaultBasePath = `http://localhost:${defaultPort}`;

export enum AppStoreMenuName {
  about = 'About',
  home = 'Home',
  appStore = 'App Store',
  myApps = 'Apps',
  myModels = 'Models',
  myInstances = 'Instances',
  myAiEngines = 'AI Engines',
  platformUsage = 'Platform Usage',
  collaborate = 'Collaborate',
  featureStore = 'Feature Store',
  adminMarketplaceApps = 'Admin Marketplace Apps',
  adminApps = 'Admin Apps',
  adminInstances = 'App Instances',
  adminAiEngines = 'AI Engines',
  aiEngineSettings = 'AI Engines Settings',
  notebookAdminSettings = 'Notebook Settings',
  secureStoreOAuthClients = 'OAuth Clients',
  secureStoreSecrets = 'Secrets',
  documentation = 'Documentation',
  visitorMode = 'Visitor Mode',
  cliApiAccess = 'CLI & API Access',
  apiAccess = 'API Access',
  license = 'License',
  logout = 'Logout',
}

export const serviceNames = {
  appstore: 'services/appstore',
  aiem: 'services/engine-manager',
  mlops: 'services/mlops-api',
  mlopsLegacy: 'services/mlops',
  steam: 'services/steam',
  logging: 'services/logging',
  telemetry: 'services/telemetry-server',
  orchestrator: 'services/orchestrator',
  notebookAPI: 'services/notebook-api',
  secureStore: 'services/secure-store',
  authz: 'services/authz-gateway',
};

export const clientNames = {
  appstore: 'clients/appstore',
  platform: 'clients/platform',
};
