import { engineTypeService } from '../../aiem/engine/services';
import { Engine_Type } from '../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { getRandomNumber } from '../../aiem/utils';

export const getNumericValue = (val: string | number | null | undefined): number | undefined => {
  const num = Number(val);
  if (isNaN(num)) {
    return undefined;
  }
  return num;
};

export const getRandomEngineName = (engineType: Engine_Type) =>
  `New ${engineTypeService[engineType].text} Engine ${getRandomNumber()}`;

export const validateId = (id: string | undefined | null): boolean =>
  Boolean(id) && /^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$/.test(id!);
