import { IStyle, MessageBarType } from '@fluentui/react';
import {
  Button,
  CodeBlock,
  IH2OTheme,
  KeyValuePairEditor,
  TextField,
  buttonStylesPrimary,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';

import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';

export interface IDeploymentTabScoringStyles {
  form: IStyle;
  loader: IStyle;
  customEndpointButton: IStyle;
}

export const deploymentTabScoringStyles = (theme: IH2OTheme): Partial<IDeploymentTabScoringStyles> => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 20,
    margin: '0px 40px',
    // TODO: Remove once theme is used.
    borderColor: theme.semanticColors?.bodyBackground,
  },
  loader: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  customEndpointButton: {
    margin: '6px 0',
  },
});

export interface IDeploymentTabScoringProps {
  defaultSecurityLevel?: string;
  scorerEndpoint?: string;
  defaultScoringPassphrase?: string;
  isNew: boolean;
}

const scoringConfigToJSON = (scoringConfig: { [key: string]: string }) =>
  JSON.stringify({
    fields: Object.keys(scoringConfig),
    rows: [Object.values(scoringConfig)],
  });

export const DeploymentTabScoring = ({
  defaultSecurityLevel,
  scorerEndpoint,
  defaultScoringPassphrase,
  isNew,
}: IDeploymentTabScoringProps) => {
  const theme = useTheme(),
    classNames = useClassNames<IDeploymentTabScoringStyles, ClassNamesFromIStyles<IDeploymentTabScoringStyles>>(
      'deploymentTabScoringStyles',
      deploymentTabScoringStyles(theme)
    ),
    { addToast } = useToast(),
    isSecured = React.useMemo(() => defaultSecurityLevel !== 'no_security', [defaultSecurityLevel]),
    // TODO: Handle loading.
    [, setLoading] = React.useState<boolean>(false),
    [scoringConfig, setScoringConfig] = React.useState<{ [key: string]: string }>({ Field: 'column' }),
    [scoringResponse, setScoringResponse] = React.useState<string>(),
    [scoringPassphrase, setScoringPassphrase] = React.useState<string>(defaultScoringPassphrase || ''),
    loadStateRef = React.useRef({
      scoring: false,
    }),
    evaluateLoading = () => {
      if (!loadStateRef.current.scoring) {
        setLoading(false);
      }
    },
    score = React.useCallback(async () => {
      loadStateRef.current.scoring = true;
      setLoading(true);
      try {
        const body = scoringConfigToJSON(scoringConfig),
          headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (defaultSecurityLevel !== 'no_security') {
          headers.append('Authorization', `Bearer ${scoringPassphrase}`);
        }
        const response = await fetch((scorerEndpoint as string) || '', {
          method: 'POST',
          headers,
          body,
        });

        addToast({
          messageBarType: MessageBarType.success,
          message: 'Scoring successfull.',
        });
        setScoringResponse(await response.text());
      } catch (err) {
        const message = `Failed to score: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
      } finally {
        loadStateRef.current.scoring = false;
        evaluateLoading();
      }
    }, [addToast, scoringConfig, scorerEndpoint, defaultSecurityLevel, scoringPassphrase]),
    keyValuePairEditorStyles = React.useMemo(
      () => ({
        root: {
          backgroundColor: theme.semanticColors?.contentBackground,
          maxWidth: 460,
          border: `1px solid ${theme.semanticColors?.inputBorder}`,
          borderRadius: 4,
        },
      }),
      [theme.semanticColors?.contentBackground, theme.semanticColors?.inputBorder]
    );

  React.useEffect(() => {
    if (!scoringPassphrase && defaultScoringPassphrase) setScoringPassphrase(defaultScoringPassphrase);
  }, [defaultScoringPassphrase, scoringPassphrase]);

  return (
    <div className={classNames.form}>
      <KeyValuePairEditor
        config={scoringConfig}
        onUpdateConfig={(newConfig) => setScoringConfig(newConfig)}
        styles={keyValuePairEditorStyles}
      />
      {(defaultSecurityLevel === 'passphrase_hashed' || defaultSecurityLevel === 'passphrase_hashed_deprecated') &&
      !isNew ? (
        <TextField
          label="Passphrase"
          value={scoringPassphrase}
          onChange={(_ev, value) => setScoringPassphrase(value || '')}
        />
      ) : null}
      <Button
        className={classNames.customEndpointButton}
        text="Score"
        onClick={score}
        disabled={
          !scoringPassphrase &&
          (defaultSecurityLevel === 'passphrase_hashed' || defaultSecurityLevel === 'passphrase_hashed_deprecated')
        }
        styles={buttonStylesPrimary}
      />
      {scoringResponse && (
        <>
          <h3>Scoring results</h3>
          <CodeBlock
            styles={{
              root: { borderRadius: 4 },
              pre: { borderRadius: 4 },
            }}
          >
            {scoringResponse}
          </CodeBlock>
        </>
      )}
      <h3>CURL request</h3>
      <CodeBlock showCopyButton>
        {`curl -X POST -H "Content-Type: application/json" -d '${scoringConfigToJSON(scoringConfig)}' ${
          isSecured ? '-H "Authorization: Bearer ' : ''
        }${isSecured ? scoringPassphrase : ''}${isSecured ? '"' : ''} ${scorerEndpoint || '[SCORER_ENDPOINT]'}`}
      </CodeBlock>
    </div>
  );
};
