import { INavLinkGroup, Stack } from '@fluentui/react';
import { Link, Nav } from '@h2oai/ui-kit';
import React from 'react';

import { GetApiAccessConfigResponse } from '../../ai.h2o.cloud.appstore';

export enum CliAndApiAccessContentType {
  TEXT = 'text',
  CODE = 'code',
}

export interface AccessData extends GetApiAccessConfigResponse {
  getTokenUrl: string;
  getPlatformTokenUrl: string;
  mlopsPythonClient?: string;
  steamPythonClient?: string;
  aiemPythonClient?: string;
  mlopsUri?: string;
  steamUri?: string;
  aiemUri?: string;
  h2oCloudEnvironment?: string;
}

export interface ContentSubsection {
  label: string;
  type: CliAndApiAccessContentType;
  renderContent: () => React.ReactNode;
}

export interface ContentSection {
  id: string;
  title: string;
  sidebarLabel: string;
  subsections: (data: AccessData) => ContentSubsection[];
}

export function TableOfContents(props: {
  cliDownloadEnabled?: boolean;
  activeSectionKey: string | undefined;
  sections: ContentSection[];
}) {
  return (
    <Nav
      selectedKey={props.activeSectionKey}
      onRenderGroupHeader={(group?: INavLinkGroup) => <h2>{group?.name || ''}</h2>}
      styles={{
        root: {
          position: 'sticky',
          alignSelf: 'flex-start',
          top: 0,
        },
      }}
      groups={[
        {
          name: props.cliDownloadEnabled ? 'CLI & API Access' : 'API Access',
          links: props.sections.map((section) => ({
            name: section.sidebarLabel,
            key: section.id,
            url: `#${section.id}`,
          })),
        },
      ]}
    />
  );
}

export function EponymousLink(props: { href: string }) {
  return <Link href={props.href}>{props.href}</Link>;
}

export function LabeledValue(props: { label: string; children: React.ReactNode }) {
  return (
    <Stack>
      <h4>{props.label}</h4>
      <div>{props.children}</div>
    </Stack>
  );
}
