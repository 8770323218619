import { IDropdownOption, MessageBarType, Stack } from '@fluentui/react';
import {
  Accordion,
  Dropdown,
  KeyValuePairSubmitOutcome,
  type KeyValuePairValidationFn,
  type KeyValueValidation,
  TextField,
  useToast,
} from '@h2oai/ui-kit';
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { defaultVersion } from '../../../../aiem/defaults';
import { useEngine } from '../../../../aiem/engine/hooks';
import { AIEMOpType, AIEngine, EngineVersion, ValidEngineType } from '../../../../aiem/engine/types';
import {
  DAIEngineProfile,
  DAIEngineProfile_ConfigEditability,
} from '../../../../aiem/gen/ai/h2o/engine/v1/dai_engine_profile_pb';
import { Engine_Type } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { H2OEngineProfile } from '../../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_profile_pb';
import { useEngineProfilesService } from '../../../../aiem/hooks';
import { ConstraintType } from '../../../../aiem/types';
import { getIdFromName } from '../../../../aiem/utils';
import { EntityModelTypes } from '../../../../pages/AIEngineSettingsPage/components/ListWithCollapsedSettings/types';
import { useFormAttributes } from '../../../../utils/utils';
import { EditablePanelFooter } from '../../../EditablePanelFooter/EditablePanelFooter';
import { H2OEngineDefaultProfiles, H2OEngineProfileOptionKeyType } from '../../constants';
import { validateId } from '../../utils';
import { AdvancedConfiguration } from '../AdvancedConfiguration/AdvancedConfiguration';
import { ConstraintInput } from '../ConstraintInput/ConstraintInput';
import { DisplayAndId } from '../DisplayAndId/DisplayAndId';
import ConfigureCustomEngineProfile from './components/ConfigureCustomEngineProfile';

export type EngineConfigurationProps = {
  op: AIEMOpType;
  engine: AIEngine;
  onSave: () => any;
  onCancel: () => any;
};

export function EngineConfiguration({ op, engine, onCancel, onSave }: EngineConfigurationProps) {
  const engineProfilesService = useEngineProfilesService();
  const { opOnEngine } = useEngine();
  const { inputContainerProps, inputRowProps, sliderContainerProps, sliderFormRowProps } = useFormAttributes();
  const { listVersions } = useEngine();
  const { addToast } = useToast();

  const createSuccessToast = useCallback(
    (displayName: string, op: AIEMOpType) => {
      addToast({
        messageBarType: MessageBarType.success,
        message:
          op === AIEMOpType.create
            ? `Engine "${displayName}" has been successfully created and it is now starting.`
            : `Engine "${displayName}" has been successfully updated.`,
      });
    },
    [addToast]
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [engineState, setEngineState] = useState<AIEngine>({ ...engine });
  const [selectedProfileOption, setSelectedProfileOption] = useState<EntityModelTypes>();
  const [selectedVersion, setSelectedVersion] = useState<string>(defaultVersion);
  const [versions, setVersions] = useState<IDropdownOption[]>([]);
  const isNew = useCallback((): boolean => {
    if (op === AIEMOpType.create) {
      return true;
    }
    return JSON.stringify(engine) !== JSON.stringify(engineState);
  }, [engine, engineState]);
  const advancedConfigurationRef = useRef<any>();
  const waitingForEngineStateUpdate = useRef<boolean>(false);
  const [profileOptions, setProfileOptions] = useState<IDropdownOption[]>([]);
  const [selectedConfigurationOption, setSelectedConfigurationOption] = useState<IDropdownOption>(
    H2OEngineDefaultProfiles[0]
  );
  const engineType = engine?.engineType;
  const isConfigEditabilityDisabled =
    (selectedProfileOption as DAIEngineProfile)?.configEditability === DAIEngineProfile_ConfigEditability.DISABLED;
  const isH2ODefaultProfileSelected =
    selectedConfigurationOption.key === H2OEngineProfileOptionKeyType.compressed ||
    selectedConfigurationOption.key === H2OEngineProfileOptionKeyType.raw;
  const isEngineValid = useMemo(() => {
    const { displayName, id, version, memoryBytes } = engineState;

    if (!selectedProfileOption) return false;

    if (op === AIEMOpType.create) {
      const containsValues = Boolean(displayName && validateId(id) && version && memoryBytes);
      const { memoryBytesConstraint } = selectedProfileOption;
      const validMemoryConstraintMin = Number(memoryBytes) >= Number(memoryBytesConstraint.min);
      const validMemoryConstraintMax =
        !memoryBytesConstraint?.max || Number(memoryBytes) <= Number(memoryBytesConstraint?.max);

      return containsValues && validMemoryConstraintMin && validMemoryConstraintMax;
    } else {
      return Boolean(displayName && version);
    }
  }, [engineState, selectedProfileOption]);

  const updateEngine = (eng: Partial<AIEngine>) => setEngineState({ ...engineState, ...eng });
  const handleVersionChange = (_e: FormEvent<HTMLDivElement>, { key: value }: any) => {
    setSelectedVersion(value);
    updateEngine({ version: value });
  };
  const onDisplayNameChange = (value: string) => updateEngine({ displayName: value });
  const onIdChange = (value: string) => updateEngine({ id: value });
  const saveEngine = async () => {
    if (op === AIEMOpType.create) {
      const res = await opOnEngine(engineState, AIEMOpType.create);
      if (res) {
        onSave();
        createSuccessToast(String(engineState!.displayName), AIEMOpType.create);
      } else {
        onCancel();
      }
    } else {
      const res = await opOnEngine(engineState, AIEMOpType.update);
      if (res) {
        onSave();
        createSuccessToast(String(engineState!.displayName), AIEMOpType.update);
      } else {
        onCancel();
      }
    }
  };

  const getEngineFromConstraintSet = (constraints: EntityModelTypes): Partial<AIEngine> => ({
    cpu: Number(constraints?.cpuConstraint?.default),
    gpu: Number(constraints?.gpuConstraint?.default),
    memoryBytes: constraints?.memoryBytesConstraint?.default,
    storageBytes: (constraints as DAIEngineProfile)?.storageBytesConstraint?.default,
    nodeCount: Number((constraints as H2OEngineProfile)?.nodeCountConstraint?.default),
    maxIdleDuration: String(constraints?.maxIdleDurationConstraint?.default),
    maxRunningDuration: String(constraints?.maxRunningDurationConstraint?.default),
    config: (constraints as DAIEngineProfile)?.baseConfiguration,
  });
  const loadVersions = useCallback(async (): Promise<string | undefined> => {
    let defaultVersion;
    const versions = await listVersions(engineType as ValidEngineType),
      options =
        versions?.map(({ key, version: text, isDefault }: EngineVersion) => {
          if (isDefault && key) {
            setSelectedVersion(key);
            defaultVersion = key;
          }
          return { key, text } as IDropdownOption;
        }) || [];
    setVersions(options);
    return defaultVersion;
  }, [listVersions, setSelectedVersion, setVersions]);
  const fetchProfileOptions = async (): Promise<EntityModelTypes | null> => {
    let activeProfile: IDropdownOption;

    try {
      const isDAIEngine = engine?.engineType === Engine_Type.DRIVERLESS_AI;
      const responseKey = isDAIEngine ? 'daiEngineProfiles' : 'h2oEngineProfiles';
      const requestParams = { parent: 'workspaces/global', pageSize: 1000 };
      const response = isDAIEngine
        ? await engineProfilesService.listDAIEngineProfiles(requestParams)
        : await engineProfilesService.listH2OEngineProfiles(requestParams);

      const profileMapper = (profile: DAIEngineProfile | H2OEngineProfile, showId = false) => ({
        key: profile.name || '',
        text: `${profile.displayName} ${showId ? `(${getIdFromName(profile.name)})` : ''}`,
        data: profile,
      });

      const profileOptions = (response[responseKey] || []).map((profile: DAIEngineProfile | H2OEngineProfile) =>
        profileMapper(profile, true)
      );
      activeProfile = {
        key: profileOptions[0]?.data?.name || '',
        text: profileOptions[0].data?.displayName || '',
        data: profileOptions[0].data,
      };

      setProfileOptions(profileOptions);
      handleProfileOptionSelect(undefined, activeProfile.data);

      return activeProfile.data;
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      return null;
    }
  };
  const prepareConfiguration = async () => {
    setIsLoading(true);
    let defaultEngine: Partial<AIEngine> = engineType === Engine_Type.DRIVERLESS_AI ? {} : {};
    try {
      const loadedSet = await fetchProfileOptions();

      if (!loadedSet) return;

      if (defaultEngine) {
        defaultEngine = getEngineFromConstraintSet(loadedSet);
      }

      if (op === AIEMOpType.create) {
        const defaultVersion = (await loadVersions()) || '';
        updateEngine({
          ...defaultEngine,
          ...(engineType === Engine_Type.DRIVERLESS_AI ? { profile: loadedSet.name } : {}),
          version: defaultVersion,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleProfileOptionSelect = (_: any, option: EntityModelTypes) => {
    setSelectedProfileOption(option);
    handleConfigurationOptionSelect(undefined, H2OEngineDefaultProfiles[0]);
  };
  const handleConfigurationOptionSelect = (_: any, option?: IDropdownOption) => {
    if (!option) return;
    setSelectedConfigurationOption(option);
    selectedProfileOption &&
      updateEngine({
        ...getEngineFromConstraintSet(selectedProfileOption),
      });
  };
  const validateAdvancedConfiguration: KeyValuePairValidationFn = ({ key, value }): KeyValueValidation => {
    const message: KeyValueValidation = {};
    const { configEditability, configurationOverride } = selectedProfileOption as DAIEngineProfile;

    if (configEditability !== DAIEngineProfile_ConfigEditability.FULL) return message;

    if (configurationOverride?.hasOwnProperty(key || '')) {
      message.keyValidation = `"${key}" key already exists in the configuration_override.`;
    }
    if (!key) {
      message.keyValidation = 'The key may not be empty.';
    }
    if (!value) {
      message.valueValidation = 'The value may not be empty.';
    }
    return message;
  };

  const onClickSave = useCallback(() => {
    if (advancedConfigurationRef.current) {
      waitingForEngineStateUpdate.current = true;
      const saveOutcome = advancedConfigurationRef.current.submitKeyValue();
      switch (saveOutcome) {
        case KeyValuePairSubmitOutcome.UNSUCCESSFUL:
          waitingForEngineStateUpdate.current = false;
          saveEngine();
          break;
        case KeyValuePairSubmitOutcome.SUCCESSFUL:
          waitingForEngineStateUpdate.current = true;
          break;
        case KeyValuePairSubmitOutcome.INVALID:
          waitingForEngineStateUpdate.current = false;
          break;
        default:
          saveEngine();
      }
    } else {
      void saveEngine();
    }
  }, [saveEngine, advancedConfigurationRef.current, waitingForEngineStateUpdate.current]);

  useEffect(() => {
    if (waitingForEngineStateUpdate.current) {
      waitingForEngineStateUpdate.current = false;
      void saveEngine();
    }
  }, [engineState, selectedProfileOption]);

  useEffect(() => {
    if (selectedProfileOption && op === AIEMOpType.create) {
      const newEngineState = {
        ...getEngineFromConstraintSet(selectedProfileOption),
        ...(!isH2ODefaultProfileSelected ? { profile: selectedProfileOption.name } : {}),
      } as AIEngine;
      updateEngine(newEngineState);
    }
  }, [selectedProfileOption]);

  useEffect(() => {
    void prepareConfiguration();
  }, []);

  return (
    <div style={{ paddingBottom: '50px' }}>
      <form>
        <Stack>
          <DisplayAndId
            engine={engine}
            onDisplayNameChange={onDisplayNameChange}
            onIdChange={onIdChange}
            editableId={op === AIEMOpType.create}
          />
          <Stack {...inputRowProps}>
            <Stack {...inputContainerProps}>
              <Dropdown
                label="Profile"
                selectedKey={selectedProfileOption?.name}
                options={profileOptions}
                onChange={(_, option) => handleProfileOptionSelect(undefined, option?.data)}
              />
            </Stack>
          </Stack>
          {!isLoading && profileOptions.length === 0 && (
            <Stack>
              <p style={{ margin: '-8px 0 20px', color: 'var(--h2o-red500, #ba2525)' }}>
                There are no available profiles to chose.
              </p>
            </Stack>
          )}
          {selectedProfileOption && (
            <Stack>
              {engineType === Engine_Type.H2O && (
                <Stack {...inputRowProps}>
                  <Stack {...inputContainerProps}>
                    <Dropdown
                      label="Dataset Parameters"
                      selectedKey={selectedConfigurationOption?.key}
                      options={H2OEngineDefaultProfiles}
                      onChange={(_, option) => handleConfigurationOptionSelect(undefined, option)}
                    />
                  </Stack>
                </Stack>
              )}
              <Stack {...inputRowProps}>
                <Stack {...inputContainerProps}>
                  {op === AIEMOpType.create ? (
                    <Dropdown
                      label="Version"
                      placeholder="Select a Version"
                      options={versions}
                      selectedKey={selectedVersion}
                      onChange={handleVersionChange}
                    />
                  ) : (
                    <TextField
                      name="version"
                      label="Version"
                      value={engineState?.version}
                      onChange={(e: any) => updateEngine({ version: e.event.value })}
                      readOnly
                    />
                  )}
                </Stack>
              </Stack>
              <ConfigureCustomEngineProfile
                selectedConfigurationOption={selectedConfigurationOption}
                engine={engineState}
                constraintSet={selectedProfileOption}
                modifyEngine={updateEngine}
                operationCreate={op === AIEMOpType.create}
              />
              <Accordion title="Timeout Configuration" isClose styles={{ title: { fontWeight: 400 } }}>
                <Stack {...sliderFormRowProps} styles={{ root: { width: 400, height: 77 } }}>
                  <Stack {...sliderContainerProps}>
                    <ConstraintInput
                      constraintType={ConstraintType.MAXIDLEDURATION}
                      constraint={selectedProfileOption?.maxIdleDurationConstraint}
                      value={engineState?.[ConstraintType.MAXIDLEDURATION]}
                      onChange={(_event, value) => {
                        const fieldName = ConstraintType.MAXIDLEDURATION as string;
                        updateEngine({ [fieldName]: value });
                      }}
                      label="Max Idle Time (Hours)"
                      tooltip="Specify the maximum idle time of the Driverless AI instance. Instance will pause if it is idle for longer
                    than max idle time. When the instance pauses, it can be started again."
                    />
                  </Stack>
                </Stack>
                <Stack {...sliderFormRowProps} styles={{ root: { width: 400, height: 77 } }}>
                  <Stack {...sliderContainerProps}>
                    <ConstraintInput
                      label="Max Up Time (Hours)"
                      constraintType={ConstraintType.MAXRUNNINGDURATION}
                      constraint={selectedProfileOption?.maxRunningDurationConstraint}
                      value={engineState?.[ConstraintType.MAXRUNNINGDURATION]}
                      onChange={(_event, value) => {
                        const fieldName = ConstraintType.MAXRUNNINGDURATION as string;
                        updateEngine({ [fieldName]: value });
                      }}
                      tooltip="Set the duration after which the instance automatically pauses. When the instance pauses, it can be started again."
                    />
                  </Stack>
                </Stack>
              </Accordion>

              {engineType === Engine_Type.DRIVERLESS_AI && !isConfigEditabilityDisabled && (
                <Accordion
                  title="Advanced Configuration (config.toml)"
                  styles={{
                    title: { fontWeight: 400 },
                    root: { marginTop: 12, marginBottom: 40, height: 'unset !important' },
                  }}
                >
                  <AdvancedConfiguration
                    validation={validateAdvancedConfiguration}
                    editOnly={
                      (selectedProfileOption as DAIEngineProfile)?.configEditability ===
                      DAIEngineProfile_ConfigEditability.BASE_CONFIG_ONLY
                    }
                    ref={advancedConfigurationRef}
                    config={engineState.config || {}}
                    onConfigChange={(updatedConfig) => {
                      updateEngine({ config: updatedConfig });
                    }}
                  />
                </Accordion>
              )}
            </Stack>
          )}
        </Stack>
      </form>
      <EditablePanelFooter
        onCancel={onCancel}
        onSave={onClickSave}
        closeButtonText={op === AIEMOpType.create ? 'Back' : 'Close'}
        saveButtonText={op === AIEMOpType.create ? `Create` : `Save`}
        saveButtonDisabled={!selectedProfileOption || !isEngineValid || !isNew()}
      />
    </div>
  );
}
