import { IButtonProps, IStackProps, Stack } from '@fluentui/react';
import {
  Button,
  IconName,
  buttonStylesDefault,
  buttonStylesIcon,
  buttonStylesPrimary,
  buttonStylesSplit,
  buttonStylesSplitGhost,
} from '@h2oai/ui-kit';
import { MouseEvent } from 'react';
import { CSSTransition } from 'react-transition-group';

import { EngineProfilesColumnProps, EntityModelTypes, EntityRowState } from './types';

const rowStackProps: IStackProps = {
  style: { height: '100%' },
  horizontal: true,
  horizontalAlign: 'end',
  verticalAlign: 'center',
};

export const onRenderActionsCell = <EntityModel extends EntityModelTypes>(
  group: EntityModel,
  props: EngineProfilesColumnProps<EntityModel>,
  index?: number
) => {
  const { entity, theme, activeEntity, onSave, handleDeleteEntity, updateActiveEntityState } = props;
  const isNewModelItem = activeEntity?.rowState === EntityRowState.CREATE && index === 0;
  const expanded = (!!activeEntity && activeEntity.model.name === group.name) || isNewModelItem;
  const isCreate = expanded && activeEntity?.rowState === EntityRowState.CREATE;
  const isEdit = expanded && activeEntity?.rowState === EntityRowState.EDIT;

  const toggleActiveEntity = (event: MouseEvent<any>) => (model?: EntityModel, entityState?: EntityRowState) => {
    event.stopPropagation();
    updateActiveEntityState(model, entityState);
  };

  return (
    <Stack {...rowStackProps}>
      <Stack
        tokens={{ childrenGap: 8 }}
        styles={{ root: { position: 'relative', paddingRight: 8 } }}
        horizontalAlign="end"
      >
        <CSSTransition in={isCreate || isEdit} timeout={300} classNames="slide" unmountOnExit>
          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <Button styles={buttonStylesDefault} onClick={(event) => toggleActiveEntity(event)()}>
              Cancel
            </Button>
            <Button styles={buttonStylesPrimary} onClick={onSave} disabled={!isCreate && !props.formChanged}>
              {isCreate ? 'Add' : 'Update'} {entity.displayName}
            </Button>
          </Stack>
        </CSSTransition>
        <CSSTransition in={!isCreate && !isEdit} timeout={300} classNames="slide" unmountOnExit>
          <Button
            split
            styles={[buttonStylesSplit, buttonStylesSplitGhost, { root: { width: 80 } }]}
            onClick={(event) => toggleActiveEntity(event)(group, EntityRowState.EDIT)}
            primaryActionButtonProps={
              {
                'data-test': `${group.name}--edit-button`,
                text: 'Edit',
              } as IButtonProps
            }
            splitButtonMenuProps={{ 'data-test': `${group.name}--more-button` } as IButtonProps}
            text="Edit"
            menuItems={[
              {
                key: 'delete-entity',
                text: 'Delete',
                'data-test': `${group.name}--delete-button`,
                onClick: () => handleDeleteEntity(group.name || ''),
                style: { color: theme.semanticColors?.buttonDanger },
                iconProps: {
                  iconName: 'Delete',
                  style: { color: 'var(--h2o-red400)' },
                },
              },
            ]}
          />
        </CSSTransition>
      </Stack>

      {!isCreate && (
        <Button
          onClick={(event) => toggleActiveEntity(event)(group, isEdit ? undefined : EntityRowState.EDIT)}
          title="Expand"
          iconName={IconName.ChevronRight}
          styles={[
            buttonStylesIcon,
            {
              root: { height: 32, width: 40 },
              icon: {
                marginRight: 0,
                transform: expanded ? 'rotate(90deg)' : 'rotate(0deg)',
                transformOrigin: '50% 50%',
                transition: 'transform 0.1s linear 0s',
              },
            },
          ]}
        />
      )}
    </Stack>
  );
};
