import { IDropdownOption } from '@fluentui/react';
import { IColumn } from '@fluentui/react/lib/DetailsList';

import { RPC } from '../gen/runtime';
import { ConstraintType } from '../types';

export type HasName = {
  name?: string;
};

export type AIEMError = {
  code: number;
  message: string;
  details: any[];
};

export enum EntityActionType {
  Create = 'Create',
  Get = 'Get',
  List = 'List',
  Update = 'Update',
  Delete = 'Delete',
  Reorder = 'Reorder',
  Assign = 'Assign',
}

type EntityActionTypeMSWRestHTTPMethod = {
  [key in EntityActionType]: 'post' | 'get' | 'patch' | 'put' | 'delete';
};

export const entityActionTypeHTTPMethod: EntityActionTypeMSWRestHTTPMethod = {
  [EntityActionType.Create]: 'post',
  [EntityActionType.Get]: 'get',
  [EntityActionType.List]: 'get',
  [EntityActionType.Update]: 'patch',
  [EntityActionType.Delete]: 'delete',
  [EntityActionType.Reorder]: 'post',
  [EntityActionType.Assign]: 'post',
};

export type EntityAction<EntityModel, EntityRequest, EntityResponse> = {
  name: string;
  type: EntityActionType;
  entityType: EntityType;
  rpc: RPC<EntityRequest, EntityResponse>;
  mockPath?: string; // if we need a special path for mocks - e.g. if the path definition includes delimiters or interpolation
  mockResponse?: EntityResponse | ((req: EntityRequest) => EntityResponse);
  responseKey?: keyof EntityResponse;
  requestNameKey?: keyof EntityRequest;
  requestPayloadKey?: keyof EntityRequest;
  payloadKey?: keyof EntityModel;
  payloadIdKey?: keyof EntityModel | keyof HasName;
};

// entity types
export enum EntityType {
  DAIProfile = 'DAIProfile',
  InternalDAIVersion = 'InternalDAIVersion',
  InternalH2OVersion = 'InternalH2OVersion',
  DefaultDAISetup = 'DefaultDAISetup',
  DefaultH2OSetup = 'DefaultH2OSetup',
  DAIEngineProfile = 'DAIEngineProfile',
  H2OEngineProfile = 'H2OEngineProfile',
}

export enum EntityFieldType {
  Text = 'Text',
  Number = 'Number',
  Bytes = 'Bytes',
  Boolean = 'Boolean',
  SelectEnum = 'SelectEnum',
  StringArray = 'StringArray',
  ReadOnlyStringArray = 'ReadOnlyStringArray',
  ReadOnly = 'ReadOnly',
  Order = 'Order',
  KeyValuePair = 'KeyValuePair',
  Hidden = 'Hidden',
  Semver = 'Semver',
  LatestAndAliases = 'LatestAndAliases',
  DisplayOnDisplayAndId = 'DisplayOnDisplayAndId',
  IdOnDisplayAndId = 'IdOnDisplayAndId',
  ConstraintNumeric = 'ConstraintNumeric',
  ConstraintDuration = 'ConstraintDuration',
  Duration = 'Duration',
  Yaml = 'Yaml',
}

export type EntityField<EntityModel> = {
  name: keyof EntityModel;
  type: EntityFieldType;
  label: string;
  description?: string;
  required?: boolean;
  options?: IDropdownOption[];
  selectedOption?: string;
  minimum?: string | number;
  relayValueTo?: [keyof EntityModel];
  readOnlyOnEdit?: boolean;
  constraintType?: ConstraintType;
};

export type Entity<EntityModel> = {
  type: EntityType;
  name: string; // name id
  displayName: string; // to be used with tab labels
  description?: string;
  createButtonLabel?: string;
  actions: { [key in EntityActionType]?: EntityAction<EntityModel, any, any> };
  fields: EntityField<EntityModel>[]; // form fields
  columns: IColumn[]; // table columns
  order?: number;
  emptyModel: EntityModel; // empty model for create
  canBeReordered: boolean;
  filterFields?: (keyof EntityModel)[];
  sortFields?: (keyof EntityModel)[];
  requestNameKeyPrefix?: string;
  displayNameKey: keyof EntityModel;
};
