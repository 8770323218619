// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/dai_engine_profile.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { ProfileConstraintDuration, ProfileConstraintNumeric } from "./profile_constraint_pb";

/**
 * DAIEngineProfile represents a set of values that are used for DAIEngine.
 *
 * @generated from message ai.h2o.engine.v1.DAIEngineProfile
 */
export type DAIEngineProfile = {
/**
 * Output only. Resource name.
 * Format: workspaces/*\/daiEngineProfiles/*
 *
 * @generated from field: string name = 1;
 */
name?: string;
/**
 * Optional. Human-readable name.
 *
 * @generated from field: string display_name = 2;
 */
displayName?: string;
/**
 * Optional. Priority of the DAIEngineProfile. Lower value means higher priority.
 * Priority is NOT a unique value (any two DAIEngineProfiles can have the same priority value).
 *
 * @generated from field: int32 priority = 3;
 */
priority?: number;
/**
 * Optional. When set to true, the DAIEngineProfile is enabled and can be used in DAIEngine.
 * When set to false, the DAIEngineProfile is disabled and cannot be used in any DAIEngine.
 *
 * @generated from field: bool enabled = 4;
 */
enabled?: boolean;
/**
 * Optional. When set to true, the assigned_oidc_roles field is verified when a user uses this profile.
 *
 * @generated from field: bool assigned_oidc_roles_enabled = 5;
 */
assignedOidcRolesEnabled?: boolean;
/**
 * Optional. List of OIDC roles assigned to this DAIEngineProfile.
 *
 * When profile has assigned some OIDC roles and verification of this list is enabled
 * (assigned_oidc_roles_enabled=true), then this profile can be used only by users who have assigned
 * at least one role from this list.
 *
 * Example 1: assigned_oidc_roles = ["role1", "role2"].
 * This profile can be used only by users who have assigned
 * either role1 or role2.
 *
 * Example 2 (special case): assigned_oidc_roles = [].
 * Empty list means that the profile has not assigned any OIDC role,
 * therefore this profile cannot be used by any user.
 *
 * @generated from field: repeated string assigned_oidc_roles = 6;
 */
assignedOidcRoles?: string[];
/**
 * Optional. Maximum number of DAIEngines per user that can be running when using this DAIEngineProfile.
 *
 * @generated from field: optional int32 max_running_engines = 7;
 */
maxRunningEngines?: number;
/**
 * Required. Constraint for each DAIEngine's cpu that uses this profile.
 *
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintNumeric cpu_constraint = 8;
 */
cpuConstraint: ProfileConstraintNumeric;
/**
 * Required. Constraint for each DAIEngine's gpu that uses this profile.
 *
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintNumeric gpu_constraint = 9;
 */
gpuConstraint: ProfileConstraintNumeric;
/**
 * Required. Constraint for each DAIEngine's memory_bytes that uses this profile.
 *
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintNumeric memory_bytes_constraint = 10;
 */
memoryBytesConstraint: ProfileConstraintNumeric;
/**
 * Required. Constraint for each DAIEngine's storage_bytes that uses this profile.
 *
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintNumeric storage_bytes_constraint = 11;
 */
storageBytesConstraint: ProfileConstraintNumeric;
/**
 * Required. Constraint for each DAIEngine's max_idle_duration that uses this profile.
 *
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintDuration max_idle_duration_constraint = 12;
 */
maxIdleDurationConstraint: ProfileConstraintDuration;
/**
 * Required. Constraint for each DAIEngine's max_running_duration that uses this profile.
 *
 * @generated from field: ai.h2o.engine.v1.ProfileConstraintDuration max_running_duration_constraint = 13;
 */
maxRunningDurationConstraint: ProfileConstraintDuration;
/**
 * Optional. Max non-interation duration applied on all DAIEngines that use this profile.
 *
 * @generated from field: optional google.protobuf.Duration max_non_interaction_duration = 14;
 */
maxNonInteractionDuration?: string | null;
/**
 * Optional. Max unused duration applied on all DAIEngines that use this profile.
 *
 * @generated from field: optional google.protobuf.Duration max_unused_duration = 15;
 */
maxUnusedDuration?: string | null;
/**
 * Optional. configuration_override is applied on top of all other configurations
 * when creating the final configuration that is passed to the DAI server.
 *
 * The whole config setup priority is:
 * final_configuration = configuration_override > core_configuration (hardcoded on AIEM server) > DAIEngine.config > base_configuration
 * where higher priority is always applied on top of the lower priority.
 *
 * Example:
 * - AIEM server has hardcoded core_configuration={"key1": "hardcoded-val1", "key2": "hardcoded-val2"}
 * - Admin sets DAIEngineProfile with base_configuration={"key3": "base-val3", "key4": "base-val4"}
 * - Admin sets DAIEngineProfile with profile config_editability=CONFIG_EDITABILITY_FULL
 * - Admin sets DAIEngineProfile with configuration_override={"key1": "override-val1", "key5": "override-val5"}
 * - User creates DAIEngine with this profile. User sets DAIEngine.config={"key3": "user-val3", "key6": "user-val6"}
 *
 * The final configuration is composed as:
 * 1) Start with base_configuration: {"key3": "base-val3", "key4": "base-val4"}
 * 2) Apply DAIEngine.config on top: {"key3": "user-val3", "key4": "base-val4", "key6": "user-val6"}
 * 3) Apply hardcoded core_configuration on top: {"key1": "hardcoded-val1", "key2": "hardcoded-val2",
 * "key3": "user-val3", "key4": "base-val4", "key6": "user-val6"}
 * 4) Apply configuration_override on top: {"key1": "override-val1", "key2": "hardcoded-val2",
 * "key3":"user-val3", "key4": "base-val4", "key5": "override-val5", "key6": "user-val6"}.
 *
 * @generated from field: map<string, string> configuration_override = 16;
 */
configurationOverride?: { [key: string]: string };
/**
 * Optional. base configuration forms the basis of the final configuration that is passed to the DAI server.
 * See configuration_override for the overall configuration setup logic.
 *
 * @generated from field: map<string, string> base_configuration = 17;
 */
baseConfiguration?: { [key: string]: string };
/**
 * Required. Specifies the behavior of DAIEngine.config editability when DAIEngine is using this profile.
 * See configuration_override for the overall configuration setup logic.
 *
 * @generated from field: ai.h2o.engine.v1.DAIEngineProfile.ConfigEditability config_editability = 18;
 */
configEditability: DAIEngineProfile_ConfigEditability;
/**
 * Optional. YAML representation of custom PodTemplateSpec.
 * Definition of PodTemplateSpec: https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.27/#podtemplatespec-v1-core
 *
 * When specified, then it is applied for each DAIEngine that uses this profile.
 * PodTemplateSpec describes what will be applied on top of a regular DriverlessAI pod before it is created.
 * This template is merged into DriverlessAI default pod using StrategicMergePatch method (it overrides the
 * default pod).
 * More info about StrategicMergePatch: https://kubernetes.io/docs/tasks/manage-kubernetes-objects/update-api-object-kubectl-patch/
 *
 * Example YAML value (custom annotation applied for each DAI pod):
 * metadata:
 *  annotations:
 *    custom-key: custom-value
 *
 * @generated from field: string yaml_pod_template_spec = 19;
 */
yamlPodTemplateSpec?: string;
/**
 * Optional. YAML representation of custom GPU Tolerations.
 * Definition of one Toleration: https://kubernetes.io/docs/reference/generated/kubernetes-api/v1.27/#toleration-v1-core
 *
 * When specified, then it is applied for each DAIEngine that uses this profile.
 * GPUTolerations sets DriverlessAI's pod.spec.tolerations in case DAIEngine has GPU > 0. This will override
 * any tolerations defined in yaml_pod_template_spec.PodSpec.Tolerations field.
 *
 * Example YAML value (two GPU tolerations applied for each DAI pod):
 *  - key: "dedicated"
 *    operator: "Equal"
 *    value: "gpu"
 *    effect: "NoSchedule"
 *  - key: "key2"
 *    operator: "Equal"
 *    value: "value2"
 *    effect: "NoSchedule"
 *
 * @generated from field: string yaml_gpu_tolerations = 20;
 */
yamlGpuTolerations?: string;
/**
 * Optional. True when DAI built-in Triton inference server is enabled, false when it is disabled.
 *
 * @generated from field: bool triton_enabled = 21;
 */
tritonEnabled?: boolean;
/**
 * Output only. Time when the DAIEngineProfile was created.
 *
 * @generated from field: google.protobuf.Timestamp create_time = 22;
 */
createTime?: string;
/**
 * Output only. Time when the DAIEngineProfile was last updated.
 *
 * @generated from field: optional google.protobuf.Timestamp update_time = 23;
 */
updateTime?: string;
/**
 * Output only. Name of entity that created the DAIEngineProfile.
 *
 * @generated from field: string creator = 24;
 */
creator?: string;
/**
 * Output only. Name of entity that last updated the DAIEngineProfile.
 *
 * @generated from field: string updater = 25;
 */
updater?: string;
/**
 * Output only. Human-readable name of entity that created the DAIEngineProfile.
 *
 * @generated from field: string creator_display_name = 26;
 */
creatorDisplayName?: string;
/**
 * Output only. Human-readable name of entity that last updated the DAIEngineProfile.
 *
 * @generated from field: string updater_display_name = 27;
 */
updaterDisplayName?: string;
}
;
/**
 * All possible DAIEngine.config editability options.
 *
 * @generated from enum ai.h2o.engine.v1.DAIEngineProfile.ConfigEditability
 */
export enum DAIEngineProfile_ConfigEditability {
/**
 * ConfigEditability is unspecified.
 *
 * @generated from enum value: CONFIG_EDITABILITY_UNSPECIFIED = 0;
 */
UNSPECIFIED = "CONFIG_EDITABILITY_UNSPECIFIED",

/**
 * Specifying DAIEngine.config is not allowed.
 *
 * @generated from enum value: CONFIG_EDITABILITY_DISABLED = 1;
 */
DISABLED = "CONFIG_EDITABILITY_DISABLED",

/**
 * Specifying DAIEngine.config is allowed only when it overrides values from base_configuration.
 *
 * @generated from enum value: CONFIG_EDITABILITY_BASE_CONFIG_ONLY = 2;
 */
BASE_CONFIG_ONLY = "CONFIG_EDITABILITY_BASE_CONFIG_ONLY",

/**
 * Specifying DAIEngine.config is allowed in full.
 *
 * @generated from enum value: CONFIG_EDITABILITY_FULL = 3;
 */
FULL = "CONFIG_EDITABILITY_FULL",
}
