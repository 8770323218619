import { ConfigurableEndpoint } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/endpoint_pb';
import {
  CreateEndpointRequest,
  ListEndpointsRequest,
} from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/endpoint_service_pb';
import { EndpointService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/endpoint_service_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { IDropdownOption, IStyle, MessageBarType, Stack } from '@fluentui/react';
import {
  BaseDialog,
  Button,
  IH2OTheme,
  Info,
  TextField,
  TextWithCopy,
  buttonStylesGhost,
  buttonStylesPrimary,
  textWithCopyStylesBorder,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';

import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
import { ENDPOINTS } from './constants';
import { useProjects } from './ProjectProvider';

export interface IDeploymentTabEndpointsProps {
  isNew: boolean;
  scorerEndpoint?: string;
  deploymentId?: string;
  deploymentEnvironmentId?: string;
}

interface IDeploymentTabEndpointsStyles {
  form: IStyle;
  dialogInput: IStyle;
  customEndpointButton: IStyle;
}

const deploymentTabEndpointsStyles = (theme: IH2OTheme): IDeploymentTabEndpointsStyles => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 20,
    margin: '0px 40px',
    // TODO: Remove once theme is used.
    borderColor: theme.semanticColors?.bodyBackground,
  },
  dialogInput: {
    width: '30%',
    minWidth: 360,
    maxWidth: 460,
    marginRight: 12,
  },
  customEndpointButton: {
    margin: '6px 0',
  },
});

export const DeploymentTabEndpoints = ({
  isNew,
  scorerEndpoint,
  deploymentId,
  deploymentEnvironmentId,
}: IDeploymentTabEndpointsProps) => {
  const theme = useTheme(),
    classNames = useClassNames<IDeploymentTabEndpointsStyles, ClassNamesFromIStyles<IDeploymentTabEndpointsStyles>>(
      'deploymentTabEndpoints',
      deploymentTabEndpointsStyles(theme)
    ),
    // TODO: Handle loading.
    [, setLoading] = React.useState(true),
    [endpoints, setEndpoints] = React.useState<ConfigurableEndpoint[]>(),
    [endpointOptions, setEndpointOptions] = React.useState<IDropdownOption[]>([]),
    [endpointPrefix, setEndpointPrefix] = React.useState(''),
    [endpointSuffix, setEndpointSuffix] = React.useState(''),
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    deploymentTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.deployment}/`,
    }),
    endpointClient = createPromiseClient(EndpointService, deploymentTransport),
    [dialogVisible, setDialogVisible] = React.useState(false),
    [showValidation, setShowValidation] = React.useState(false),
    [customEndpointPath, setCustomEndpointPath] = React.useState(''),
    [endpointName, setEndpointName] = React.useState(''),
    [endpointDescription, setEndpointDescription] = React.useState(''),
    [deploymentTarget, setDeploymentTarget] = React.useState(''),
    { addToast } = useToast(),
    { ACTIVE_PROJECT_ID, permissions } = useProjects(),
    loadStateRef = React.useRef({
      creatingEndpoint: false,
      fetchEndpoints: false,
    }),
    evaluateLoading = () => {
      if (!loadStateRef.current.creatingEndpoint && !loadStateRef.current.fetchEndpoints) {
        setLoading(false);
      }
    },
    getEndpoints = React.useCallback(async () => {
      if (!ACTIVE_PROJECT_ID || !deploymentEnvironmentId) return;
      loadStateRef.current.fetchEndpoints = true;
      setLoading(true);
      try {
        const parentEnv = `projects/${ACTIVE_PROJECT_ID}/environments/${deploymentEnvironmentId}`,
          listEndpointsBody = new ListEndpointsRequest({
            parent: parentEnv,
          }),
          response = await endpointClient.listEndpoints(listEndpointsBody),
          endpoints: ConfigurableEndpoint[] | undefined = response?.endpoints;
        setEndpoints(endpoints);
        if (response && !endpoints) console.error('No endpoints found in the response.');
      } catch (err) {
        const message = `Failed to fetch endpoints: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setEndpoints(undefined);
      } finally {
        loadStateRef.current.fetchEndpoints = false;
        evaluateLoading();
      }
    }, [ACTIVE_PROJECT_ID, addToast, deploymentEnvironmentId]),
    createCustomEndpoint = React.useCallback(
      async (deploymentId: string, path: string, displayName: string, description?: string) => {
        if (!ACTIVE_PROJECT_ID || !deploymentEnvironmentId) return;
        loadStateRef.current.creatingEndpoint = true;
        try {
          const parentEnv = `projects/${ACTIVE_PROJECT_ID}/environments/${deploymentEnvironmentId}`;
          const createEndpointBody = new CreateEndpointRequest({
            parent: parentEnv,
            endpoint: new ConfigurableEndpoint({
              displayName,
              description,
              path,
              target: `${parentEnv}/deployments/${deploymentId}`,
            }),
          });
          await endpointClient.createEndpoint(createEndpointBody);
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Endpoint created successfully.',
          });
        } catch (err) {
          const message = `Failed to create endpoint: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.creatingEndpoint = false;
          evaluateLoading();
          getEndpoints();
        }
      },
      [addToast, ACTIVE_PROJECT_ID, deploymentEnvironmentId]
    ),
    onCreateEndpointAction = React.useCallback(() => {
      setDeploymentTarget(deploymentId || '');
      setDialogVisible(true);
    }, [createCustomEndpoint, deploymentId]),
    onDialogDismiss = () => {
      setDialogVisible(false);
      setDeploymentTarget('');
      setCustomEndpointPath('');
      setEndpointName('');
      setEndpointDescription('');
      setShowValidation(false);
    },
    onEndpointCreate = () => {
      if (!customEndpointPath || !endpointName) {
        setShowValidation(true);
        return;
      }
      setDialogVisible(false);
      createCustomEndpoint(deploymentTarget, customEndpointPath, endpointName, endpointDescription);
    };

  React.useEffect(() => void getEndpoints(), [getEndpoints]);

  React.useEffect(() => {
    if (!ACTIVE_PROJECT_ID || !deploymentEnvironmentId) return;
    if (deploymentId && deploymentEnvironmentId && endpoints && scorerEndpoint) {
      // The hardcoded fallback value is for local mocks.
      const parentEnv = `projects/${ACTIVE_PROJECT_ID}/environments/${deploymentEnvironmentId}`;
      const [urlPrefix, urlSuffix] = scorerEndpoint.includes(deploymentId)
        ? scorerEndpoint.split(deploymentId)
        : ['', ''];
      if (urlPrefix && !endpointPrefix) setEndpointPrefix(urlPrefix);
      if (urlSuffix && !endpointSuffix) setEndpointSuffix(urlSuffix);
      const endpointOptions: IDropdownOption[] = [];
      if (scorerEndpoint) {
        endpointOptions.push({
          key: scorerEndpoint,
          text: 'Scorer (default)',
        });
      }
      if (endpoints?.length) {
        endpointOptions.push(
          ...endpoints
            .filter((endpoint) => endpoint.target.includes(`${parentEnv}/deployments/${deploymentId}`))
            .map((endpoint) => ({
              key: `${urlPrefix}${endpoint.path}${urlSuffix}`,
              text: endpoint.displayName,
            }))
        );
      }
      setEndpointOptions(endpointOptions);
    }
  }, [deploymentEnvironmentId, deploymentId, scorerEndpoint, endpoints, ACTIVE_PROJECT_ID]);

  return (
    <div className={classNames.form}>
      <BaseDialog
        hidden={!dialogVisible}
        title={'Create new endpoint'}
        content={
          <>
            <p>If the default scorer endpoint is not suitable, you can create the endpoint with a custom path.</p>
            <TextField
              required
              label="Name"
              value={endpointName}
              className={classNames.dialogInput}
              onChange={(_ev, newValue) => setEndpointName(newValue || '')}
              errorMessage={showValidation && !endpointName ? 'This field cannot be empty.' : ''}
            />
            <TextField
              label="Description"
              value={endpointDescription}
              className={classNames.dialogInput}
              onChange={(_ev, newValue) => setEndpointDescription(newValue || '')}
            />
            <TextField
              required
              label="Path"
              value={customEndpointPath}
              className={classNames.dialogInput}
              onChange={(_ev, newValue) => setCustomEndpointPath(newValue || '')}
              errorMessage={showValidation && !customEndpointPath ? 'This field cannot be empty.' : ''}
            />
            {customEndpointPath ? (
              <Info>
                <b>Endpoint URL preview: </b>
                {`${endpointPrefix}${customEndpointPath}${endpointSuffix}`}
              </Info>
            ) : null}
          </>
        }
        onDismiss={onDialogDismiss}
        footer={
          <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: 10 }}>
            <Button onClick={onDialogDismiss} text="Cancel" />
            <Button styles={buttonStylesPrimary} onClick={onEndpointCreate} iconName="Add" text="Create endpoint" />
          </Stack>
        }
      />
      {endpointOptions.length
        ? endpointOptions.map((option) => (
            <TextWithCopy
              key={option.key}
              hasBorder
              header={option.text}
              styles={[
                textWithCopyStylesBorder,
                {
                  root: { marginBottom: 10 },
                  header: { minWidth: 180 },
                },
              ]}
              text={option.key as string}
            />
          ))
        : isNew
        ? `A default scorer endpoint will be assigned to this deployment after creation.`
        : 'No endpoints available.'}
      {isNew || !permissions.canWrite ? null : (
        <div className={classNames.customEndpointButton}>
          <Button text="Add custom endpoint" onClick={onCreateEndpointAction} styles={buttonStylesGhost} />
        </div>
      )}
    </div>
  );
};
