import { AIEngine } from '../../../../../aiem/engine/types';
import { AdjustedDAIProfile } from '../../../../../aiem/gen/ai/h2o/engine/v1/adjusted_dai_profile_pb';
import { H2OEngineProfile } from '../../../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_profile_pb';
import EngineProfileTableRows from './EngineProfileTableRows';

interface DisplayPresetEngineProfileProps {
  engine: AIEngine;
  constraintSet?: H2OEngineProfile;
  profile?: AdjustedDAIProfile;
}

export default function DisplayPresetEngineProfile({
  engine,
  constraintSet,
  profile,
}: DisplayPresetEngineProfileProps) {
  return <EngineProfileTableRows loading={!constraintSet} engine={engine} profile={profile} />;
}
