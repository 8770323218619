import { IColumn } from '@fluentui/react';
import { ListCell } from '@h2oai/ui-kit';

import { onRenderBooleanCell } from '../../utils/render';
import { H2OEngineProfile } from '../gen/ai/h2o/engine/v1/h2o_engine_profile_pb';
import {
  CreateH2OEngineProfileRequest,
  CreateH2OEngineProfileResponse,
  DeleteH2OEngineProfileRequest,
  DeleteH2OEngineProfileResponse,
  GetH2OEngineProfileRequest,
  GetH2OEngineProfileResponse,
  H2OEngineProfileService_CreateH2OEngineProfile,
  H2OEngineProfileService_DeleteH2OEngineProfile,
  H2OEngineProfileService_GetH2OEngineProfile,
  H2OEngineProfileService_ListH2OEngineProfiles,
  H2OEngineProfileService_UpdateH2OEngineProfile,
  ListH2OEngineProfilesRequest,
  ListH2OEngineProfilesResponse,
  UpdateH2OEngineProfileRequest,
  UpdateH2OEngineProfileResponse,
} from '../gen/ai/h2o/engine/v1/h2o_engine_profile_service_pb';
import { BigIntString } from '../gen/runtime';
import { ConstraintType } from '../types';
import { Entity, EntityAction, EntityActionType, EntityField, EntityFieldType, EntityType } from './types';
import { onRender, onRenderDisplayAndId } from './utils';

const entityType = EntityType.H2OEngineProfile;
const required = true;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateH2OEngineProfile',
    rpc: H2OEngineProfileService_CreateH2OEngineProfile,
    type: EntityActionType.Create,
    responseKey: 'h2oEngineProfile',
    requestNameKey: 'h2oEngineProfileId',
    requestPayloadKey: 'h2oEngineProfile',
    requestNameKeyPrefix: 'h2oEngineProfile/',
    entityType,
  } as EntityAction<H2OEngineProfile, CreateH2OEngineProfileRequest, CreateH2OEngineProfileResponse>,
  [EntityActionType.Get]: {
    name: 'GetH2OEngineProfile',
    rpc: H2OEngineProfileService_GetH2OEngineProfile,
    type: EntityActionType.Get,
    requestNameKey: 'name' as unknown,
    responseKey: 'h2oEngineProfile',
    entityType,
  } as EntityAction<H2OEngineProfile, GetH2OEngineProfileRequest, GetH2OEngineProfileResponse>,
  [EntityActionType.List]: {
    name: 'ListH2OEngineProfile',
    rpc: H2OEngineProfileService_ListH2OEngineProfiles,
    type: EntityActionType.List,
    responseKey: 'h2oEngineProfiles',
    entityType,
  } as EntityAction<H2OEngineProfile, ListH2OEngineProfilesRequest, ListH2OEngineProfilesResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateH2OEngineProfile',
    rpc: H2OEngineProfileService_UpdateH2OEngineProfile,
    type: EntityActionType.Update,
    responseKey: 'h2oEngineProfile',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'h2oEngineProfile',
    entityType,
  } as EntityAction<H2OEngineProfile, UpdateH2OEngineProfileRequest, UpdateH2OEngineProfileResponse>,
  [EntityActionType.Delete]: {
    name: 'DeleteH2OEngineProfile',
    rpc: H2OEngineProfileService_DeleteH2OEngineProfile,
    type: EntityActionType.Delete,
    entityType,
  } as EntityAction<H2OEngineProfile, DeleteH2OEngineProfileRequest, DeleteH2OEngineProfileResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'icon',
    name: 'Icon',
    minWidth: 50,
    maxWidth: 50,
    onRender: () => <ListCell styles={{ root: { paddingLeft: 12 } }} iconProps={{ iconName: 'Settings' }} />,
  },
  {
    key: 'displayName',
    name: 'Display Name',
    fieldName: 'displayName',
    minWidth: 200,
    maxWidth: 250,
    onRender: onRenderDisplayAndId,
  },
  {
    key: 'priority',
    name: 'Priority',
    fieldName: 'priority',
    minWidth: 80,
    maxWidth: 120,
    onRender,
  },
  {
    key: 'enabled',
    name: 'Enabled',
    fieldName: 'enabled',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

export const fields: EntityField<H2OEngineProfile>[] = [
  {
    name: 'name',
    type: EntityFieldType.IdOnDisplayAndId,
    label: 'Name',
    description: 'H2O Engine Profile resource name.',
    required,
  },
  {
    name: 'displayName',
    type: EntityFieldType.DisplayOnDisplayAndId,
    label: 'Display Name',
    description: 'Human-readable name of H2O Engine Profile.',
    required,
  },
  {
    name: 'enabled',
    type: EntityFieldType.Boolean,
    label: 'Enabled',
    description: `When set to true, the H2OEngineProfile is enabled and can be used in H2OEngine.`,
  },
  {
    name: 'assignedOidcRolesEnabled',
    type: EntityFieldType.Boolean,
    label: 'Assigned Oidc Roles Enabled',
    description: 'When set to true, the assigned_oidc_roles field is verified when a user uses this profile.',
  },
  {
    name: 'priority',
    type: EntityFieldType.Number,
    label: 'Priority',
    description: 'Priority of the H2OEngineProfile. Lower value means higher priority.',
  },
  {
    name: 'maxRunningEngines',
    type: EntityFieldType.Number,
    label: 'Max Running Engines',
    description: 'Maximum number of H2OEngines per user that can be running when using this H2OEngineProfile.',
    minimum: 1,
  },
  {
    name: 'assignedOidcRoles',
    type: EntityFieldType.StringArray,
    label: 'Assigned Oidc Roles',
    description: 'List of OIDC roles assigned to this H2OEngineProfile.',
  },
  {
    name: 'nodeCountConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'Node Count Constraint',
    description: `Constraint for each H2OEngine's node count that uses this profile.`,
    constraintType: ConstraintType.NODECOUNT,
  },
  {
    name: 'cpuConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'CPU Constraint',
    description: `Constraint for each H2OEngine's cpu in the workspace.`,
    constraintType: ConstraintType.CPU,
    minimum: 1,
  },
  {
    name: 'gpuConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'GPU Constraint',
    description: `Constraint for each H2OEngine's gpu in the workspace.`,
    constraintType: ConstraintType.GPU,
  },
  {
    name: 'memoryBytesConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'Memory Constraint (GiB)',
    description: `Constraint for each H2OEngine's memory_bytes in the workspace.`,
    constraintType: ConstraintType.MEMORYBYTES,
  },
  {
    name: 'maxIdleDurationConstraint',
    type: EntityFieldType.ConstraintDuration,
    label: 'Max Idle Duration Constraint',
    description: `Constraint for each H2OEngine's max_idle_duration in the workspace.`,
    constraintType: ConstraintType.MAXIDLEDURATION,
  },
  {
    name: 'maxRunningDurationConstraint',
    type: EntityFieldType.ConstraintDuration,
    label: 'Max Running Duration Constraint',
    description: `Constraint for each H2OEngine's max_running_duration in the workspace.`,
    constraintType: ConstraintType.MAXRUNNINGDURATION,
  },
  {
    name: 'yamlPodTemplateSpec',
    type: EntityFieldType.Yaml,
    label: 'Pod Template Specification (YAML)',
    description: `
      YAML representation of custom PodTemplateSpec.
      When specified, then it is applied for each H2OEngine that uses this profile.
    `,
  },
  {
    name: 'yamlGpuTolerations',
    type: EntityFieldType.Yaml,
    label: 'YAML GPU Tolerations',
    description: `
      YAML representation of custom GPU Tolerations.
      When specified, then it is applied for each H2OEngine that uses this profile.
    `,
  },
];

export const h2oEngineProfileEntity: Entity<H2OEngineProfile> = {
  type: EntityType.H2OEngineProfile,
  name: 'H2OEngineProfile',
  createButtonLabel: 'Add H2O Engine Profile',
  displayName: 'H2O Engine Profile',
  displayNameKey: 'displayName',
  actions,
  columns,
  fields,
  requestNameKeyPrefix: 'workspaces/global/h2oEngineProfiles/',
  emptyModel: {
    name: 'new-h2o-engine-profile-id',
    displayName: 'New H2O Engine Profile',
    priority: 0,
    enabled: true,
    assignedOidcRolesEnabled: false,
    assignedOidcRoles: [],
    maxRunningEngines: 1,
    nodeCountConstraint: {
      min: '1' as BigIntString,
      default: '1' as BigIntString,
    },
    cpuConstraint: {
      min: '1' as BigIntString,
      default: '1' as BigIntString,
    },
    gpuConstraint: {
      min: '0' as BigIntString,
      default: '0' as BigIntString,
    },
    memoryBytesConstraint: {
      min: '2147483648' as BigIntString,
      default: '4294967296' as BigIntString,
    },
    maxIdleDurationConstraint: {
      min: '0s' as BigIntString,
      default: '3600s' as BigIntString,
    },
    maxRunningDurationConstraint: {
      min: '0s' as BigIntString,
      default: '432000s' as BigIntString,
    },
    yamlPodTemplateSpec: '',
    yamlGpuTolerations: '',
  },
  canBeReordered: false,
};
