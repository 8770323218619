// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/engine_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { Engine } from "./engine_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message for listing Engines in a workspace.
 *
 * @generated from message ai.h2o.engine.v1.ListEnginesRequest
 */
export type ListEnginesRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * Authorization may require the following permission on the engine's parent workspace:
 * * actions/enginemanager/engines/LIST
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of Engines to return in a response.
 * If unspecified (or set to 0), at most 50 Engines will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListEnginesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
/**
 * Used to specify the sorting order.
 *
 * When unset, Engines are ordered by their time of creation in
 * descending order. This is equivalent to "create_time desc".
 *
 * When specified, the value must be a comma separated list of supported
 * fields. The supported fields are: {name, version, cpu, gpu, memory_bytes, storage_bytes,
 * creator, create_time, update_time, delete_time, resume_time, display_name, uid}
 *
 * The default sorting order is ascending. For example: "name" and "name asc"
 * are equivalent values.
 *
 * To specify descending order for a field, append a " desc" suffix. For
 * example: "name desc".
 *
 * Redundant space characters are insignificant. For example these values are
 * all equal:
 * - "  name, cpu     desc"
 * - "name, cpu desc"
 * - "name   , cpu desc   "
 *
 * @generated from field: string order_by = 4;
 */
orderBy?: string;
/**
 * Used to filter Engines. When unset, no filtering is applied.
 *
 * Filtering implements specification https://google.aip.dev/160.
 *
 * Supported filter fields:
 * - name
 * - engine_id
 * - version (supporting version semantic comparison, does not support aliases)
 * - deprecated_version
 * - state
 * - cpu
 * - gpu
 * - memory_bytes
 * - storage_bytes
 * - creator
 * - creator_display_name
 * - create_time
 * - update_time
 * - delete_time
 * - resume_time
 * - reconciling
 * - uid
 * - display_name
 * - max_idle_duration
 * - max_running_duration
 * - current_idle_duration
 * - current_running_duration
 *
 * Examples:
 * - `cpu > 5`
 * - `gpu < 3`
 * - `cpu >= 5 AND gpu <= 3`
 * - `cpu != 5 OR gpu == 3`
 * - `NOT (cpu > 5 OR gpu < 3)`
 * - `-(cpu > 5 OR gpu < 3)`
 * - `NOT (cpu > 5 OR (gpu < 3))`
 * - `(cpu > 5 OR gpu < 3) AND memory_bytes != 1000`
 * - `(cpu > 5 OR gpu < 3) AND (NOT ((((memory_bytes = 1000)))))`
 * - `state = STATE_RUNNING AND create_time > "2012-04-21T11:30:00-04:00"`
 * - `version >= "1.10.3"`
 * - `deprecated_version = false`
 * - `reconciling = true OR reconciling = false`
 * - `engine_id = "*e*" OR display_name = "*e*"` (supporting prefix/suffix wildcard `*` for string fields equality comparison)
 *
 * @generated from field: string filter = 5;
 */
filter?: string;
}
;
/**
 * Response message for EngineService.ListEngines.
 *
 * @generated from message ai.h2o.engine.v1.ListEnginesResponse
 */
export type ListEnginesResponse = {
/**
 * Collection of Engines.
 *
 * @generated from field: repeated ai.h2o.engine.v1.Engine engines = 1;
 */
engines?: Engine[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
/**
 * Total number of Engines matched by the List request.
 *
 * @generated from field: int32 total_size = 3;
 */
totalSize?: number;
}
;
/**
 * Returns a collection of Engines within a workspace.
 *
 * @generated from rpc ai.h2o.engine.v1.EngineService.ListEngines
 */
export const EngineService_ListEngines = new RPC<ListEnginesRequest,ListEnginesResponse>("GET", "/v1/{parent=workspaces/*}/engines");
