import { DetailsRow, IButtonProps, IColumn, IDetailsRowProps, Stack } from '@fluentui/react';
import {
  Button,
  FontSizes,
  IH2OTheme,
  ListCell,
  Loader,
  LoaderType,
  TextWithCopy,
  buttonStylesGhost,
  buttonStylesSplit,
  buttonStylesSplitGhost,
  loaderStylesProgressIndicatorButton,
} from '@h2oai/ui-kit';

import { copyHideContentsStyles } from '../../../components/AdminSettings/Entity/utils';
import { ActionCellContents } from '../../../components/ListPages/ActionCell';
import { BadgeCell } from '../../../components/ListPages/BadgeCell';
import Cell from '../../../components/ListPages/Cell';
import { Secret_State } from '../../../secure-store/gen/ai/h2o/securestore/v1/secret_pb';
import { SecretVersion } from '../../../secure-store/gen/ai/h2o/securestore/v1/secret_version_pb';
import { decodeBytes, formatDateWithTime } from '../../../utils/utils';
import { CellRendererProps, ExtendedSecret, SecretServiceColumnProps } from '../types';

const ROW_HEIGHT = 40;

export const getNameFromPath = (path?: string) => (path ? path.replace(/.*\//, '') : '');

const CellWrapper = (props: CellRendererProps) => {
  const { groupCell, onRenderCell, group, alignRight, expanded, rootStyles = {} } = props;
  const cellStyles = {
    minHeight: ROW_HEIGHT,
    maxHeight: ROW_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: alignRight ? 'flex-end' : 'flex-start',
    fontSize: FontSizes.textPrimary,
  };

  return (
    <Stack tokens={{ childrenGap: 8 }} styles={{ root: rootStyles }} className="cell-wrapper">
      <Cell alignRight={alignRight} style={cellStyles}>
        {groupCell}
      </Cell>
      {expanded && onRenderCell && (
        <>
          <div style={{ margin: '8px 0' }}></div>
          {group.data.map((secretVersion) => (
            <div key={secretVersion.name} style={{ ...cellStyles, marginTop: 18 }}>
              {onRenderCell(secretVersion)}
            </div>
          ))}
        </>
      )}
    </Stack>
  );
};

const secretStateMap: { [key in Secret_State]: string } = {
  [Secret_State.ACTIVE]: 'Active',
  [Secret_State.DELETED]: 'Deleted',
  [Secret_State.UNSPECIFIED]: 'Unspecified',
};

export const getSecretState = (theme: IH2OTheme, secretState: Secret_State, hideTitle?: boolean) => {
  const statusTextColorMap = {
    [Secret_State.ACTIVE]: theme.palette?.green900,
    [Secret_State.DELETED]: theme.palette?.red900,
    [Secret_State.UNSPECIFIED]: theme.semanticColors?.textPrimary,
  };

  const statusBackgroundColorMap = {
    [Secret_State.ACTIVE]: theme.palette?.green100,
    [Secret_State.DELETED]: theme.palette?.red100,
    [Secret_State.UNSPECIFIED]: theme.palette?.gray300,
  };

  return (
    <BadgeCell
      titleStyles={{ color: theme.palette?.gray700, fontWeight: '600' }}
      title={hideTitle ? '' : 'State'}
      dataTest="secret-state-badge"
      badgeLabel={secretStateMap[secretState]}
      badgeBackgroundColor={statusBackgroundColorMap[secretState]}
      badgeTextColor={statusTextColorMap[secretState]}
    />
  );
};

const onRenderTitleCell = (group: ExtendedSecret, props: SecretServiceColumnProps) => {
  const expanded = props.expandedGroupKeys.includes(group.name);

  return (
    <CellWrapper
      label=""
      expanded={expanded}
      group={group}
      groupCell={
        <ListCell
          styles={{ root: { paddingLeft: 12 } }}
          iconProps={{ iconName: 'Permissions' }}
          headerText="Name"
          onRenderText={() => (
            <TextWithCopy
              text={`${group.name}/versions/latest`}
              header={getNameFromPath(group.name)}
              styles={copyHideContentsStyles}
            />
          )}
        />
      }
      onRenderCell={(secretVersion) =>
        secretVersion.name ? (
          <ListCell
            key={secretVersion.name}
            styles={{ root: { paddingLeft: 12 } }}
            headerText="Version"
            onRenderText={() => (
              <TextWithCopy
                text={secretVersion.name}
                header={getNameFromPath(secretVersion.name)}
                styles={copyHideContentsStyles}
              />
            )}
          />
        ) : null
      }
    />
  );
};
const onRenderCreatedOn = (group: ExtendedSecret, props: SecretServiceColumnProps) => {
  const expanded = props.expandedGroupKeys.includes(group.name);

  const renderCell = (cellData?: SecretVersion) => (
    <ListCell
      styles={{ root: { paddingLeft: 12 } }}
      headerText="Created On"
      text={formatDateWithTime(new Date(cellData?.createTime || group.createTime || ''))}
    />
  );

  return (
    <CellWrapper
      label="Created On"
      expanded={expanded}
      group={group}
      groupCell={renderCell()}
      onRenderCell={(secretVersion) => (secretVersion.name ? renderCell(secretVersion) : null)}
    />
  );
};
const onRenderState = (group: ExtendedSecret, props: SecretServiceColumnProps) => {
  const expanded = props.expandedGroupKeys.includes(group.name);

  return (
    <CellWrapper
      expanded={expanded}
      group={group}
      groupCell={
        <ListCell
          styles={{ root: { paddingLeft: 12 } }}
          onRenderText={() => getSecretState(props.theme, group.state as Secret_State)}
        />
      }
    />
  );
};
const onRenderActionsCell = (group: ExtendedSecret, props: SecretServiceColumnProps) => {
  const {
    expandedGroupKeys,
    loadingGroupKeys,
    theme,
    handleDeleteSecret,
    handleUnDeleteSecret,
    toggleExpandSecretVersions,
  } = props;
  const expanded = expandedGroupKeys.includes(group.name);
  const isLoading = loadingGroupKeys.includes(group.name);
  const isDeleted = group.state === Secret_State.DELETED;

  return (
    <CellWrapper
      alignRight
      rootStyles={{ marginRight: 16 }}
      expanded={expanded && !isLoading}
      group={group}
      groupCell={
        <ActionCellContents
          primaryButton={
            <Button
              split
              styles={[buttonStylesSplit, buttonStylesSplitGhost, { root: { width: 80 } }]}
              onClick={() => props.setViewSecret(group)}
              primaryActionButtonProps={
                {
                  onClick: () => {},
                  'data-test': `${group.name}--view-button`,
                  text: 'View',
                } as IButtonProps
              }
              splitButtonMenuProps={
                {
                  'data-test': `${group.name}--more-button`,
                } as IButtonProps
              }
              text="View"
              menuItems={
                isDeleted
                  ? [
                      {
                        key: 'undelete-secret',
                        text: 'Undelete Secret',
                        'data-test': `${group.name}--undelete-button`,
                        onClick: () => {
                          handleUnDeleteSecret(group.name);
                        },
                        iconProps: { iconName: 'RevToggleKey' },
                      },
                    ]
                  : [
                      {
                        key: 'create-secret-version',
                        text: 'Create Secret Version',
                        'data-test': `${group.name}--create-secret-version-button`,
                        onClick: () => {
                          props.setSecretForNewVersion(group.name);
                          props.showCreatePanel();
                        },
                        iconProps: { iconName: 'CalculatorAddition' },
                      },
                      {
                        key: 'delete-secret',
                        text: 'Delete Secret',
                        'data-test': `${group.name}--delete-button`,
                        onClick: () => {
                          handleDeleteSecret(group.name);
                        },
                        style: { color: theme.semanticColors?.buttonDanger },
                        iconProps: {
                          iconName: 'Delete',
                          style: { color: 'var(--h2o-red400)' },
                        },
                      },
                    ]
              }
            />
          }
          expandButtonProps={
            {
              onClick: () => toggleExpandSecretVersions(group.name),
              'data-test': `${group.name}--expand-button`,
              disabled: isDeleted,
              style: { opacity: isDeleted ? 0 : 1 },
            } as IButtonProps
          }
          expanded={expanded && !isLoading}
        />
      }
      onRenderCell={(secretVersion) => (
        <ListCell
          styles={{ root: { padding: '0 34px 0 0' } }}
          onRenderText={() =>
            secretVersion.value ? (
              <TextWithCopy text={decodeBytes(secretVersion.value)} />
            ) : (
              <Button
                text="Reveal secret"
                styles={[buttonStylesGhost]}
                onClick={() => props.revealSecret(secretVersion.name || '')}
              />
            )
          }
        />
      )}
    />
  );
};

export const secretServiceColumns = (props: SecretServiceColumnProps): IColumn[] => [
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 200,
    maxWidth: 250,
    onRender: (group: ExtendedSecret) => onRenderTitleCell(group, props),
  },
  {
    key: 'createTime',
    name: 'Created on',
    fieldName: 'createTime',
    minWidth: 140,
    maxWidth: 200,
    onRender: (group: ExtendedSecret) => onRenderCreatedOn(group, props),
  },
  {
    key: 'state',
    name: 'State',
    fieldName: 'state',
    minWidth: 140,
    onRender: (group: ExtendedSecret) => onRenderState(group, props),
  },
  {
    key: 'actions',
    name: '',
    fieldName: 'actions',
    minWidth: 230,
    maxWidth: 300,
    onRender: (group: ExtendedSecret) => onRenderActionsCell(group, props),
  },
];

export const onRenderRow = (rowProps?: IDetailsRowProps, isLoading?: boolean, theme?: IH2OTheme) => {
  if (!rowProps) return null;

  const detailsRowStyles = {
    root: {
      border: `1px solid ${theme?.palette?.gray300}`,
      pointerEvents: isLoading ? 'none' : 'unset',
      userSelect: isLoading ? 'none' : 'unset',
    },
    cell: {
      alignSelf: 'start',
      paddingTop: 16,
      paddingBottom: 16,
      height: '100%',
    },
  };

  return (
    <div style={{ position: 'relative', marginBottom: 8 }}>
      <DetailsRow disabled={isLoading} {...rowProps} styles={detailsRowStyles} />
      <Loader type={LoaderType.progressIndicator} styles={loaderStylesProgressIndicatorButton} hidden={!isLoading} />
    </div>
  );
};
