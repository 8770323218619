import { IDetailsRowStyles, IStackStyles } from '@fluentui/react';
import { IH2OTheme } from '@h2oai/ui-kit';

export const detailsListRowStyles = (theme: IH2OTheme): Partial<IDetailsRowStyles> => ({
  root: {
    border: `1px solid ${theme?.palette?.gray300}`,
    pointerEvents: 'unset',
    userSelect: 'unset',
  },
  cell: {
    alignSelf: 'start',
    paddingTop: 16,
    paddingBottom: 16,
    height: '100%',
  },
});

export const detailsStickyWrapperStyles = (expanded: boolean): Partial<IStackStyles> => ({
  root: {
    ...(expanded
      ? {
          position: 'sticky',
          top: 0,
          zIndex: 1,
        }
      : {}),
  },
});
