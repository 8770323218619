import { ArtifactComposition } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/artifact_composition_pb';
import { ListArtifactCompositionsRequest } from '@buf/h2oai_mlops-deployment.bufbuild_es/ai/h2o/mlops/deployer/v1/composition_service_pb';
import { CompositionService } from '@buf/h2oai_mlops-deployment.connectrpc_es/ai/h2o/mlops/deployer/v1/composition_service_connect';
import { Artifact } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/artifact_pb';
import { ListEntityArtifactsRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/artifact_service_pb';
import { Operator } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/query_pb';
import { RegisteredModel } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_pb';
import { ListRegisteredModelsRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_service_pb';
import { ListModelVersionsForModelRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/registered_model_version_service_pb';
import { ArtifactService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/artifact_service_connect';
import { RegisteredModelService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_service_connect';
import { RegisteredModelVersionService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/registered_model_version_service_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { IStyle, MessageBarType, mergeStyles } from '@fluentui/react';
import { Dropdown, IH2OTheme, Loader, Search, WidgetItem, useClassNames, useTheme, useToast } from '@h2oai/ui-kit';
import React from 'react';

import { useCloudPlatformDiscovery } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
// TODO: Move to global context.
import { WidgetIconButton } from '../Orchestrator/Runnables';
import { ENDPOINTS, ROUTES } from './constants';
import { DeploymentTypeKey, DropdownOption } from './DeploymentTabDetail';
import { DeploymentOptions, RegisteredModelItem } from './Models';
import { useProjects } from './ProjectProvider';

interface IModelSelectorStyles {
  placeholderContainer: IStyle;
  placeholderContainerSmall: IStyle;
  placeholderContent: IStyle;
  placeholderContentSmall: IStyle;
  widgetLayout: IStyle;
  loader: IStyle;
}

const modelSelectorStyles = (theme: IH2OTheme): Partial<IModelSelectorStyles> => ({
    placeholderContainer: {
      margin: '16px 0',
      height: 100, // Widget height.
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 8,
      outlineWidth: 1,
      outlineStyle: 'dashed',
      outlineColor: theme.semanticColors?.inputBorder,
    },
    placeholderContainerSmall: {
      width: '50%',
    },
    placeholderContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minWidth: 452, // Callout width.
    },
    placeholderContentSmall: {
      minWidth: 280, // Callout width.
    },
    widgetLayout: {
      display: 'flex',
      gap: 16,
    },
    loader: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
      height: 100, // Widget height.
    },
  }),
  widgetItemStyles = (theme: IH2OTheme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 8,
      margin: '16px 0',
      height: 100,
      outlineWidth: 1,
      outlineStyle: 'solid',
      outlineColor: theme.semanticColors?.inputBorder,
    },
  }),
  getWidgetActions = (onOpenSelection: () => void, onRemoveSelection: () => void, disabled?: boolean) => (
    <div>
      <WidgetIconButton iconName="OpenInNewTab" onClick={onOpenSelection} title="Open in new tab" disabled={disabled} />
      <WidgetIconButton
        title="Delete"
        iconName="Delete"
        iconColor="white"
        backgroundColor="red"
        hasBorder={false}
        onClick={onRemoveSelection}
        disabled={disabled}
      />
    </div>
  ),
  defaultWidgetData = {
    title: `Selected item does not exist.`,
    description: '',
    iconName: '',
  };

const ModelSelector = ({
  selectedModels,
  onSelectedModelsChange,
  defaultArtifactTypeKey,
  defaultRuntimeKey,
  deploymentType,
  onChangeOptions,
  defaultSelectedModelIds,
  onContentLoadFinish,
  onContentLoadStart,
  readOnly,
}: {
  selectedModels: (RegisteredModel | undefined)[];
  onSelectedModelsChange: (models: (RegisteredModel | undefined)[]) => void;
  defaultArtifactTypeKey?: string;
  defaultRuntimeKey?: string;
  deploymentType: DeploymentTypeKey;
  onChangeOptions: (options: Partial<DeploymentOptions>) => void;
  defaultSelectedModelIds?: string[];
  onContentLoadFinish?: () => void;
  onContentLoadStart?: () => void;
  readOnly?: boolean;
}) => {
  const theme = useTheme(),
    calloutARef = React.useRef<HTMLDivElement | null>(null),
    calloutBRef = React.useRef<HTMLDivElement | null>(null),
    classNames = useClassNames<IModelSelectorStyles, ClassNamesFromIStyles<IModelSelectorStyles>>(
      'modelSelectorStyles',
      modelSelectorStyles(theme)
    ),
    shouldShowTwoWidgets = deploymentType === 'ab_test' || deploymentType === 'champion_challenger',
    [artifactTypeOptions, setArtifactTypeOptions] = React.useState<DropdownOption[]>([]),
    [artifactRuntimeOptions, setArtifactRuntimeOptions] = React.useState<DropdownOption[]>([]),
    [selectedArtifactTypeKey, setSelectedArtifactTypeKey] = React.useState<string>(defaultArtifactTypeKey || ''),
    [selectedRuntimeKey, setSelectedRuntimeKey] = React.useState(defaultRuntimeKey || ''),
    [artifactCompositions, setArtifactCompositions] = React.useState<ArtifactComposition[]>(),
    { ACTIVE_PROJECT_ID } = useProjects(),
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    storageTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.storage}/`,
    }),
    deploymentTransport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}${ENDPOINTS.deployment}/`,
    }),
    artifactClient = createPromiseClient(ArtifactService, storageTransport),
    artifactCompositionClient = createPromiseClient(CompositionService, deploymentTransport),
    registeredModelVersionClient = createPromiseClient(RegisteredModelVersionService, storageTransport),
    registeredModelClient = createPromiseClient(RegisteredModelService, storageTransport),
    { addToast } = useToast(),
    // TODO: Support loading more models.
    [, setIsLoadingMoreModels] = React.useState(false),
    [, setModelNextPageToken] = React.useState<string>(),
    [isLoadingModelSearch, setIsLoadingModelSearch] = React.useState(false),
    [registeredModelItems, setRegisteredModelItems] = React.useState<RegisteredModelItem[]>(),
    [isLoading, setIsLoading] = React.useState(true),
    loadStateRef = React.useRef({
      fetchRegisteredModels: true,
      getRegisteredModelVersion: false,
      fetchArtifactCompositions: true,
      fetchEntityArtifacts: false,
    }),
    evaluateLoading = React.useCallback(() => {
      // TODO: Remove loading refs regarding ModelSelector.
      if (
        !loadStateRef.current.fetchRegisteredModels &&
        !loadStateRef.current.getRegisteredModelVersion &&
        !loadStateRef.current.fetchArtifactCompositions &&
        !loadStateRef.current.fetchEntityArtifacts
      ) {
        setIsLoading(false);
      }
    }, []),
    getEntityArtifacts = React.useCallback(
      async (entityId: string) => {
        loadStateRef.current.fetchEntityArtifacts = true;
        setIsLoading(true);
        try {
          const listEntityArtifactsRequest = new ListEntityArtifactsRequest({
            // Experiment id.
            entityId,
          });
          const response = await artifactClient.listEntityArtifacts(listEntityArtifactsRequest);
          const artifacts: Artifact[] | undefined = response?.artifact;
          if (response && !artifacts) console.error('No artifacts found in the response.');
          return artifacts;
        } catch (err) {
          const message = `Failed to fetch artifacts: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.fetchEntityArtifacts = false;
          evaluateLoading();
        }
      },
      [addToast, evaluateLoading]
    ),
    getModelVersionsByModel = React.useCallback(
      async (modelId: string) => {
        loadStateRef.current.getRegisteredModelVersion = true;
        setIsLoading(true);
        try {
          const getModelVersionsByModelRequest = new ListModelVersionsForModelRequest({
            registeredModelId: modelId,
          });
          const response = await registeredModelVersionClient.listModelVersionsForModel(getModelVersionsByModelRequest);
          const modelVersions = response?.modelVersions;
          if (modelVersions) {
            return modelVersions;
          } else {
            console.error('No model versions found in the response.');
            return undefined;
          }
        } catch (err) {
          const message = `Failed to fetch model versions: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return undefined;
        } finally {
          loadStateRef.current.getRegisteredModelVersion = false;
          evaluateLoading();
        }
      },
      [addToast, evaluateLoading]
    ),
    onSelectModelUpdateOptions = React.useCallback(async () => {
      if (!selectedModels.length || !artifactCompositions) return;
      let firstModelOptions: Partial<DeploymentOptions> = {};
      let secondModelOptions: Partial<DeploymentOptions> = {};

      if (selectedModels?.[0]) {
        const firstModelVersions = await getModelVersionsByModel(selectedModels[0].id),
          firstModelExperimentId = firstModelVersions?.[0]?.experimentId,
          firstModelEntityArtifacts = await getEntityArtifacts(firstModelExperimentId || ''),
          firstModelFilteredArtifactCompositions = artifactCompositions?.filter(
            (a) => a?.deployableArtifactType?.artifactType === firstModelEntityArtifacts?.[0]?.type
          );
        if (!firstModelExperimentId || !firstModelFilteredArtifactCompositions) {
          const message = 'No experiment found for the model.';
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return;
        }

        const artifactCompositionFirstModel = firstModelFilteredArtifactCompositions[0];
        firstModelOptions = {
          firstModelExperimentId,
          firstModelDeployableArtifactTypeName: artifactCompositionFirstModel.deployableArtifactType?.name || '',
          firstModelArtifactProcessorName: artifactCompositionFirstModel.artifactProcessor?.name || '',
          firstModelRuntimeName: artifactCompositionFirstModel.runtime?.name || '',
          artifactCompositions: artifactCompositions || [],
          selectedArtifactComposition: artifactCompositionFirstModel,
        };

        // Updates dropdowns on model select.
        setSelectedArtifactTypeKey(artifactCompositionFirstModel.deployableArtifactType?.name || '');
        setSelectedRuntimeKey(artifactCompositionFirstModel.runtime?.name || '');
      }

      if (selectedModels?.[1]) {
        const secondModelVersions = await getModelVersionsByModel(selectedModels[1].id),
          secondModelExperimentId = secondModelVersions?.[0]?.experimentId,
          secondModelEntityArtifacts = await getEntityArtifacts(secondModelExperimentId || ''),
          secondModelFilteredArtifactCompositions = artifactCompositions?.filter(
            (a) => a?.deployableArtifactType?.artifactType === secondModelEntityArtifacts?.[0]?.type
          );
        if (!secondModelExperimentId || !secondModelFilteredArtifactCompositions) {
          const message = 'No experiment found for the second model.';
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          return;
        }

        const artifactCompositionSecondModel = secondModelFilteredArtifactCompositions[0];
        secondModelOptions = {
          secondModelExperimentId,
          secondModelDeployableArtifactTypeName: artifactCompositionSecondModel.deployableArtifactType?.name || '',
          secondModelArtifactProcessorName: artifactCompositionSecondModel.artifactProcessor?.name || '',
          secondModelRuntimeName: artifactCompositionSecondModel.runtime?.name || '',
        };
      }

      onChangeOptions({ ...firstModelOptions, ...secondModelOptions });
    }, [
      addToast,
      artifactCompositions,
      getEntityArtifacts,
      getModelVersionsByModel,
      selectedArtifactTypeKey,
      selectedModels,
      selectedRuntimeKey,
      evaluateLoading,
    ]),
    fetchRegisteredModels = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        if (!ACTIVE_PROJECT_ID) return;
        loadStateRef.current.fetchRegisteredModels = true;
        if (pageToken) setIsLoadingMoreModels(true);
        else if (filter || filter === `""`) setIsLoadingModelSearch(true);
        else setIsLoading(true);
        try {
          const listRegisteredModelsBody = new ListRegisteredModelsRequest(
            filter
              ? {
                  projectId: ACTIVE_PROJECT_ID,
                  paging: {
                    pageSize: 20,
                    pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
                  },
                  filter: {
                    query: {
                      clause: [
                        {
                          propertyConstraint: [
                            {
                              property: {
                                propertyType: {
                                  // TODO: Make filter case insensitive.
                                  value: 'name',
                                  case: 'field',
                                },
                              },
                              operator: Operator.CONTAINS,
                              value: {
                                value: {
                                  case: 'stringValue',
                                  value: filter,
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                }
              : {
                  projectId: ACTIVE_PROJECT_ID,
                  paging: {
                    pageSize: 20,
                    pageToken: pageToken ? new TextEncoder().encode(pageToken) : undefined,
                  },
                }
          );
          const response = await registeredModelClient.listRegisteredModels(listRegisteredModelsBody);
          const registeredModelItems: RegisteredModel[] | undefined = response?.registeredModels;
          if (response && !registeredModelItems) console.error('No registered models found in the response.');
          setModelNextPageToken(
            response?.paging?.nextPageToken ? new TextDecoder().decode(response.paging.nextPageToken) : undefined
          );
          const newItems: RegisteredModelItem[] | undefined = registeredModelItems?.map(
            (item) =>
              ({
                ...item,
                createdTimeLocal:
                  item.createdTime?.seconds !== undefined
                    ? new Date(Number(item.createdTime.seconds) * 1000).toLocaleString()
                    : '',
                // TODO: Display user name instead of the id.
                createdByName: item.createdBy,
              } as RegisteredModelItem)
          );
          setRegisteredModelItems((items) => (pageToken ? [...(items || []), ...(newItems || [])] : newItems));
        } catch (err) {
          const message = `Failed to fetch registered models: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setRegisteredModelItems(undefined);
        } finally {
          loadStateRef.current.fetchRegisteredModels = false;
          evaluateLoading();
          setIsLoadingMoreModels(false);
          setIsLoadingModelSearch(false);
        }
      },
      [addToast, ACTIVE_PROJECT_ID, evaluateLoading]
    ),
    getArtifactCompositions = React.useCallback(async () => {
      loadStateRef.current.fetchArtifactCompositions = true;
      setIsLoading(true);
      try {
        const listArtifactCompositionsRequest = new ListArtifactCompositionsRequest({});
        const response = await artifactCompositionClient.listArtifactCompositions(listArtifactCompositionsRequest);
        const artifactCompositions: ArtifactComposition[] | undefined = response?.artifactComposition;
        setArtifactCompositions(artifactCompositions);
        if (response && !artifactCompositions) console.error('No artifact compositions found in the response.');
      } catch (err) {
        const message = `Failed to fetch artifact compositions: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setArtifactCompositions(undefined);
      } finally {
        loadStateRef.current.fetchArtifactCompositions = false;
        evaluateLoading();
      }
    }, [addToast, evaluateLoading]),
    searchProps = React.useMemo(
      () => ({
        emptyMessage: 'No results found',
        hasSearchResult: true,
        onRenderSearchResultItemActions: () => <></>,
        onSearchTextChange: (text?: string) => void fetchRegisteredModels(undefined, text),
        placeholder: `Search by model name`,
        searchResultItems: registeredModelItems,
        minCalloutWidth: deploymentType === 'single_model' ? 452 : 280,
        searchResultItemFields: {
          titleField: 'displayName',
          descriptionField: 'description',
          iconNameField: 'iconName',
        },
        onDismissCallout: () => void fetchRegisteredModels(),
        readOnly,
        styles: {
          root: {
            width: '100%',
          },
        },
        // TODO: Add loader.
        loadingMessage: isLoadingModelSearch ? 'Loading models...' : '',
      }),
      [fetchRegisteredModels, isLoadingModelSearch, registeredModelItems, deploymentType, readOnly]
    );

  React.useEffect(() => {
    const artifactTypeOptions: DropdownOption[] = (artifactCompositions || []).map((composition) => ({
      key: composition.deployableArtifactType?.name || '',
      text: composition.deployableArtifactType?.displayName || '',
    }));

    const artifactRuntimeOptions: DropdownOption[] = (artifactCompositions || []).map((composition) => ({
      key: composition.runtime?.name || '',
      text: composition.runtime?.displayName || '',
    }));
    setArtifactTypeOptions(artifactTypeOptions);
    setArtifactRuntimeOptions(artifactRuntimeOptions);
  }, [artifactCompositions]);

  React.useEffect(() => {
    // TODO: Update directly from model selection callout.
    if (artifactCompositions) onSelectModelUpdateOptions();
  }, [selectedModels, artifactCompositions]);

  React.useEffect(() => void getArtifactCompositions(), [getArtifactCompositions]);

  React.useEffect(() => void fetchRegisteredModels(), [fetchRegisteredModels]);

  React.useEffect(() => onContentLoadStart?.(), []);

  React.useEffect(() => {
    // TODO: For some reason it is called sooner than expected.
    if (!isLoading) onContentLoadFinish?.();
  }, [isLoading]);

  React.useEffect(() => {
    if (!registeredModelItems || !defaultSelectedModelIds) return;
    const models = registeredModelItems?.filter((item) => defaultSelectedModelIds?.includes(item.id)) || [];
    if (defaultSelectedModelIds?.[0] === defaultSelectedModelIds?.[1]) onSelectedModelsChange([models[0], models[0]]);
    else onSelectedModelsChange(models);
  }, [defaultSelectedModelIds, registeredModelItems]);

  React.useEffect(() => {
    if (deploymentType !== 'single_model') {
      // Reset selected models when deployment type changes from single model to other types.
      setSelectedArtifactTypeKey('');
      setSelectedRuntimeKey('');
    }
  }, [deploymentType]);

  // TODO: Make the model widget to start loading with the deployment detail page.
  return (
    <>
      <div className={classNames.widgetLayout}>
        {isLoading ? (
          <div className={classNames.loader}>
            <Loader label="Loading model widget..." />
          </div>
        ) : (
          <>
            {selectedModels?.[0] ? (
              <WidgetItem
                styles={widgetItemStyles(theme)}
                style={{ width: deploymentType === 'single_model' ? '100%' : '50%' }}
                actions={getWidgetActions(
                  () => {
                    window.open(
                      `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.MODELS}/${selectedModels[0]?.id}`,
                      '_blank'
                    );
                  },
                  () => {
                    onSelectedModelsChange([undefined, selectedModels?.[1]]);
                    setSelectedArtifactTypeKey('');
                    setSelectedRuntimeKey('');
                  },
                  readOnly
                )}
                data={selectedModels[0] || defaultWidgetData}
                idField={'id'}
                titleField={'displayName'}
              />
            ) : (
              <div
                className={mergeStyles(
                  classNames.placeholderContainer,
                  deploymentType === 'single_model' ? '' : classNames.placeholderContainerSmall
                )}
              >
                <div
                  className={mergeStyles(
                    classNames.placeholderContent,
                    deploymentType === 'single_model' ? '' : classNames.placeholderContentSmall
                  )}
                >
                  <p>
                    {deploymentType === 'single_model'
                      ? 'Select a model.'
                      : deploymentType === 'ab_test'
                      ? 'Select Model A.'
                      : 'Select a CHAMPION model.'}
                  </p>
                  <Search
                    {...searchProps}
                    calloutProps={{ dismissOnTargetClick: true, ref: calloutARef }}
                    onClickItem={(item) => {
                      onSelectedModelsChange([item as RegisteredModel, selectedModels?.[1]]);
                      // HACK: Dismiss callout.
                      calloutARef.current?.parentElement?.click();
                    }}
                  />
                </div>
              </div>
            )}
            {shouldShowTwoWidgets ? (
              selectedModels?.[1] ? (
                <WidgetItem
                  styles={widgetItemStyles(theme)}
                  style={{ width: '50%' }}
                  actions={getWidgetActions(
                    () => {
                      window.open(
                        `/mlops/projects/${ACTIVE_PROJECT_ID}${ROUTES.MODELS}/${selectedModels[1]?.id}`,
                        '_blank'
                      );
                    },
                    () => {
                      onSelectedModelsChange([selectedModels[0], undefined]);
                    },
                    readOnly
                  )}
                  data={selectedModels[1] || defaultWidgetData}
                  idField={'id'}
                  titleField={'displayName'}
                />
              ) : (
                <div className={mergeStyles(classNames.placeholderContainer, classNames.placeholderContainerSmall)}>
                  <div className={mergeStyles(classNames.placeholderContent, classNames.placeholderContentSmall)}>
                    <p>{deploymentType === 'ab_test' ? 'Select Model B.' : 'Select a CHALLENGER model.'}</p>
                    <Search
                      {...searchProps}
                      calloutProps={{ dismissOnTargetClick: true, ref: calloutBRef }}
                      onClickItem={(item) => {
                        onSelectedModelsChange([selectedModels[0], item as RegisteredModel]);
                        // HACK: Dismiss callout.
                        calloutBRef.current?.parentElement?.click();
                      }}
                    />
                  </div>
                </div>
              )
            ) : null}
          </>
        )}
      </div>
      {!shouldShowTwoWidgets && (
        <>
          <h4>Deployment parameters</h4>
          <Dropdown
            label="Artifact type"
            required
            options={artifactTypeOptions}
            selectedKey={selectedArtifactTypeKey}
            onChange={(_ev, option) => {
              setSelectedArtifactTypeKey(option?.key as string);
            }}
            disabled={!artifactTypeOptions.length || !selectedModels.length || readOnly}
          />
          <Dropdown
            label="Runtime"
            required
            options={artifactRuntimeOptions}
            selectedKey={selectedRuntimeKey}
            onChange={(_ev, option) => {
              setSelectedRuntimeKey(option?.key as string);
            }}
            disabled={!artifactRuntimeOptions.length || !selectedModels.length || readOnly}
          />
        </>
      )}
    </>
  );
};

export default ModelSelector;
