import { Stack } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { Button, ConfirmDialog, SortInfo, buttonStylesPrimary, detailsHeaderStylesBasic } from '@h2oai/ui-kit';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useEntity } from '../../../aiem/entity/hooks';
import { Entity, HasName } from '../../../aiem/entity/types';
import { getIdFromName } from '../../../aiem/utils';
import List, { ReorderItem } from '../../../components/ListPages/List';
import ListPage from '../../../components/ListPages/ListPage';
import { stackStylesInnerPivotPage } from '../../../themes/themes';
import { AddEditModelForm } from './AddEditModelForm';
import { AIEMSettingsEditPanel } from './AIEMSettingsEditPanel';
import { AIEMSettingsViewPanel } from './AIEMSettingsViewPanel';
import { EntityModelItemActionButton } from './EntityModelItemActionButton';

export interface EntityDataContainerProps<EntityModel extends HasName> {
  entity: Entity<EntityModel>;
}

export default function EntityDataContainer<EntityModel extends HasName>({
  entity,
}: EntityDataContainerProps<EntityModel>) {
  const { columns, canBeReordered, displayName, sortFields, actions } = entity;
  const { List: hasList } = actions || undefined;
  const entityDataConnector = useEntity();
  const [data, setData] = useState<EntityModel[] | undefined>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [dataRetrievalError, setDataRetrievalError] = useState<string>();
  const [isViewPanelOpen, { setTrue: showViewPanel, setFalse: hideViewPanel }] = useBoolean(false);
  const [isEditPanelOpen, { setTrue: showEditPanel, setFalse: hideEditPanel }] = useBoolean(false);
  const operationCreate = useRef<boolean>(false);
  const [isDeleteDialogOpen, { setTrue: showDeleteDialog, setFalse: hideDeleteDialog }] = useBoolean(false);
  const showNoDataMessage = useRef<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<EntityModel>();
  const refSort = useRef<SortInfo<EntityModel & { [key: string]: any }>>();

  const updatedColumns = useMemo(() => {
    return [...columns].map((c) => {
      const column = { ...c };
      // action button
      if (column.key === 'actions') {
        const deleteFn = (e: EntityModel) => {
          setSelectedItem(e);
          showDeleteDialog();
        };
        const openViewPanel = (e: EntityModel) => {
          setSelectedItem(e);
          showViewPanel();
        };
        const openEditPanel = (e: EntityModel) => {
          setSelectedItem(e);
          showEditPanel();
          operationCreate.current = false;
        };
        column.onRender = (model: EntityModel) => {
          return (
            <Stack horizontal tokens={{ childrenGap: 16 }} horizontalAlign="end" verticalAlign="center">
              <Button text="View" onClick={() => openViewPanel(model)} />
              <EntityModelItemActionButton
                model={model}
                onSetLatest={onSetLatest}
                onEdit={openEditPanel}
                onDelete={deleteFn}
              />
            </Stack>
          );
        };
      }
      return column;
    });
  }, [columns]);

  const onDismissDeleteDialog = () => {
    hideDeleteDialog();
    setSelectedItem(undefined);
  };

  const onCreateClick = () => {
    setSelectedItem(entity.emptyModel);
    operationCreate.current = true;
    showEditPanel();
  };

  const onReorderItem = async ({ item, newOrder }: ReorderItem<EntityModel>) => {
    setLoading(true);
    try {
      const name = item.name;
      const requestParams = {
        name,
        newOrder,
      };
      const entities = await entityDataConnector.reorder(entity, requestParams, {
        subject: getIdFromName(name),
        action: `reordered to position number ${newOrder} `,
      });
      setData(entities);
    } catch (error: any) {
      setDataRetrievalError(error.message);
    }
    setLoading(false);
  };
  const getDeleteDialogContent = () => {
    return (
      <Stack>
        <p>
          Are you sure you want to delete {entity.displayName}{' '}
          <span style={{ fontWeight: 'bold' }}>{selectedItem ? getIdFromName(selectedItem.name) : ''}</span> ?
        </p>
      </Stack>
    );
  };
  const onDelete = async () => {
    setLoading(true);
    const name = selectedItem?.name;
    const cleanName = getIdFromName(name);
    if (!selectedItem || !name) {
      setDataRetrievalError('Missing entity name.');
      hideDeleteDialog();
      setSelectedItem(undefined);
      setLoading(false);
      return;
    }
    try {
      const messageText = {
        subject: cleanName,
        action: 'deleted ',
      };
      await entityDataConnector.erase(entity, { name: name }, messageText);
      loadData();
    } catch (error: any) {
      setDataRetrievalError(error.message);
    }
    hideDeleteDialog();
    setSelectedItem(undefined);
    setLoading(false);
  };

  const loadData = async () => {
    setLoading(true);
    try {
      const requestParams = {
        parent: 'workspaces/global',
        pageSize: 1000,
      };
      if (sortFields) {
        requestParams['orderBy'] = refSort.current?.isSorted
          ? `${String(refSort.current?.fieldName)} ${refSort.current?.isSortedDescending ? 'desc' : 'asc'}`
          : '';
      }
      const entities = await entityDataConnector.list(entity, requestParams);
      if (!entities || (entities as EntityModel[]).length === 0) {
        showNoDataMessage.current = true;
        setData([]);
      } else {
        showNoDataMessage.current = false;
        setData(entities);
      }
    } catch (error: any) {
      setDataRetrievalError(error.message);
    }
    setLoading(false);
  };

  const onSave = () => {
    hideEditPanel();
    loadData();
  };

  const onSetLatest = async (model: EntityModel) => {
    setLoading(true);
    try {
      await entityDataConnector.setLatest(entity, model);
      loadData();
    } catch (error: any) {
      setDataRetrievalError(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Stack tokens={{ childrenGap: 5 }} styles={{ root: { paddingTop: 5 } }}>
      <Stack horizontal horizontalAlign="end" style={{ position: 'absolute', top: '40px', right: '30px', height: '0' }}>
        <Button
          styles={[buttonStylesPrimary]}
          text={entity.createButtonLabel || `Add ${entity.displayName}`}
          onClick={onCreateClick}
        />
      </Stack>
      {hasList && (
        <ListPage
          showFilterButton={false}
          showNoDataPage={showNoDataMessage.current}
          showSearchInput={false}
          error={dataRetrievalError}
          loading={loading}
          copy={{
            title: 'AI Engine Manager Admin Settings',
            subtitle: '',
            loadingMessage: `Loading ${entity.displayName}s`,
            noDataMessage: 'No data.',
          }}
          showPageTitle={false}
          stackStyles={stackStylesInnerPivotPage}
        >
          <List<EntityModel>
            dataTest="entity-model-list"
            items={data || []}
            columns={updatedColumns}
            canBeReordered={canBeReordered}
            onReorderItem={onReorderItem}
            orderField={'order'}
            onShouldVirtualize={process.env.NODE_ENV === 'test' ? () => false : undefined}
            getKey={(col) => col.key}
            isHeaderVisible={true}
            headerStyles={detailsHeaderStylesBasic}
            showSelectAllButton={false}
            sortFields={sortFields}
          />
          {isViewPanelOpen && (
            <AIEMSettingsViewPanel
              data-test="aiem-panel"
              panelTitle={displayName}
              item={selectedItem}
              onDismiss={hideViewPanel}
              type={entity.type}
            />
          )}
          <ConfirmDialog
            confirmationButtonText={'Delete'}
            hidden={!isDeleteDialogOpen}
            content={getDeleteDialogContent()}
            title={`Delete ${entity.displayName}`}
            onConfirm={onDelete}
            onDismiss={onDismissDeleteDialog}
          />
        </ListPage>
      )}
      {isEditPanelOpen && (
        <AIEMSettingsEditPanel
          panelTitle={operationCreate.current ? `Add ${displayName}` : `Edit ${displayName}`}
          onDismiss={() => {
            hideEditPanel();
            operationCreate.current = false;
          }}
          isCreate={operationCreate.current}
          onSave={onSave}
          entity={entity}
          model={selectedItem}
        />
      )}
      {!hasList && (
        <AddEditModelForm
          entity={entity}
          onSave={() => {
            throw new Error('Function not implemented.');
          }}
          onDismiss={() => {}}
          isCreate={false}
        />
      )}
    </Stack>
  );
}
