// @generated by protoc-gen-grpc-gateway-es v0.2.3 with parameter "target=ts,import_extension=none"
// @generated from file ai/h2o/engine/v1/h2o_engine_profile_service.proto (package ai.h2o.engine.v1, syntax proto3)
/* eslint-disable */

import type { H2OEngineProfile } from "./h2o_engine_profile_pb";
import { RPC } from "../../../../runtime";

/**
 * Request message Create method.
 *
 * @generated from message ai.h2o.engine.v1.CreateH2OEngineProfileRequest
 */
export type CreateH2OEngineProfileRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * The H2OEngineProfile resource to create.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngineProfile h2o_engine_profile = 2;
 */
h2oEngineProfile: H2OEngineProfile;
/**
 * Specify the H2OEngineProfile ID, which will become a part of the H2OEngineProfile
 * resource name.
 *
 * It must:
 * - contain 1-63 characters
 * - contain only lowercase alphanumeric characters or hyphen ('-')
 * - start with an alphabetic character
 * - end with an alphanumeric character
 *
 * This matches a regular expression: ^[a-z]([a-z0-9-]{0,61}[a-z0-9])?$
 *
 * @generated from field: string h2o_engine_profile_id = 3;
 */
h2oEngineProfileId: string;
}
;
/**
 * Response message for Create method.
 *
 * @generated from message ai.h2o.engine.v1.CreateH2OEngineProfileResponse
 */
export type CreateH2OEngineProfileResponse = {
/**
 * Created H2OEngineProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngineProfile h2o_engine_profile = 1;
 */
h2oEngineProfile?: H2OEngineProfile;
}
;
/**
 * Request message for Get method.
 *
 * @generated from message ai.h2o.engine.v1.GetH2OEngineProfileRequest
 */
export type GetH2OEngineProfileRequest = {
/**
 * H2OEngineProfile resource name.
 * Format: workspaces/{workspace}/h2oEngineProfiles/{h2o_engine_profile}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message Get method.
 *
 * @generated from message ai.h2o.engine.v1.GetH2OEngineProfileResponse
 */
export type GetH2OEngineProfileResponse = {
/**
 * H2OEngineProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngineProfile h2o_engine_profile = 1;
 */
h2oEngineProfile?: H2OEngineProfile;
}
;
/**
 * Request message for List method.
 *
 * @generated from message ai.h2o.engine.v1.ListH2OEngineProfilesRequest
 */
export type ListH2OEngineProfilesRequest = {
/**
 * Workspace resource name. Format: workspaces/{workspace}
 *
 * @generated from field: string parent = 1;
 */
parent: string;
/**
 * Maximum number of H2OEngineProfiles to return in a response.
 * If unspecified (or set to 0), at most 50 H2OEngineProfiles will be returned.
 * The maximum value is 1000; values above 1000 will be coerced to 1000.
 *
 * @generated from field: int32 page_size = 2;
 */
pageSize?: number;
/**
 * Leave unset to receive the initial page.
 * To list any subsequent pages use the value of 'next_page_token'
 * returned from the ListH2OEngineProfilesResponse.
 *
 * @generated from field: string page_token = 3;
 */
pageToken?: string;
}
;
/**
 * Response message for List method.
 *
 * @generated from message ai.h2o.engine.v1.ListH2OEngineProfilesResponse
 */
export type ListH2OEngineProfilesResponse = {
/**
 * Collection of H2OEngineProfiles.
 *
 * @generated from field: repeated ai.h2o.engine.v1.H2OEngineProfile h2o_engine_profiles = 1;
 */
h2oEngineProfiles?: H2OEngineProfile[];
/**
 * Used to retrieve the next page of results.
 * When unset, no further items are available (this response was the last page).
 *
 * @generated from field: string next_page_token = 2;
 */
nextPageToken?: string;
}
;
/**
 * Request message for Update method.
 *
 * @generated from message ai.h2o.engine.v1.UpdateH2OEngineProfileRequest
 */
export type UpdateH2OEngineProfileRequest = {
/**
 * Required. H2OEngineProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngineProfile h2o_engine_profile = 1;
 */
h2oEngineProfile: H2OEngineProfile;
/**
 * Required. The list of paths referencing which fields to update.
 * Update mask must be non-empty.
 *
 * Allowed field paths are:
 * - display_name
 * - priority
 * - disabled
 * - assigned_oidc_roles
 * - max_running_engines
 * - node_count_constraint
 * - cpu_constraint
 * - gpu_constraint
 * - memory_bytes_constraint
 * - max_idle_duration_constraint
 * - max_running_duration_constraint
 * - yaml_pod_template_spec
 * - yaml_gpu_tolerations
 *
 * Paths are case sensitive (must match exactly).
 *
 * To update all allowed fields, specify exactly one path with value "*".
 *
 * @generated from field: google.protobuf.FieldMask update_mask = 2;
 */
updateMask: string;
}
;
/**
 * Response for Update method.
 *
 * @generated from message ai.h2o.engine.v1.UpdateH2OEngineProfileResponse
 */
export type UpdateH2OEngineProfileResponse = {
/**
 * H2OEngineProfile resource.
 *
 * @generated from field: ai.h2o.engine.v1.H2OEngineProfile h2o_engine_profile = 1;
 */
h2oEngineProfile?: H2OEngineProfile;
}
;
/**
 * Request message for Delete method.
 *
 * @generated from message ai.h2o.engine.v1.DeleteH2OEngineProfileRequest
 */
export type DeleteH2OEngineProfileRequest = {
/**
 * H2OEngine resource name.
 * Format: workspaces/{workspace}/h2oEngineProfiles/{h2o_engine_profile}
 *
 * @generated from field: string name = 1;
 */
name: string;
}
;
/**
 * Response message for Delete method.
 *
 * @generated from message ai.h2o.engine.v1.DeleteH2OEngineProfileResponse
 */
export type DeleteH2OEngineProfileResponse = {
}
;
/**
 * Creates a new H2OEngineProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineProfileService.CreateH2OEngineProfile
 */
export const H2OEngineProfileService_CreateH2OEngineProfile = new RPC<CreateH2OEngineProfileRequest,CreateH2OEngineProfileResponse>("POST", "/v1/{parent=workspaces/*}/h2oEngineProfiles", "h2oEngineProfile");
/**
 * Get H2OEngineProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineProfileService.GetH2OEngineProfile
 */
export const H2OEngineProfileService_GetH2OEngineProfile = new RPC<GetH2OEngineProfileRequest,GetH2OEngineProfileResponse>("GET", "/v1/{name=workspaces/*/h2oEngineProfiles/*}");
/**
 * Returns a collection of H2OEngineProfiles.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineProfileService.ListH2OEngineProfiles
 */
export const H2OEngineProfileService_ListH2OEngineProfiles = new RPC<ListH2OEngineProfilesRequest,ListH2OEngineProfilesResponse>("GET", "/v1/{parent=workspaces/*}/h2oEngineProfiles");
/**
 * Updates a H2OEngineProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineProfileService.UpdateH2OEngineProfile
 */
export const H2OEngineProfileService_UpdateH2OEngineProfile = new RPC<UpdateH2OEngineProfileRequest,UpdateH2OEngineProfileResponse>("PATCH", "/v1/{h2oEngineProfile.name=workspaces/*/h2oEngineProfiles/*}", "h2oEngineProfile");
/**
 * Deletes a H2OEngineProfile.
 *
 * @generated from rpc ai.h2o.engine.v1.H2OEngineProfileService.DeleteH2OEngineProfile
 */
export const H2OEngineProfileService_DeleteH2OEngineProfile = new RPC<DeleteH2OEngineProfileRequest,DeleteH2OEngineProfileResponse>("DELETE", "/v1/{name=workspaces/*/h2oEngineProfiles/*}");
