import { daiEngineProfileEntity } from './DAIEngineProfile.entity';
import { daiProfileEntity } from './DAIProfile.entity';
import { defaultDAISetupEntity } from './DefaultDAISetup.entity';
import { defaultH2OSetupEntity } from './DefaultH2OSetup.entity';
import { h2oEngineProfileEntity } from './H2OEngineProfile.entity';
import { daiVersionEntity } from './InternalDAIVersion.entity';
import { h2oVersionEntity } from './InternalH2OVersion.entity';
import { Entity, EntityType } from './types';

export const EntitiesMap = new Map<EntityType, Entity<any>>([
  [EntityType.DAIProfile, daiProfileEntity],
  [EntityType.InternalDAIVersion, daiVersionEntity],
  [EntityType.InternalH2OVersion, h2oVersionEntity],
  [EntityType.DefaultDAISetup, defaultDAISetupEntity],
  [EntityType.DefaultH2OSetup, defaultH2OSetupEntity],
  [EntityType.DAIEngineProfile, daiEngineProfileEntity],
  [EntityType.H2OEngineProfile, h2oEngineProfileEntity],
]);
