import { IColumn } from '@fluentui/react';
import { ListCell } from '@h2oai/ui-kit';

import { onRenderBooleanCell } from '../../utils/render';
import { DAIEngineProfile, DAIEngineProfile_ConfigEditability } from '../gen/ai/h2o/engine/v1/dai_engine_profile_pb';
import {
  CreateDAIEngineProfileRequest,
  CreateDAIEngineProfileResponse,
  DAIEngineProfileService_CreateDAIEngineProfile,
  DAIEngineProfileService_DeleteDAIEngineProfile,
  DAIEngineProfileService_GetDAIEngineProfile,
  DAIEngineProfileService_ListDAIEngineProfiles,
  DAIEngineProfileService_UpdateDAIEngineProfile,
  DeleteDAIEngineProfileRequest,
  DeleteDAIEngineProfileResponse,
  GetDAIEngineProfileRequest,
  GetDAIEngineProfileResponse,
  ListDAIEngineProfilesRequest,
  ListDAIEngineProfilesResponse,
  UpdateDAIEngineProfileRequest,
  UpdateDAIEngineProfileResponse,
} from '../gen/ai/h2o/engine/v1/dai_engine_profile_service_pb';
import { BigIntString } from '../gen/runtime';
import { ConstraintType } from '../types';
import { Entity, EntityAction, EntityActionType, EntityField, EntityFieldType, EntityType } from './types';
import { getOptionsFromEnum, onRender, onRenderDisplayAndId } from './utils';

const entityType = EntityType.DAIEngineProfile;
const required = true;

export const actions = {
  [EntityActionType.Create]: {
    name: 'CreateDAIEngineProfile',
    rpc: DAIEngineProfileService_CreateDAIEngineProfile,
    type: EntityActionType.Create,
    responseKey: 'daiEngineProfile',
    requestNameKey: 'daiEngineProfileId',
    requestPayloadKey: 'daiEngineProfile',
    requestNameKeyPrefix: 'daiEngineProfile/',
    entityType,
  } as EntityAction<DAIEngineProfile, CreateDAIEngineProfileRequest, CreateDAIEngineProfileResponse>,
  [EntityActionType.Get]: {
    name: 'GetDAIEngineProfile',
    rpc: DAIEngineProfileService_GetDAIEngineProfile,
    type: EntityActionType.Get,
    requestNameKey: 'name' as unknown,
    responseKey: 'daiEngineProfile',
    entityType,
  } as EntityAction<DAIEngineProfile, GetDAIEngineProfileRequest, GetDAIEngineProfileResponse>,
  [EntityActionType.List]: {
    name: 'ListDAIEngineProfile',
    rpc: DAIEngineProfileService_ListDAIEngineProfiles,
    type: EntityActionType.List,
    responseKey: 'daiEngineProfiles',
    entityType,
  } as EntityAction<DAIEngineProfile, ListDAIEngineProfilesRequest, ListDAIEngineProfilesResponse>,
  [EntityActionType.Update]: {
    name: 'UpdateDAIEngineProfile',
    rpc: DAIEngineProfileService_UpdateDAIEngineProfile,
    type: EntityActionType.Update,
    responseKey: 'daiEngineProfile',
    requestNameKey: 'name' as unknown,
    requestPayloadKey: 'daiEngineProfile',
    entityType,
  } as EntityAction<DAIEngineProfile, UpdateDAIEngineProfileRequest, UpdateDAIEngineProfileResponse>,
  [EntityActionType.Delete]: {
    name: 'DeleteDAIEngineProfile',
    rpc: DAIEngineProfileService_DeleteDAIEngineProfile,
    type: EntityActionType.Delete,
    entityType,
  } as EntityAction<DAIEngineProfile, DeleteDAIEngineProfileRequest, DeleteDAIEngineProfileResponse>,
};

export const columns: IColumn[] = [
  {
    key: 'icon',
    name: 'Icon',
    minWidth: 50,
    maxWidth: 50,
    onRender: () => <ListCell styles={{ root: { paddingLeft: 12 } }} iconProps={{ iconName: 'Settings' }} />,
  },
  {
    key: 'displayName',
    name: 'Display Name',
    fieldName: 'displayName',
    minWidth: 200,
    maxWidth: 250,
    onRender: onRenderDisplayAndId,
  },
  {
    key: 'priority',
    name: 'Priority',
    fieldName: 'priority',
    minWidth: 80,
    maxWidth: 120,
    onRender,
  },
  {
    key: 'enabled',
    name: 'Enabled',
    fieldName: 'enabled',
    minWidth: 80,
    maxWidth: 120,
    onRender: onRenderBooleanCell,
  },
  {
    key: 'actions',
    name: 'Actions',
    fieldName: 'actions',
    minWidth: 170,
    maxWidth: 170,
  },
];

export const fields: EntityField<DAIEngineProfile>[] = [
  {
    name: 'name',
    type: EntityFieldType.IdOnDisplayAndId,
    label: 'Name',
    description: 'DAI Engine Profile resource name.',
    required,
  },
  {
    name: 'displayName',
    type: EntityFieldType.DisplayOnDisplayAndId,
    label: 'Display Name',
    description: 'Human-readable name of DAI Engine Profile.',
    required,
  },
  {
    name: 'enabled',
    type: EntityFieldType.Boolean,
    label: 'Enabled',
    description: `When set to true, the DAIEngineProfile is enabled and can be used in DAIEngine.`,
  },
  {
    name: 'assignedOidcRolesEnabled',
    type: EntityFieldType.Boolean,
    label: 'Assigned Oidc Roles Enabled',
    description: 'When set to true, the assigned_oidc_roles field is verified when a user uses this profile.',
  },
  {
    name: 'priority',
    type: EntityFieldType.Number,
    label: 'Priority',
    description: 'Priority of the DAI Engine Profile. Lower value means higher priority.',
  },
  {
    name: 'configEditability',
    type: EntityFieldType.SelectEnum,
    label: 'Config Editability',
    description: 'Specifies the behavior of DAIEngine.config editability when DAIEngine is using this profile.',
    options: getOptionsFromEnum(DAIEngineProfile_ConfigEditability, 'CONFIG_EDITABILITY_', [
      DAIEngineProfile_ConfigEditability.UNSPECIFIED,
    ]),
    selectedOption: DAIEngineProfile_ConfigEditability.FULL,
  },
  {
    name: 'maxRunningEngines',
    type: EntityFieldType.Number,
    label: 'Max Running Engines',
    description: 'Maximum number of DAI Engines per user that can be running when using this DAI Engine Profile.',
    minimum: 1,
  },
  {
    name: 'assignedOidcRoles',
    type: EntityFieldType.StringArray,
    label: 'Assigned Oidc Roles',
    description: 'List of OIDC roles assigned to this DAIEngineProfile.',
  },
  {
    name: 'cpuConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'CPU Constraint',
    description: `Constraint for each DAIEngine's cpu in the workspace.`,
    constraintType: ConstraintType.CPU,
    minimum: 1,
  },
  {
    name: 'gpuConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'GPU Constraint',
    description: `Constraint for each DAIEngine's gpu in the workspace.`,
    constraintType: ConstraintType.GPU,
  },
  {
    name: 'memoryBytesConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'Memory Constraint (GiB)',
    description: `Constraint for each DAIEngine's memory_bytes in the workspace.`,
    constraintType: ConstraintType.MEMORYBYTES,
  },
  {
    name: 'storageBytesConstraint',
    type: EntityFieldType.ConstraintNumeric,
    label: 'Storage Constraint (GiB)',
    description: `Constraint for each DAIEngine's storage_bytes in the workspace.`,
    constraintType: ConstraintType.STORAGEBYTES,
  },
  {
    name: 'maxIdleDurationConstraint',
    type: EntityFieldType.ConstraintDuration,
    label: 'Max Idle Duration Constraint',
    description: `Constraint for each DAIEngine's max_idle_duration in the workspace.`,
    constraintType: ConstraintType.MAXIDLEDURATION,
  },
  {
    name: 'maxRunningDurationConstraint',
    type: EntityFieldType.ConstraintDuration,
    label: 'Max Running Duration Constraint',
    description: `Constraint for each DAIEngine's max_running_duration in the workspace.`,
    constraintType: ConstraintType.MAXRUNNINGDURATION,
  },
  {
    name: 'maxNonInteractionDuration',
    type: EntityFieldType.Duration,
    label: 'Max Non Interaction Duration Constraint',
    description: `Max non-interaction duration applied on all DAIEngines in the workspace.`,
  },
  {
    name: 'maxUnusedDuration',
    type: EntityFieldType.Duration,
    label: 'Max Unused Duration Constraint',
    description: `Max unused duration applied on all DAIEngines in the workspace.`,
  },
  {
    name: 'configurationOverride',
    type: EntityFieldType.KeyValuePair,
    label: 'Configuration Override',
    description: `
      When specified, then it is applied on top of each DAIEngine's configuration_override in the workspace.

      It replaces config values for keys that are set and inserts new values for keys
      that are not set already. Other configuration key-value pairs are preserved.
    `,
  },
  {
    name: 'baseConfiguration',
    type: EntityFieldType.KeyValuePair,
    label: 'Base Configuration',
    description: `
      Base configuration forms the basis of the final configuration that is passed to the DAI server.
      See configuration_override for the overall configuration setup logic.
    `,
  },
  {
    name: 'yamlPodTemplateSpec',
    type: EntityFieldType.Yaml,
    label: 'Pod Template Specification (YAML)',
    description: `
      YAML representation of custom PodTemplateSpec serialized into bytes.
      When specified, then it is applied for each DAIEngine in the workspace.
    `,
  },
  {
    name: 'yamlGpuTolerations',
    type: EntityFieldType.Yaml,
    label: 'YAML GPU Tolerations',
    description: `
      YAML representation of custom GPU Tolerations serialized into bytes.
      When specified, then it is applied for each DAIEngine in the workspace.
     `,
  },
  {
    name: 'tritonEnabled',
    type: EntityFieldType.Boolean,
    label: 'Triton Enabled',
    description: 'True when DAI built-in Triton inference server is enabled, false when it is disabled.',
  },
];

export const daiEngineProfileEntity: Entity<DAIEngineProfile> = {
  type: EntityType.DAIEngineProfile,
  name: 'daiEngineProfiles',
  createButtonLabel: 'Add DAI Engine Profile',
  displayName: 'DAI Engine Profile',
  displayNameKey: 'displayName',
  actions,
  columns,
  fields,
  requestNameKeyPrefix: 'workspaces/global/daiEngineProfiles/',
  emptyModel: {
    name: 'new-dai-engine-profile-id',
    displayName: 'New DAI Engine Profile',
    priority: 0,
    enabled: true,
    assignedOidcRolesEnabled: false,
    assignedOidcRoles: [],
    maxRunningEngines: 0,
    cpuConstraint: {
      min: '1' as BigIntString,
      default: '2' as BigIntString,
    },
    gpuConstraint: {
      min: '0' as BigIntString,
      default: '0' as BigIntString,
    },
    memoryBytesConstraint: {
      min: '4294967296' as BigIntString,
      default: '8589934592' as BigIntString,
    },
    storageBytesConstraint: {
      min: '4294967296' as BigIntString,
      default: '8589934592' as BigIntString,
    },
    maxIdleDurationConstraint: {
      min: '0s' as BigIntString,
      default: '3600s' as BigIntString,
    },
    maxRunningDurationConstraint: {
      min: '0s' as BigIntString,
      default: '432000s' as BigIntString,
    },
    maxNonInteractionDuration: '1209600s',
    maxUnusedDuration: '1209600s',
    configurationOverride: {},
    baseConfiguration: {},
    configEditability: DAIEngineProfile_ConfigEditability.FULL,
    yamlPodTemplateSpec: '',
    yamlGpuTolerations: '',
    tritonEnabled: false,
  },
  canBeReordered: false,
};
