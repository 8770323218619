import { Stack, Text } from '@fluentui/react';
import { FontSizes, KeyValuePairEditor, useClassNames, useTheme } from '@h2oai/ui-kit';

import { DAIEngineProfileConfigEditabilityMap } from '../../../../aiem/defaults';
import { DAIEngineProfileInfo } from '../../../../aiem/gen/ai/h2o/engine/v1/dai_engine_pb';
import { Engine_Type } from '../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { H2OEngineProfile } from '../../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_profile_pb';
import { bytesToGibibytes, getIdFromName, secondsToFriendlyInterval } from '../../../../aiem/utils';
import { formatDateWithTime } from '../../../../utils/utils';
import { CodeArea } from '../../../CodeArea/CodeArea';
import { manageWorkspacesWrapperStyles } from './aiemPanelView.styles';

export type EngineProfileInfoProps = {
  profileInfo: DAIEngineProfileInfo | H2OEngineProfile;
  engineType?: Engine_Type;
};

export const EngineProfileInfo = (props: EngineProfileInfoProps) => {
  const { engineType, profileInfo } = props;
  const theme = useTheme();
  const isDAIEngine = engineType === Engine_Type.DRIVERLESS_AI;
  const viewLabel = { styles: { root: { fontSize: FontSizes.xsmall, color: theme.palette?.gray500 } } };
  const viewValue = { styles: { root: { fontSize: FontSizes.xsmall, color: theme.palette?.gray900 } } };
  const classNames = useClassNames('AIEMPanelView', manageWorkspacesWrapperStyles(theme)) as any;

  return (
    <Stack tokens={{ childrenGap: 20 }}>
      <Stack horizontal horizontalAlign="space-between">
        <Text {...viewLabel}>Display name</Text>
        <Text {...viewValue}>{profileInfo?.displayName}</Text>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Text {...viewLabel}>Name</Text>
        <Text {...viewValue}>{getIdFromName(profileInfo?.name)}</Text>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Text {...viewLabel}>Priority</Text>
        <Text {...viewValue}>{profileInfo?.priority}</Text>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Text {...viewLabel}>Enabled</Text>
        <Text {...viewValue}>{profileInfo?.enabled ? 'Yes' : 'No'}</Text>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Text {...viewLabel}>Assigned Oidc Roles Enabled</Text>
        <Text {...viewValue}>{profileInfo?.assignedOidcRolesEnabled ? 'Yes' : 'No'}</Text>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Text {...viewLabel}>Assigned Oidc Roles</Text>
        <Text {...viewValue}>{profileInfo?.assignedOidcRoles?.join(', ') || '-'}</Text>
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Text {...viewLabel}>Max Running Engines</Text>
        <Text {...viewValue}>{profileInfo?.maxRunningEngines}</Text>
      </Stack>
      <Stack>
        <table className={classNames.table}>
          <thead>
            <tr>
              <td></td>
              <td>Min</td>
              <td>Max</td>
              <td>Default</td>
              <td>Cumulative max</td>
            </tr>
          </thead>

          <tbody>
            {(profileInfo as H2OEngineProfile)?.nodeCountConstraint && (
              <tr>
                <td>Node Count Constraint</td>
                <td>{(profileInfo as H2OEngineProfile)?.nodeCountConstraint.min}</td>
                <td>{(profileInfo as H2OEngineProfile)?.nodeCountConstraint.max}</td>
                <td>{(profileInfo as H2OEngineProfile)?.nodeCountConstraint.default}</td>
                <td>{(profileInfo as H2OEngineProfile)?.nodeCountConstraint.cumulativeMax || '-'}</td>
              </tr>
            )}
            <tr>
              <td>CPU Constraint</td>
              <td>{profileInfo?.cpuConstraint?.min}</td>
              <td>{profileInfo?.cpuConstraint?.max || 'No limit'}</td>
              <td>{profileInfo?.cpuConstraint?.default}</td>
              <td>{profileInfo?.cpuConstraint?.cumulativeMax || 'No limit'}</td>
            </tr>
            <tr>
              <td>GPU Constraint</td>
              <td>{profileInfo?.gpuConstraint?.min}</td>
              <td>{profileInfo?.gpuConstraint?.max || 'No limit'}</td>
              <td>{profileInfo?.gpuConstraint?.default}</td>
              <td>{profileInfo?.gpuConstraint?.cumulativeMax || 'No limit'}</td>
            </tr>
            <tr>
              <td>Memory Bytes Constraint</td>
              <td>{bytesToGibibytes(profileInfo?.memoryBytesConstraint?.min)} GiB</td>
              <td>
                {profileInfo?.memoryBytesConstraint?.max
                  ? `${bytesToGibibytes(profileInfo?.memoryBytesConstraint?.max)} GiB`
                  : 'No Limit'}
              </td>
              <td>{bytesToGibibytes(profileInfo?.memoryBytesConstraint?.default)} GiB</td>
              <td>
                {profileInfo?.memoryBytesConstraint?.cumulativeMax
                  ? `${bytesToGibibytes(profileInfo?.memoryBytesConstraint.cumulativeMax)} GiB`
                  : 'No Limit'}
              </td>
            </tr>
            {(profileInfo as DAIEngineProfileInfo)?.storageBytesConstraint && (
              <tr>
                <td>Storage Bytes Constraint</td>
                <td>{bytesToGibibytes((profileInfo as DAIEngineProfileInfo)?.storageBytesConstraint?.min)} GiB</td>
                <td>
                  {(profileInfo as DAIEngineProfileInfo)?.storageBytesConstraint?.max
                    ? `${bytesToGibibytes((profileInfo as DAIEngineProfileInfo)?.storageBytesConstraint?.max)} GiB`
                    : 'No Limit'}
                </td>
                <td>{bytesToGibibytes((profileInfo as DAIEngineProfileInfo)?.storageBytesConstraint?.default)} GiB</td>
                <td>
                  {(profileInfo as DAIEngineProfileInfo)?.storageBytesConstraint?.cumulativeMax
                    ? `${bytesToGibibytes(
                        (profileInfo as DAIEngineProfileInfo)?.storageBytesConstraint?.cumulativeMax
                      )} GiB`
                    : 'No Limit'}
                </td>
              </tr>
            )}
            <tr>
              <td>Max Idle Duration Constraint</td>
              <td>{secondsToFriendlyInterval(profileInfo?.maxIdleDurationConstraint?.min)}</td>
              <td>{secondsToFriendlyInterval(profileInfo?.maxIdleDurationConstraint?.max) || 'No limit'}</td>
              <td>{secondsToFriendlyInterval(profileInfo?.maxIdleDurationConstraint?.default)}</td>
              <td>-</td>
            </tr>
            <tr>
              <td>Max Running Duration Constraint</td>
              <td>{secondsToFriendlyInterval(profileInfo?.maxRunningDurationConstraint?.min)}</td>
              <td>{secondsToFriendlyInterval(profileInfo?.maxRunningDurationConstraint?.max) || 'No limit'}</td>
              <td>{secondsToFriendlyInterval(profileInfo?.maxRunningDurationConstraint?.default)}</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </Stack>
      {isDAIEngine && (
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Max Non Interaction Duration</Text>
          <Text {...viewValue}>
            {secondsToFriendlyInterval((profileInfo as DAIEngineProfileInfo)?.maxNonInteractionDuration) || '-'}
          </Text>
        </Stack>
      )}
      {isDAIEngine && (
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Max Unused Duration</Text>
          <Text {...viewValue}>
            {secondsToFriendlyInterval((profileInfo as DAIEngineProfileInfo)?.maxUnusedDuration) || '-'}
          </Text>
        </Stack>
      )}
      {isDAIEngine && (
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>configurationOverride</Text>
          <KeyValuePairEditor
            styles={{ root: { width: '60%' } }}
            onUpdateConfig={() => null}
            config={(profileInfo as DAIEngineProfileInfo)?.configurationOverride || {}}
            readOnly
          />
          <Text {...viewValue}>{}</Text>
        </Stack>
      )}
      {isDAIEngine && (
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>baseConfiguration</Text>
          <KeyValuePairEditor
            styles={{ root: { width: '60%' } }}
            onUpdateConfig={() => null}
            config={(profileInfo as DAIEngineProfileInfo)?.baseConfiguration || {}}
            readOnly
          />
        </Stack>
      )}
      {isDAIEngine && (
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Config Editability</Text>
          <Text {...viewValue}>
            {DAIEngineProfileConfigEditabilityMap[(profileInfo as DAIEngineProfileInfo)?.configEditability || '']}
          </Text>
        </Stack>
      )}
      <Stack horizontal horizontalAlign="space-between">
        <Text {...viewLabel}>Yaml Pod Template Spec</Text>
        {profileInfo?.yamlPodTemplateSpec ? <CodeArea value={profileInfo?.yamlPodTemplateSpec} readOnly /> : '-'}
      </Stack>
      <Stack horizontal horizontalAlign="space-between">
        <Text {...viewLabel}>Yaml GPU Tolerations</Text>
        {profileInfo?.yamlGpuTolerations ? <CodeArea value={profileInfo?.yamlGpuTolerations} readOnly /> : '-'}
      </Stack>
      {isDAIEngine && (
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Triton Enabled</Text>
          <Text {...viewValue}>{(profileInfo as DAIEngineProfileInfo)?.tritonEnabled ? 'Yes' : 'No'}</Text>
        </Stack>
      )}
      {profileInfo?.createTime && (
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Create Time</Text>
          <Text {...viewValue}>{formatDateWithTime(new Date(profileInfo?.createTime))}</Text>
        </Stack>
      )}
      {profileInfo?.updateTime && (
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Update Time</Text>
          <Text {...viewValue}>{formatDateWithTime(new Date(profileInfo?.updateTime))}</Text>
        </Stack>
      )}
      <Stack horizontal horizontalAlign="space-between">
        <Text {...viewLabel}>Creator</Text>
        <Text {...viewValue}>{profileInfo?.creatorDisplayName}</Text>
      </Stack>
      {profileInfo?.updater && (
        <Stack horizontal horizontalAlign="space-between">
          <Text {...viewLabel}>Updater</Text>
          <Text {...viewValue}>{profileInfo?.updaterDisplayName}</Text>
        </Stack>
      )}
    </Stack>
  );
};
