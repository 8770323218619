import { IStyle } from '@fluentui/react';
import { Button, PageHeader, buttonStylesPrimary, mediaMobile, useClassNames } from '@h2oai/ui-kit';
import { useHistory, useLocation } from 'react-router-dom';

import { ClassNamesFromIStyles } from '../../utils/models';

type BreadcrumbsProps = {
  actionTitle?: string;
  onActionClick?: () => void;
  customPageTitle?: string;
  actionIcon?: string;
  isActionButtonDisabled?: boolean;
};

interface IBreadcrumbsStyles {
  root: IStyle;
  actionButtonContainer: IStyle;
  actionButton: IStyle;
}

const breadcrumbsStyles: Partial<IBreadcrumbsStyles> = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    // Adds extra margin to prevent "Need help?" button from overlapping.
    margin: '0px 40px',
  },
  actionButtonContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'end',
    alignItems: 'end',
    ...mediaMobile({
      position: 'fixed',
      bottom: '8vh',
      right: '5vw',
      width: '60vw',
      height: 60,
      zIndex: 10,
    }),
  },
  actionButton: {
    marginRight: 10,
    marginBottom: 5,
    ...mediaMobile({
      width: '60vw',
      height: 60,
      '.ms-Button-label': {
        fontSize: 30,
      },
      '.ms-Icon': {
        fontSize: 30,
      },
      '.ms-Button-textContainer ': {
        display: 'flex',
        justifyContent: 'center',
      },
    }),
  },
};

const headerStyles = {
  root: {
    padding: '10px 20px',
    minWidth: 500,
  },
};

const Header = ({
  actionTitle,
  onActionClick,
  actionIcon,
  isActionButtonDisabled,
  customPageTitle,
}: BreadcrumbsProps) => {
  const history = useHistory(),
    location = useLocation(),
    classNames = useClassNames<IBreadcrumbsStyles, ClassNamesFromIStyles<IBreadcrumbsStyles>>(
      'breadcrumbs',
      breadcrumbsStyles
    ),
    path = location.pathname.split('/'),
    pageTitle = path[path.length - 1],
    items = ['home', ...path]
      .filter((it) => it !== '')
      .map((item, idx) => {
        return {
          key: item,
          // Regex to add space between camel case words.
          text: item === 'create-new' ? 'Create new' : item.replace(/([a-z])([A-Z])/g, '$1 $2'),
          onClick: () => history.push(path.slice(0, idx + 1).join('/') || '/'),
        };
      });

  return (
    <div className={classNames.root}>
      <PageHeader
        breadcrumbProps={
          location.pathname !== '/'
            ? {
                items,
                styles: {
                  itemLink: {
                    textTransform: 'capitalize',
                  },
                },
              }
            : undefined
        }
        styles={[headerStyles, { title: { textTransform: customPageTitle ? undefined : 'capitalize' } }]}
        pageTitle={customPageTitle || pageTitle}
      />
      {actionTitle ? (
        <div className={classNames.actionButtonContainer}>
          <Button
            className={classNames.actionButton}
            styles={buttonStylesPrimary}
            iconProps={actionIcon ? { iconName: actionIcon } : undefined}
            text={actionTitle}
            onClick={onActionClick}
            disabled={isActionButtonDisabled}
          />
        </div>
      ) : undefined}
    </div>
  );
};

export default Header;
