import { Project } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/project_pb';
import {
  CreateProjectResponse,
  ListProjectsRequest,
  ListProjectsResponse,
} from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/project_service_pb';
import { ListProjectSharingsRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/project_service_pb';
import { Operator } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/query_pb';
import { Role } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/role_pb';
import { ListRolesRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/role_service_pb';
import { Sharing, SharingType } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/sharing_pb';
import { User } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/user_pb';
import { BatchGetUsersRequest } from '@buf/h2oai_mlops-storage.bufbuild_es/ai/h2o/mlops/storage/v1/user_service_pb';
import { ProjectService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/project_service_connect.js';
import { RoleService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/role_service_connect';
import { UserService } from '@buf/h2oai_mlops-storage.connectrpc_es/ai/h2o/mlops/storage/v1/user_service_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { MessageBarType } from '@fluentui/react';
import { useToast } from '@h2oai/ui-kit';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { NoItemView } from '../../components/NoItemView/NoItemView';
import { useCloudPlatformDiscovery, useUser } from '../../utils/hooks';
import { formatError } from '../../utils/utils';

type MLOpsPermission = 'canRead' | 'canWrite' | 'isOwner';

export type MLOpsPermissions = {
  [key in MLOpsPermission]: boolean;
};

type ProjectContextType = {
  projects?: Project[];
  activeProject?: Project;
  ACTIVE_PROJECT_ID?: string;
  activeProjectInitialized: boolean;
  loading?: boolean;
  isLoadingMore?: boolean;
  onLoadMore?: () => void;
  activateProject: (name: string) => void;
  fetchProjects: (pageToken?: string, filter?: string) => void;
  createProject: (displayName: string, projectDescription?: string) => void;
  isLoadingSearch?: boolean;
  projectSharings?: Sharing[];
  sharedUsers?: User[];
  listProjectSharings: () => void;
  roles?: Role[];
  permissions: MLOpsPermissions;
};

const getProjectFromUrl = (pathname: string) => {
  return pathname.includes('/mlops/projects/') && !pathname.endsWith('projects/')
    ? `${pathname.replace('/mlops/', '').split('/')[1]}`
    : undefined;
};

type SwitcherProps = {
  currentProject: string;
  destinationProject: string;
  activateDestinationProject: () => void;
};

const ProjectContext = React.createContext<ProjectContextType | undefined>(undefined);

const ProjectProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation(),
    history = useHistory(),
    { addToast } = useToast(),
    { id: userId } = useUser(),
    [activeProject, setActiveProject] = React.useState<Project | undefined>(),
    skipProceedDialogRef = React.useRef(false),
    loadStateRef = React.useRef({
      fetchProjects: false,
      createProject: false,
      fetchSharings: false,
      fetchUsersBatch: false,
      fetchRoles: false,
    }),
    [activeProjectInitialized, setActiveProjectInitialized] = React.useState(false),
    [loading, setLoading] = React.useState(true),
    [isLoadingMore, setIsLoadingMore] = React.useState(false),
    [isLoadingSearch, setIsLoadingSearch] = React.useState(false),
    [nextPageToken, setNextPageToken] = React.useState<string>(),
    [projectSwitcherProps, setProjectSwitcherProps] = React.useState<SwitcherProps>(),
    [projectNotFound, setProjectNotFound] = React.useState(false),
    [projects, setProjects] = React.useState<Project[]>(),
    [projectSharings, setProjectSharings] = React.useState<Sharing[] | undefined>(undefined),
    [permissions, setPermissions] = React.useState<MLOpsPermissions>({
      canRead: false,
      canWrite: false,
      isOwner: false,
    }),
    [sharedUsers, setSharedUsers] = React.useState<User[] | undefined>(undefined),
    [roles, setRoles] = React.useState<Role[] | undefined>(undefined),
    // TODO: Move to context global to mlops.
    cloudPlatformDiscovery = useCloudPlatformDiscovery(),
    mlopsApiUrl = cloudPlatformDiscovery?.mlopsApiUrl || '',
    transport = createConnectTransport({
      baseUrl: `${mlopsApiUrl}/storage/`,
    }),
    userClient = createPromiseClient(UserService, transport),
    roleClient = createPromiseClient(RoleService, transport),
    projectClient = createPromiseClient(ProjectService, transport),
    projectNamesToDisplayNames: { [key: string]: string } = React.useMemo(
      () =>
        projects
          ? projects.reduce(
              (acc, project) => ({ ...acc, [project.id || '']: project.displayName || '' }),
              {} as { [key: string]: string }
            )
          : {},
      [projects]
    ),
    evaluateLoading = () => {
      if (
        !loadStateRef.current.fetchProjects &&
        !loadStateRef.current.createProject &&
        !loadStateRef.current.fetchSharings &&
        !loadStateRef.current.fetchUsersBatch &&
        !loadStateRef.current.fetchRoles
      ) {
        setLoading(false);
      }
    },
    fetchProjects = React.useCallback(
      async (pageToken?: string, filter?: string) => {
        loadStateRef.current.fetchProjects = true;
        if (pageToken) setIsLoadingMore(true);
        else if (filter || filter === `""`) setIsLoadingSearch(true);
        else setLoading(true);
        try {
          // TODO: Handle next page token.
          const listProjectsBody = new ListProjectsRequest(
            filter
              ? {
                  filter: {
                    query: {
                      clause: [
                        {
                          propertyConstraint: [
                            {
                              property: {
                                propertyType: {
                                  value: 'display_name',
                                  case: 'field',
                                },
                              },
                              operator: Operator.CONTAINS,
                              value: {
                                value: {
                                  case: 'stringValue',
                                  value: filter,
                                },
                              },
                            },
                          ],
                        },
                      ],
                    },
                  },
                }
              : {}
          );
          const response: ListProjectsResponse = await projectClient.listProjects(listProjectsBody);
          const projectItems: Project[] | undefined = response?.project;
          if (response && !projectItems) console.error('No projects found in the response.');
          setNextPageToken(
            response?.paging?.nextPageToken ? new TextDecoder().decode(response.paging.nextPageToken) : undefined
          );
          setProjects((items) => (pageToken ? [...(items || []), ...(projectItems || [])] : projectItems));
        } catch (err) {
          const message = `Failed to fetch projects: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setProjects(undefined);
        } finally {
          loadStateRef.current.fetchProjects = false;
          evaluateLoading();
          setIsLoadingMore(false);
          setIsLoadingSearch(false);
        }
      },
      [addToast]
    ),
    activateProject = React.useCallback(
      (projectId: string, toDashboard?: boolean) => {
        const project = projects?.find((project) => project.id === projectId);
        if (!userId || !projectId || !projects || !project) {
          const message = `Failed to activate project. ${
            !userId
              ? 'User ID is missing.'
              : !projectId
              ? 'Project ID is missing.'
              : !projects
              ? 'Projects are not loaded yet.'
              : !project
              ? 'Project you are trying to activate was not found.'
              : ''
          }`;
          console.error(message);
          addToast({ messageBarType: MessageBarType.error, message });
          return;
        }
        const lastActiveProject = localStorage.getItem(userId || '');
        if (projectId !== lastActiveProject) {
          skipProceedDialogRef.current = true;
          // TODO: Use preferences service once it's available.
          localStorage.setItem(userId, projectId);
          setActiveProject(project);
          if (toDashboard) {
            history.push(`/mlops/projects/${projectId}`);
          } else if (location.pathname.startsWith('/mlops/projects/') && !location.pathname.endsWith('projects/')) {
            const pathnameEnd = location.pathname.replace('/mlops/projects/', '').split(/\/(.*)/s)[1];
            const path = pathnameEnd?.endsWith('/create-new') ? pathnameEnd.replace('/create-new', '') : pathnameEnd;
            history.push(`/mlops/projects/${projectId}${path ? `/${path}` : ''}`);
          }
        }
      },
      [userId, projects]
    ),
    createProject = React.useCallback(
      async (displayName: string, projectDescription?: string) => {
        loadStateRef.current.createProject = true;
        setLoading(true);
        try {
          const response: CreateProjectResponse = await projectClient.createProject({
            project: {
              displayName,
              description: projectDescription ? projectDescription : undefined,
            },
          });
          const projectName = response?.project?.id;
          addToast({
            messageBarType: MessageBarType.success,
            message: 'Project created successfully.',
          });
          await fetchProjects();
          skipProceedDialogRef.current = true;
          if (projectName) {
            // TODO: Update preferences once preferences service is available.
            // Activate project after creation.
            localStorage.setItem(userId, projectName);
            setActiveProject(response.project);
            history.push(`/mlops/projects/${projectName}`);
          }
        } catch (err) {
          const message = `Failed to create project: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
        } finally {
          loadStateRef.current.createProject = false;
          evaluateLoading();
        }
      },
      [fetchProjects, userId, activateProject, addToast]
    ),
    batchGetUsers = React.useCallback(
      async (userIds: string[]) => {
        loadStateRef.current.fetchUsersBatch = true;
        setLoading(true);
        try {
          const batchGetUsersBody = new BatchGetUsersRequest({
            IDs: userIds,
          });
          const response = await userClient.batchGetUsers(batchGetUsersBody);
          const userItems: User[] | undefined = response?.users;
          if (response && !userItems) console.error('No users found in the response.');
          setSharedUsers(userItems);
        } catch (err) {
          const message = `Failed to batch fetch users: ${formatError(err)}`;
          console.error(message);
          addToast({
            messageBarType: MessageBarType.error,
            message,
          });
          setSharedUsers(undefined);
        }
        loadStateRef.current.fetchUsersBatch = false;
        evaluateLoading();
      },
      [addToast, userClient]
    ),
    listProjectSharings = React.useCallback(async () => {
      loadStateRef.current.fetchSharings = true;
      setLoading(true);
      try {
        const response = await projectClient.listProjectSharings(
          new ListProjectSharingsRequest({ projectId: activeProject?.id })
        );
        const sharingItems: Sharing[] | undefined = response?.sharing;
        if (response && !sharingItems) console.error('No project sharings found in the response.');
        setProjectSharings(sharingItems);
        await batchGetUsers(sharingItems?.map((sharing) => sharing.userId) || []);
      } catch (err) {
        const message = `Failed to fetch project sharings: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setProjectSharings(undefined);
      }
      loadStateRef.current.fetchSharings = false;
      evaluateLoading();
    }, [addToast, projectClient, activeProject?.id, batchGetUsers]),
    listRoles = React.useCallback(async () => {
      loadStateRef.current.fetchRoles = true;
      setLoading(true);
      try {
        const response = await roleClient.listRoles(new ListRolesRequest());
        const roleItems: Role[] | undefined = response?.role;
        if (response && !roleItems) console.error('No roles found in the response.');
        setRoles(roleItems);
      } catch (err) {
        const message = `Failed to fetch roles: ${formatError(err)}`;
        console.error(message);
        addToast({
          messageBarType: MessageBarType.error,
          message,
        });
        setRoles(undefined);
      }
      loadStateRef.current.fetchRoles = false;
      evaluateLoading();
    }, [addToast, roleClient]);

  React.useEffect(() => void fetchProjects(), []);

  React.useEffect(() => {
    listRoles();
  }, []);

  React.useEffect(() => {
    if (!activeProject?.id) return;
    listProjectSharings();
  }, [activeProject?.id]);

  React.useEffect(() => {
    const lastActiveProject = localStorage.getItem(userId || '');
    const projectNameFromUrl = getProjectFromUrl(location.pathname);
    if (!loading && projects && lastActiveProject && activeProject?.id !== projectNameFromUrl) {
      const isProjectFromUrlAvailable =
        projectNameFromUrl &&
        projects.find((project) => {
          return project.id === projectNameFromUrl;
        });
      if (isProjectFromUrlAvailable && projectNameFromUrl !== lastActiveProject && !skipProceedDialogRef.current) {
        setProjectSwitcherProps({
          currentProject: projectNamesToDisplayNames[lastActiveProject] || '',
          destinationProject: projectNamesToDisplayNames[projectNameFromUrl] || '',
          activateDestinationProject: () => {
            activateProject(projectNameFromUrl);
            setProjectSwitcherProps(undefined);
          },
        });
      } else {
        if (lastActiveProject) {
          setActiveProject(projects.find((project) => project.id === lastActiveProject));
        }
        if (projectNameFromUrl && !isProjectFromUrlAvailable) setProjectNotFound(true);
      }
    }
    // Set active project as initialized after everything is loaded and set for ProjectRedirect to work properly.
    if (!loading) setActiveProjectInitialized(true);
    skipProceedDialogRef.current = false;
  }, [projects, location.pathname, loading, activeProject, userId]);

  React.useEffect(() => {
    const lastActiveProject = localStorage.getItem(userId || '');
    setActiveProject(projects?.find((project) => project.id === lastActiveProject));
    setActiveProjectInitialized(true);
  }, [projects, location.pathname]);

  React.useEffect(() => {
    if (!userId || !projectSharings || !roles) return;
    const readerRoleId = roles?.find((role) => role.displayName === 'Reader')?.id,
      defaultRoleId = roles?.find((role) => role.displayName === 'Default')?.id,
      currentUserSharing = projectSharings.find((sharing) => sharing.userId === userId),
      isCurrentUserOwner =
        currentUserSharing?.restrictionRoleId === '' && currentUserSharing?.type === SharingType.OWNER,
      isCurrentUserReader = currentUserSharing?.restrictionRoleId === readerRoleId,
      isCurrentUserDefault = currentUserSharing?.restrictionRoleId === defaultRoleId;
    // TODO: Handle "Nobody" role.
    // nobodyRoleId = roles?.find((role) => role.displayName === 'Nobody')?.id,
    // isCurrentUserNobody = currentUserSharing?.restrictionRoleId === nobodyRoleId;

    setPermissions({
      canRead: isCurrentUserOwner || isCurrentUserReader || isCurrentUserDefault,
      canWrite: isCurrentUserOwner || isCurrentUserDefault,
      isOwner: isCurrentUserOwner,
    });
  }, [userId, projectSharings, roles]);

  const value = {
    projects,
    activeProject,
    activateProject,
    ACTIVE_PROJECT_ID: activeProject?.id,
    activeProjectInitialized,
    loading,
    fetchProjects,
    createProject,
    isLoadingMore,
    onLoadMore: nextPageToken ? () => void fetchProjects(nextPageToken) : undefined,
    isLoadingSearch,
    projectSharings,
    sharedUsers,
    listProjectSharings,
    roles,
    permissions,
  };

  return (
    <ProjectContext.Provider value={value}>
      {projectSwitcherProps ? (
        <NoItemView
          actionIcon="NavigateForward"
          title={`The URL is not from your active project`}
          description={`Do you want to leave ${projectSwitcherProps.currentProject} and activate ${projectSwitcherProps.destinationProject}?`}
          actionTitle={`Proceed to ${projectSwitcherProps.destinationProject}`}
          onActionClick={projectSwitcherProps.activateDestinationProject}
        />
      ) : projectNotFound ? (
        <NoItemView
          actionIcon="NavigateForward"
          title="Project not found"
          description="The project you are trying to access does not exist or you do not have access to it."
          actionTitle="Go to project selection"
          onActionClick={() => {
            setProjectNotFound(false);
            history.push('/mlops/projects');
          }}
        />
      ) : (
        children
      )}
    </ProjectContext.Provider>
  );
};

const useProjects = () => {
  const context = React.useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjects must be used within a ProjectsProvider');
  }
  return context;
};

export { ProjectProvider, useProjects };
