import { IDropdownOption } from '@fluentui/react';

import { AIEngine } from '../../../../../aiem/engine/types';
import { DAIEngineProfile } from '../../../../../aiem/gen/ai/h2o/engine/v1/dai_engine_profile_pb';
import { Engine_Type } from '../../../../../aiem/gen/ai/h2o/engine/v1/engine_pb';
import { H2OEngineProfile } from '../../../../../aiem/gen/ai/h2o/engine/v1/h2o_engine_profile_pb';
import { bytesToGibibytes, gibibytesToBytes } from '../../../../../aiem/utils';
import { EntityModelTypes } from '../../../../../pages/AIEngineSettingsPage/components/ListWithCollapsedSettings/types';
import { H2OEngineProfileOptionKeyType } from '../../../constants';
import {
  MetadataLabelCell,
  MetadataRow,
  MetadataTable,
  MetadataTableBody,
  MetadataValueCell,
} from '../../MetadataTable/MetadataTable';
import SpinnerWithTooltip from '../../SpinnerWithTooltip/SpinnerWithTooltip';
import ConfigureCompressedDataEngine from './ConfigureCompressedDataEngine';
import ConfigureRawDataEngine from './ConfigureRawDataEngine';
import DisplayPresetEngine from './DisplayPresetEngineProfile';

export function generateTooltipMessage(label: string, min?: number | string | null, max?: number | string | null) {
  if (!(min || max)) return undefined;
  if (!max) return `Min: ${min} ${label}. `;
  if (!min) return `Max: ${max} ${label}. `;
  return `Allowed range: ${min}-${max} ${label}. `;
}

interface ConfigureCustomEngineProfileProps {
  engine: AIEngine;
  modifyEngine: any;
  selectedConfigurationOption: IDropdownOption;
  constraintSet?: EntityModelTypes;
  operationCreate?: boolean;
}

export default function ConfigureCustomEngineProfile({
  engine,
  constraintSet,
  modifyEngine,
  operationCreate,
  selectedConfigurationOption,
}: ConfigureCustomEngineProfileProps) {
  const engineType = engine?.engineType;
  const isH2ODefaultProfileSelected =
    selectedConfigurationOption.key === H2OEngineProfileOptionKeyType.compressed ||
    selectedConfigurationOption.key === H2OEngineProfileOptionKeyType.raw;

  const memoryConstraintMin = parseInt(bytesToGibibytes(constraintSet?.memoryBytesConstraint?.min) || '0');
  const memoryConstraintMax = parseInt(bytesToGibibytes(constraintSet?.memoryBytesConstraint?.max) || '');
  const memoryConstraintValue = parseInt(bytesToGibibytes(engine.memoryBytes) || '0');
  const memoryConstraintError =
    memoryConstraintValue < memoryConstraintMin
      ? `Minimum "Memory per Node" for the selected profile is ${memoryConstraintMin}GiB`
      : memoryConstraintValue > memoryConstraintMax
      ? `Maximum "Memory per Node" for the selected profile is ${memoryConstraintMax}GiB`
      : '';

  const EngineProfileComponent = (function () {
    if (selectedConfigurationOption.key === H2OEngineProfileOptionKeyType.compressed) {
      return ConfigureCompressedDataEngine;
    }
    if (selectedConfigurationOption.key === H2OEngineProfileOptionKeyType.raw) return ConfigureRawDataEngine;
    return DisplayPresetEngine;
  })();

  return (
    <MetadataTable>
      <MetadataTableBody>
        {isH2ODefaultProfileSelected && (
          <EngineProfileComponent
            engine={engine}
            constraintSet={constraintSet as H2OEngineProfile}
            modifyEngine={modifyEngine}
          />
        )}
        <MetadataRow>
          <MetadataLabelCell colspan={3}>
            {engineType === Engine_Type.H2O ? 'CPUs per Node' : 'CPU Units'}
          </MetadataLabelCell>
          <MetadataValueCell loading={!constraintSet}>
            <SpinnerWithTooltip
              onChange={(_: any, value: number) => {
                modifyEngine({ cpu: value });
              }}
              value={engine.cpu || 0}
              min={constraintSet?.cpuConstraint?.min}
              max={constraintSet?.cpuConstraint?.max}
              tooltip={generateTooltipMessage(
                'CPU unit(s)',
                constraintSet?.cpuConstraint?.min,
                constraintSet?.cpuConstraint?.max
              )}
            />
          </MetadataValueCell>
        </MetadataRow>
        <MetadataRow>
          <MetadataLabelCell colspan={3}>
            {engineType === Engine_Type.H2O ? 'GPUs per Node' : 'GPU Units'}
          </MetadataLabelCell>
          <MetadataValueCell loading={!constraintSet}>
            <SpinnerWithTooltip
              min={constraintSet?.gpuConstraint?.min}
              max={constraintSet?.gpuConstraint?.max}
              tooltip={generateTooltipMessage(
                'GPU unit(s)',
                constraintSet?.gpuConstraint?.min,
                constraintSet?.gpuConstraint?.max
              )}
              onChange={(_: any, value: number) => {
                modifyEngine({
                  gpu: value,
                });
              }}
              value={engine.gpu || 0}
            />
          </MetadataValueCell>
        </MetadataRow>
        {engineType === Engine_Type.H2O && (
          <MetadataRow>
            <MetadataLabelCell colspan={3}>Number of Nodes</MetadataLabelCell>
            <MetadataValueCell loading={!constraintSet}>
              <SpinnerWithTooltip
                onChange={(_: any, value: number) => {
                  modifyEngine({
                    nodeCount: value,
                  });
                }}
                value={engine.nodeCount || 1}
                min={(constraintSet as H2OEngineProfile)?.nodeCountConstraint?.min || 1}
                max={(constraintSet as H2OEngineProfile)?.nodeCountConstraint?.max}
                tooltip={generateTooltipMessage(
                  'node(s)',
                  (constraintSet as H2OEngineProfile)?.nodeCountConstraint?.min,
                  (constraintSet as H2OEngineProfile)?.nodeCountConstraint?.max
                )}
              />
            </MetadataValueCell>
          </MetadataRow>
        )}
        <MetadataRow>
          <MetadataLabelCell colspan={3}>
            {engineType === Engine_Type.H2O ? 'Memory per Node' : 'Memory'}
          </MetadataLabelCell>
          <MetadataValueCell loading={!constraintSet}>
            <SpinnerWithTooltip
              disabled={isH2ODefaultProfileSelected}
              min={memoryConstraintMin}
              max={memoryConstraintMax}
              tooltip={generateTooltipMessage('GiB', memoryConstraintMin, memoryConstraintMax)}
              onChange={(_: any, value: number) => {
                modifyEngine({
                  memoryBytes: gibibytesToBytes(value),
                });
              }}
              value={memoryConstraintValue}
              suffix="GiB"
            />
          </MetadataValueCell>
        </MetadataRow>
        {memoryConstraintError && (
          <MetadataRow>
            <MetadataLabelCell colspan={4}>
              <p style={{ color: 'var(--h2o-red500, #ba2525)' }}>{memoryConstraintError}</p>
            </MetadataLabelCell>
          </MetadataRow>
        )}
        {engineType === Engine_Type.DRIVERLESS_AI && (
          <MetadataRow>
            <MetadataLabelCell colspan={3}>Storage</MetadataLabelCell>
            <MetadataValueCell loading={!constraintSet}>
              <SpinnerWithTooltip
                min={bytesToGibibytes((constraintSet as DAIEngineProfile)?.storageBytesConstraint?.min)}
                max={bytesToGibibytes((constraintSet as DAIEngineProfile)?.storageBytesConstraint?.max)}
                tooltip={
                  operationCreate
                    ? generateTooltipMessage(
                        'GiB',
                        bytesToGibibytes((constraintSet as DAIEngineProfile)?.storageBytesConstraint?.min),
                        bytesToGibibytes((constraintSet as DAIEngineProfile)?.storageBytesConstraint?.max)
                      )
                    : 'In order to resize the engine storage, please use the "Resize" option from the action button on the engine list.'
                }
                onChange={(_: any, value: number) => {
                  modifyEngine({
                    storageBytes: gibibytesToBytes(value),
                  });
                }}
                value={parseInt(bytesToGibibytes(engine.storageBytes) || '0')}
                suffix="GiB"
                disabled={!operationCreate}
              />
            </MetadataValueCell>
          </MetadataRow>
        )}
      </MetadataTableBody>
    </MetadataTable>
  );
}
