import { Dropdown, Label, Stack, TextField, Toggle } from '@fluentui/react';
import type { IDropdownOption, ITextFieldProps } from '@fluentui/react';
import { BasicList, Item, TextListEditor, basicListStylesInput, itemStylesDropdown } from '@h2oai/ui-kit';
import { useEffect, useState } from 'react';

import { getNameFromPath } from '../../../../pages/SecureStoreAdmin/components/parts';
import { fetchWrapRPC } from '../../../../services/api';
import { useCloudPlatformDiscovery } from '../../../../utils/hooks';
import { useFormAttributes } from '../../../../utils/utils';
import { FluentNumberField } from '../../../FluentNumberField/FluentNumberField';
import { LabelIconTooltip } from '../../../LabelIconTooltip/LabelIconTooltip';
import { bytesToGibibytes, gibibytesToBigIntStringBytes } from '../../utils';
import { defaultEntityFormRowStyles } from '../DefaultEntityFormRowStyles';
import { LabelAndDescription } from '../LabelAndDescription';
import type { EntityFieldInputProps, FormRowProps, NumberEntityFieldInputProps } from './types';

export const FormRow = ({ singleRow = false, children }: FormRowProps) => {
  const { inputContainerProps, inputRowProps, singleRowInputRowProps } = useFormAttributes();

  return (
    <Stack {...(singleRow ? singleRowInputRowProps : inputRowProps)} style={defaultEntityFormRowStyles}>
      <Stack {...inputContainerProps}>{children}</Stack>
    </Stack>
  );
};

export const TextEntityModelField = <EntityModel, EntityType extends string>(
  props: EntityFieldInputProps<EntityModel, EntityType>
) => {
  const { field, model, onChange, disabled = false } = props;
  const { name, label, description, required } = field;
  const [fieldValue, setFieldValue] = useState<string>((model as any)[name] as string);

  return (
    <FormRow>
      <TextField
        required={required}
        disabled={disabled}
        autoFocus
        label={label}
        data-test={`admin-setting-${String(name)}-input`}
        value={fieldValue}
        onChange={(_ev, value) => {
          setFieldValue(value || '');
          onChange && onChange(name, value);
        }}
        onRenderLabel={(
          labelProps: ITextFieldProps | undefined,
          defaultRender: ((props?: ITextFieldProps | undefined) => JSX.Element | null) | undefined
        ) => (
          <LabelIconTooltip
            id={labelProps?.id}
            data-test={`admin-settings-${String(name)}-info`}
            label={defaultRender!(labelProps) as any}
            tooltip={description}
          />
        )}
      />
    </FormRow>
  );
};

export const NumberEntityModelField = <EntityModel, EntityType extends string>(
  props: NumberEntityFieldInputProps<EntityModel, EntityType>
) => {
  const { field, model, onChange, convertToGibibytes = false, disabled = false } = props;
  const { name, label, description, minimum, maximum, referenceName } = field;
  const fieldValue: string = (model as any)[referenceName || name] as string;

  return (
    <FormRow>
      <FluentNumberField
        required={field.required}
        value={convertToGibibytes ? bytesToGibibytes(fieldValue) : fieldValue}
        min={Number(minimum)}
        max={maximum ? Number(maximum) : undefined}
        label={label}
        tooltip={description}
        disabled={disabled}
        onChange={(_ev, value) => {
          onChange &&
            onChange!(
              referenceName || name,
              convertToGibibytes ? gibibytesToBigIntStringBytes(Number(value)) : Number(value)
            );
        }}
      />
    </FormRow>
  );
};

export const BooleanEntityModelField = <EntityModel, EntityType extends string>(
  props: EntityFieldInputProps<EntityModel, EntityType>
) => {
  const { field, model, onChange, disabled = false, largeLabel = false } = props;
  const { name, label, referenceName } = field;
  const checked: boolean = Boolean((model as any)[referenceName || name]) as boolean;

  return (
    <Stack horizontal tokens={{ childrenGap: 15 }} style={defaultEntityFormRowStyles}>
      {largeLabel && <LabelAndDescription label={label} />}
      <Toggle
        checked={checked}
        onChange={(_ev, newValue) => (onChange ? onChange(referenceName || name, newValue) : undefined)}
        inlineLabel
        label={largeLabel ? undefined : label}
        disabled={disabled}
      />
    </Stack>
  );
};

export const SelectEnumEntityModelField = <EntityModel, EntityType extends string>(
  props: EntityFieldInputProps<EntityModel, EntityType>
) => {
  const { field, model, onChange } = props;
  const { name, label, options = [], referenceName } = field;
  const [fieldValue, setFieldValue] = useState<string>((model as any)[referenceName || name] as string);

  return (
    <FormRow>
      <Label required={field.required}>{label}</Label>
      {options ? (
        <Dropdown
          options={options}
          selectedKey={fieldValue}
          onChange={(_ev, v) => {
            const value = v?.key as string;
            setFieldValue(value);
            onChange && onChange(referenceName || name, value);
          }}
        />
      ) : (
        <>This field has an implementation error</>
      )}
    </FormRow>
  );
};

export const SelectAsyncEntityModelField = <EntityModel, EntityType extends string>(
  props: EntityFieldInputProps<EntityModel, EntityType>
) => {
  const cloudPlatformDiscovery = useCloudPlatformDiscovery();
  const { field, model, onChange } = props;
  const { name, label, referenceName, selectAsyncConfig } = field;
  const [fieldValue, setFieldValue] = useState<string>((model as any)[referenceName || name] as string);
  const [options, setOptions] = useState<IDropdownOption[]>([]);

  useEffect(() => {
    void fetchFieldOptions();
  }, []);

  if (!selectAsyncConfig) return null;

  const { fetchOptionsRPC, fetchOptionsBasePath, fetchOptionsRequestConfig } = selectAsyncConfig;

  if (!fetchOptionsRPC || !cloudPlatformDiscovery || !fetchOptionsBasePath) return null;

  const basePath = cloudPlatformDiscovery[fetchOptionsBasePath] || '';

  const fetchFieldOptions = async () => {
    try {
      const response = await fetchWrapRPC(fetchOptionsRPC, { basePath })({ ...fetchOptionsRequestConfig });
      setOptions(
        response[selectAsyncConfig.responseKey || ''].map((responseItem: any) => ({
          // It's our common response structure. Can be customized though passing the "key" and "name" keys in the props.
          key: responseItem.name,
          text: responseItem.displayName || getNameFromPath(responseItem.name),
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormRow>
      <Label required={field.required}>{label}</Label>
      <Dropdown
        placeholder={selectAsyncConfig.placeholder}
        options={options}
        selectedKey={fieldValue}
        onChange={(_ev, v) => {
          const value = v?.key as string;
          setFieldValue(value);
          onChange && onChange(referenceName || name, value);
        }}
      />
    </FormRow>
  );
};

export const StringArrayEntityModelField = <EntityModel, EntityType extends string>(
  props: EntityFieldInputProps<EntityModel, EntityType>
) => {
  const { field, model, onChange } = props;
  const { name, label, referenceName } = field;
  const items: string[] = (model as any)[referenceName || name] as string[];

  return (
    <FormRow>
      <Label required={field.required}>{label}</Label>
      <TextListEditor
        items={items}
        onChange={(value: string[]) => (onChange ? onChange(referenceName || name, value) : undefined)}
      />
    </FormRow>
  );
};

export const ReadOnlyStringArrayEntityModelField = <EntityModel, EntityType extends string>({
  field,
  model,
}: EntityFieldInputProps<EntityModel, EntityType>) => {
  const { name, label, referenceName } = field;
  const items: string[] = (model as any)[referenceName || name] as string[];

  return (
    <FormRow>
      <Label required={field.required}>{label}</Label>
      <BasicList
        title={label}
        styles={basicListStylesInput}
        idField="key"
        labelField="text"
        data={[{ key: '__input__', text: 'input' }, ...items?.map((d) => ({ key: d, text: d }))]}
        data-test="text-list-editor"
        horizontal
        itemRenderer={(d: IDropdownOption) => (
          <Item
            key={`${d.key}-string-array-item`}
            styles={itemStylesDropdown}
            data={d}
            idField="key"
            labelField="text"
          />
        )}
      />
    </FormRow>
  );
};

export const LatestAndAliasesEntityModelField = <EntityModel, EntityType extends string>(
  props: EntityFieldInputProps<EntityModel, EntityType>
) => {
  const { field, model, onChange, disabled = false } = props;
  const { name, label, referenceName } = field;
  const originalItems = (model as any)[referenceName || name] as string[];
  const [items, setItems] = useState<string[]>(originalItems);
  const [latest, setLatest] = useState<boolean>(originalItems.includes('latest'));

  return (
    <>
      <FormRow singleRow>
        <Toggle
          checked={latest}
          onChange={(_ev, checked) => {
            const newItems = [
              ...(checked ? ['latest'] : []),
              ...(checked ? items : items.filter((item) => item !== 'latest')),
            ];
            setItems(newItems);
            setLatest(Boolean(checked));
            onChange && onChange(name, newItems);
          }}
          inlineLabel
          label={'Latest'}
          disabled={disabled}
        />
      </FormRow>
      <FormRow>
        <Label required={field.required}>{label}</Label>
        <TextListEditor
          items={items}
          onChange={(value: string[]) => {
            setItems(value);
            setLatest(value.includes('latest'));
            onChange && onChange(referenceName || name, value);
          }}
        />
      </FormRow>
    </>
  );
};
