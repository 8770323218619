import { DetailsRow, IDetailsRowBaseProps, Stack } from '@fluentui/react';
import { useTheme } from '@h2oai/ui-kit';
import { useCallback, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { LargeAddEditForm } from '../LargeAddEditForm';
import { detailsListRowStyles, detailsStickyWrapperStyles } from './listWithCollapsedSettings.styles';
import { EntityModelTypes, EntityRowState, ListRowProps } from './types';

export const ListRow = <EntityModel extends EntityModelTypes>(props: ListRowProps<EntityModel>) => {
  const { rowProps, entity, onChange, activeEntity } = props;
  const isNewModelItem = activeEntity?.rowState === EntityRowState.CREATE && rowProps.itemIndex === 0;
  const expanded = !!activeEntity && (activeEntity.model.name === rowProps.item.name || isNewModelItem);
  const theme = useTheme();
  const [listRow, setListRow] = useState<IDetailsRowBaseProps | undefined>(rowProps);

  const handleChange = useCallback(
    (model: EntityModel) => {
      const updatedListRow = { ...listRow, item: { ...model } } as IDetailsRowBaseProps;
      setListRow(updatedListRow);
      onChange(model);
    },
    [listRow, activeEntity]
  );

  if (!listRow) return null;

  return (
    <Stack style={{ position: 'relative', marginBottom: 8 }}>
      <Stack styles={detailsStickyWrapperStyles(expanded)}>
        <DetailsRow {...rowProps} styles={detailsListRowStyles(theme)} />
      </Stack>
      <CSSTransition in={expanded} timeout={600} classNames="expand" unmountOnExit>
        <Stack
          style={{
            marginTop: 8,
            padding: 16,
            background: theme.palette?.white,
            border: '1px solid var(--h2o-gray300, #D1D5DB)',
            borderRadius: 4,
            overflow: 'hidden',
          }}
        >
          <LargeAddEditForm
            expandedEntityProps={activeEntity}
            onChange={handleChange}
            model={activeEntity?.model}
            entityType={entity.type}
            isCreate={activeEntity?.rowState === EntityRowState.CREATE}
          />
        </Stack>
      </CSSTransition>
    </Stack>
  );
};
