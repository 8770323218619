import { Label, Stack } from '@fluentui/react';
import { IStyleSetBase } from '@fluentui/style-utilities';

import { defaultEntityLabelColumnWidth } from './DefaultEntityFormRowStyles';

type LabelAndDescriptionProps = {
  label: string;
  description?: string;
  light?: boolean;
  rootStyles?: IStyleSetBase;
  required?: boolean;
};

export const LabelAndDescription = (props: LabelAndDescriptionProps) => {
  const { label, description, light = false, rootStyles = {}, required = false } = props;

  return (
    <Stack
      styles={
        light
          ? undefined
          : {
              root: {
                minWidth: defaultEntityLabelColumnWidth,
                maxWidth: defaultEntityLabelColumnWidth,
                ...rootStyles,
              },
            }
      }
    >
      <Label styles={{ root: { fontWeight: light ? 400 : 700 } }} required={required}>
        {label}
      </Label>
      {description}
    </Stack>
  );
};
