import {
  Check,
  CheckboxVisibility,
  IComboBox,
  IComboBoxOption,
  IContextualMenuItem,
  IStyle,
  Icon,
  MessageBarType,
  SelectAllVisibility,
  Selection,
  SelectionMode,
  SpinnerSize,
  Stack,
} from '@fluentui/react';
import {
  Button,
  Checkbox,
  ComboBox,
  ConfirmDialog,
  DetailsList,
  IH2OTheme,
  Loader,
  Persona,
  Search,
  WidgetItem,
  buttonStylesStealth,
  useClassNames,
  useTheme,
  useToast,
} from '@h2oai/ui-kit';
import React from 'react';

import { RoleBinding } from '../../authz/gen/ai/h2o/authorization/v1/role_binding_pb';
import { User } from '../../authz/gen/ai/h2o/user/v1/user_pb';
import Header from '../../components/Header/Header';
import { useUser } from '../../utils/hooks';
import { ClassNamesFromIStyles } from '../../utils/models';
import { formatError } from '../../utils/utils';
import NavigationWrapper from './NavigationWrapper';
import { useRoles } from './RoleProvider';
import { useUsers } from './UsersProvider';
import { useWorkspaces } from './WorkspaceProvider';

type UserItem = User & {
  key: string;
  name: string;
  email?: string;
  image?: string;
  roleBindings?: RoleBinding[];
  disabled?: boolean;
  onRoleChange: (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption | undefined,
    index?: number | undefined,
    value?: string | undefined
  ) => void;
  disabledOptions?: string[];
};

type RoleOption = {
  key: string;
  name?: string;
  description?: string;
  selected: boolean;
};

interface IAccessControlStyles {
  pageWrapper?: IStyle;
  toolbar?: IStyle;
  toolbarButton?: IStyle;
  searchBar?: IStyle;
  noBindingContainer?: IStyle;
}

const accessControlStyles = (theme: IH2OTheme): IAccessControlStyles => {
  return {
    pageWrapper: {
      padding: 10,
      // Adds extra margin to prevent "Need help?" button from overlapping.
      margin: '10px 60px',
      backgroundColor: theme?.semanticColors?.contentBackground,
      borderRadius: 4,
    },
    toolbar: {
      padding: '12px 6px',
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    toolbarButton: {
      marginRight: 2,
    },
    searchBar: {
      flexGrow: 1,
      maxWidth: 380,
      marginLeft: 18,
      marginRight: 8,
    },
    noBindingContainer: {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      justifyContent: 'center',
      minHeight: 450,
      whiteSpace: 'pre-line',
    },
  };
};

// TODO: Handle loading.
// TODO: Handle if role bindings are not fetched.
const AccessControl = () => {
  const theme = useTheme(),
    classNames = useClassNames<IAccessControlStyles, ClassNamesFromIStyles<IAccessControlStyles>>(
      'accessControl',
      accessControlStyles(theme)
    ),
    { addToast } = useToast(),
    {
      orchestratorRoles,
      bootstrapRoles,
      roleBindings,
      createRoleBindings,
      deleteRoleBindings,
      addRoleBindingToUsers,
      createRoleBinding,
      deleteRoleBinding,
      loadingRoleBindings,
    } = useRoles(),
    { users, listUsers } = useUsers(),
    { picture } = useUser(),
    { ACTIVE_WORKSPACE_NAME, activeWorkspace } = useWorkspaces(),
    [isAddUserDialogOpen, setIsAddUserDialogOpen] = React.useState(false),
    [isSomeItemSelected, setIsSomeItemSelected] = React.useState(false),
    [isAllSelected, setIsAllSelected] = React.useState(false),
    [selectedUser, setSelectedUser] = React.useState<UserItem>(),
    [dialogRoleOptions, setDialogRoleOptions] = React.useState<RoleOption[]>(),
    searchTimeoutRef = React.useRef<number>(),
    [bindingsByUser, setBindingsByUser] = React.useState<{ [key: string]: RoleBinding[] }>(),
    // TODO: Move outside.
    [userItems, setUserItems] = React.useState<UserItem[] | undefined>(),
    [searchUsersItems, setSearchUsersItems] = React.useState(userItems),
    [filterSearchUserItems, setFilterSearchUserItems] = React.useState<UserItem[]>(),
    [disabledOptions, setDisabledOptions] = React.useState<{ [userName: string]: { disabledOptions: string[] } }>({}),
    [isLoadingFilterSearch, setIsLoadingFilterSearch] = React.useState(false),
    searchCalloutRef = React.useRef<HTMLDivElement | null>(null),
    onSearchChange = React.useCallback(
      (searchText: string) =>
        setSearchUsersItems(
          userItems?.filter((item) =>
            `${item?.displayName}${item.email}`.toLowerCase().includes(searchText.trim().toLocaleLowerCase())
          )
        ),
      [userItems, setSearchUsersItems]
    ),
    onRoleChange = React.useCallback(
      (_event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (!option) return;
        setDisabledOptions((options) => {
          const alreadyDisabledOptions = options?.[option?.data?.userName]?.disabledOptions || [];
          return {
            ...options,
            [option?.data?.userName]: { disabledOptions: [...alreadyDisabledOptions, option?.key] || [] },
          };
        });
        if (option.selected) {
          createRoleBinding(`${option?.key}`, option?.data?.userName);
        } else {
          deleteRoleBinding(
            option?.data?.userRoleBindings?.find((binding: RoleBinding) => binding?.role === option?.key)
          );
        }
      },
      [createRoleBinding, deleteRoleBinding]
    ),
    onFilterSearchChange = React.useCallback(
      (searchText: string) => {
        setIsLoadingFilterSearch(!!searchText);
        if (searchTimeoutRef.current) window.clearTimeout(searchTimeoutRef.current);
        searchTimeoutRef.current = window.setTimeout(async () => {
          if (!searchText) {
            setFilterSearchUserItems([]);
            return;
          }
          try {
            const data = await listUsers(searchText);
            const userItems = data?.map((user, idx) => ({
              ...user,
              name: user.name || '',
              key: user.name || `user-item-${idx}`,
              email: user.emails?.map((email) => email.address).join(', '),
              // TODO: Use user picture.
              image: picture,
              roleBindings: bindingsByUser?.[user.name || ''],
              disabled: !!bindingsByUser?.[user.name || ''],
              onRoleChange,
            }));
            setFilterSearchUserItems(userItems);
          } catch (err) {
            const message = `Failed to fetch users: ${formatError(err)}`;
            console.error(message);
            addToast({
              messageBarType: MessageBarType.error,
              message,
            });
          } finally {
            searchTimeoutRef.current = undefined;
            setIsLoadingFilterSearch(false);
          }
        }, 500);
      },
      [listUsers, picture, bindingsByUser, setFilterSearchUserItems, onRoleChange]
    ),
    openAddUserDialog = () => setIsAddUserDialogOpen(true),
    closeAddUserDialog = () => {
      setIsAddUserDialogOpen(false);
      setDialogRoleOptions((options) => options?.map((role) => ({ ...role, selected: false })));
      setSelectedUser(undefined);
    },
    addUser = () => {
      createRoleBindings(
        (dialogRoleOptions || []).filter((role) => role.selected).map((role) => role.key),
        selectedUser?.name || ''
      );
      closeAddUserDialog();
    },
    onDeleteSelected = () => {
      const selectedUserItems = selection.getSelection() as UserItem[];
      const roleBindingsToDelete = selectedUserItems.reduce((acc, userItem) => {
        if (userItem.roleBindings) acc.push(...userItem.roleBindings);
        return acc;
      }, [] as RoleBinding[]);
      deleteRoleBindings(roleBindingsToDelete);
    },
    onAddRoleToSelected = (_ev?: any, item?: IContextualMenuItem) => {
      const selectedUserItems = selection.getSelection() as UserItem[];
      addRoleBindingToUsers(
        item?.key || '',
        selectedUserItems.map((userItem) => userItem.name)
      );
    },
    rowColumns = React.useMemo(
      () => [
        {
          key: 'name',
          fieldName: 'name',
          name: 'User',
          minWidth: 180,
          maxWidth: 260,
          onRender: ({ displayName, email, name }: UserItem) => (
            // TODO: Use picture of the actual user.
            <Persona
              key="name"
              imageUrl={picture}
              optionalText={name}
              secondaryText={email}
              size={12}
              text={displayName || name}
            />
          ),
        },
        {
          key: 'roleBindings',
          name: 'Roles',
          minWidth: 200,
          maxWidth: 300,
          onRender: ({ roleBindings, onRoleChange, name, disabledOptions }: UserItem) => (
            <div>
              <ComboBox
                options={[...(orchestratorRoles || []), ...(bootstrapRoles || [])].map((role) => ({
                  key: role.name || '',
                  text: role.displayName || '',
                  data: { userName: name, userRoleBindings: roleBindings },
                  // TODO: Check if needed.
                  selected: !!roleBindings?.find((roleBinding) => roleBinding?.role === role?.name),
                  disabled: disabledOptions?.includes(role.name || ''),
                }))}
                multiSelect
                selectedKey={(roleBindings || [])?.map((roleBinding) => roleBinding?.role || '')}
                onChange={onRoleChange}
                onRenderOption={(option) => {
                  return (
                    <div style={{ display: 'flex' }}>
                      <div style={{ marginRight: option?.disabled ? undefined : 16 }}>{option?.text}</div>
                      {option?.disabled ? (
                        <Loader
                          size={SpinnerSize.xSmall}
                          loaderContainerStyles={{ root: { alignContent: 'center', marginLeft: 4 } }}
                        />
                      ) : null}
                    </div>
                  );
                }}
              />
            </div>
          ),
        },
      ],
      [orchestratorRoles, bootstrapRoles, roleBindings]
    ),
    selection = React.useMemo(
      () =>
        new Selection({
          onSelectionChanged: () => {
            setIsSomeItemSelected(selection.getSelectedCount() > 0);
            setIsAllSelected(selection.isAllSelected());
          },
        }),
      []
    ),
    onRenderDetailsHeader = React.useCallback(
      (props, defaultRender) => (
        <>
          <div className={classNames.toolbar}>
            <div onClick={() => selection.toggleAllSelected()} style={{ zIndex: 1, margin: 8 }}>
              <Check checked={isAllSelected} />
            </div>
            <Search
              // TODO: Handle loading once items are fetched on search.
              // loadingMessage=""
              onSearchTextChange={onSearchChange}
              placeholder={`Search by user name or email address`}
              className={classNames.searchBar}
            />
            {isSomeItemSelected ? (
              <>
                <Button
                  text="Delete all roles"
                  iconProps={{ iconName: 'Delete', style: { fontSize: 16 } }}
                  styles={buttonStylesStealth}
                  className={classNames.toolbarButton}
                  onClick={onDeleteSelected}
                />
                <Button
                  text="Add role"
                  iconProps={{ iconName: 'Add', style: { fontSize: 16 } }}
                  styles={buttonStylesStealth}
                  className={classNames.toolbarButton}
                  menuItems={[...(orchestratorRoles || []), ...(bootstrapRoles || [])].map((role) => ({
                    key: role.name || '',
                    text: role.displayName || '',
                    onClick: onAddRoleToSelected,
                  }))}
                />
              </>
            ) : null}
          </div>
          {defaultRender?.({
            ...props!,
            selectAllVisibility: SelectAllVisibility.hidden,
            styles: {
              ...props!.styles,
              root: {
                padding: 0,
                margin: 0,
                height: 22,
                lineHeight: 22,
                backgroundColor: theme.semanticColors?.contentBackground,
                '.ms-DetailsHeader-cell': {
                  height: 22,
                },
              },
            },
          })}
        </>
      ),
      [isAllSelected, isSomeItemSelected, selection, onSearchChange, onDeleteSelected, onAddRoleToSelected]
    );

  React.useEffect(() => {
    setDialogRoleOptions(
      [...(orchestratorRoles || []), ...(bootstrapRoles || [])].map((role) => ({
        key: role.name || '',
        name: role.displayName || '',
        description: role.description || '',
        selected: false,
      }))
    );
  }, [orchestratorRoles, bootstrapRoles]);

  React.useEffect(() => {
    const bindingsByUser = roleBindings
      ?.filter((roleBinding) => roleBinding?.resource?.includes(ACTIVE_WORKSPACE_NAME || ''))
      ?.reduce((acc, roleBinding) => {
        if (roleBinding.subject) {
          if (!acc[roleBinding.subject]) acc[roleBinding.subject] = [];
          acc[roleBinding.subject].push(roleBinding);
        }
        return acc;
      }, {} as { [key: string]: RoleBinding[] });
    setBindingsByUser(bindingsByUser);
    // TODO: Improve by removing disabled options only for finished requests.
    setDisabledOptions({});
  }, [roleBindings, ACTIVE_WORKSPACE_NAME, setBindingsByUser]);

  React.useEffect(() => {
    if (users && bindingsByUser) {
      const userItems = users
        ?.filter((user) => bindingsByUser?.[user.name || ''])
        .map((user, idx) => ({
          ...user,
          name: user.name || '',
          key: user.name || `user-item-${idx}`,
          email: user.emails?.map((email) => email.address).join(', '),
          // TODO: Use user picture.
          image: picture,
          roleBindings: bindingsByUser?.[user.name || ''],
          onRoleChange,
          disabledOptions: disabledOptions?.[user.name || '']?.disabledOptions,
        }));
      setUserItems(userItems);
      setSearchUsersItems(userItems);
    }
  }, [users, picture, bindingsByUser, setUserItems, setSearchUsersItems, onRoleChange, disabledOptions]);

  return (
    <NavigationWrapper>
      <Header actionTitle="Add people" actionIcon="AddFriend" onActionClick={openAddUserDialog} />
      <div className={classNames.pageWrapper}>
        <ConfirmDialog
          title="Add user"
          hidden={!isAddUserDialogOpen}
          onConfirm={addUser}
          onDismiss={closeAddUserDialog}
          confirmationButtonDisabled={!selectedUser || !dialogRoleOptions?.some((role) => role.selected)}
          msg=""
          confirmationButtonText="Add"
          dismissalButtonText="Cancel"
          modalProps={{ isBlocking: false }}
          content={
            <>
              <Search
                placeholder="Search by name or email"
                onSearchTextChange={onFilterSearchChange}
                searchResultItems={filterSearchUserItems}
                hasSearchResult
                loadingMessage={isLoadingFilterSearch ? 'Searching users...' : undefined}
                onRenderSearchResultItemActions={(item) =>
                  !item.disabled ? (
                    <Button
                      text="Add"
                      iconProps={{ iconName: 'Add' }}
                      onClick={() => {
                        setSelectedUser(item);
                        // HACK: Dismiss callout.
                        searchCalloutRef.current?.parentElement?.click();
                      }}
                    />
                  ) : (
                    <p>User already added</p>
                  )
                }
                calloutProps={{
                  calloutMaxWidth: 519,
                  dismissOnTargetClick: true,
                  ref: searchCalloutRef,
                }}
                searchResultItemFields={{
                  titleField: 'displayName',
                  descriptionField: 'email',
                  idField: 'name',
                }}
              />
              <p style={{ margin: '30px 0px' }}>
                {selectedUser ? (
                  <>
                    Add <b>{selectedUser?.displayName}</b> {`(${selectedUser.email})`} into the workspace with the
                    following roles?
                  </>
                ) : (
                  'Please select the user first.'
                )}
              </p>
              {dialogRoleOptions ? (
                <Stack tokens={{ childrenGap: 10 }}>
                  {dialogRoleOptions.map((option) => (
                    <div key={option.key}>
                      <Checkbox
                        label={option.name}
                        styles={{ label: { fontWeight: 600 } }}
                        defaultChecked={option.selected}
                        disabled={!selectedUser}
                        onChange={(_ev, checked) => {
                          setDialogRoleOptions((options) =>
                            options?.map((role) => (role.key === option.key ? { ...role, selected: !!checked } : role))
                          );
                        }}
                      />
                      <p style={{ margin: 0, marginLeft: 28, marginTop: 4, marginBottom: 6, fontSize: 12 }}>
                        {option.description}
                      </p>
                    </div>
                  ))}
                </Stack>
              ) : (
                <p>Orchestrator roles could not be loaded. Please try again later or contact our support.</p>
              )}
            </>
          }
          // TODO: Add danger button style.
        />
        <WidgetItem
          data={{
            title: activeWorkspace?.displayName,
            description: 'Manage roles and permissions for users in this workspace',
            iconName: 'Favicon',
            id: 'workspace-name',
          }}
          descriptionField="description"
          iconNameField="iconName"
          titleField="title"
          idField={'id'}
        />
        {userItems?.length && searchUsersItems ? (
          <DetailsList
            columns={rowColumns}
            items={searchUsersItems || []}
            selectionMode={SelectionMode.multiple}
            checkboxVisibility={CheckboxVisibility.always}
            selection={selection}
            selectionPreservedOnEmptyClick
            onRenderDetailsHeader={onRenderDetailsHeader}
            styles={{ root: { minHeight: 450 } }}
          />
        ) : (
          <div className={classNames.noBindingContainer}>
            {userItems && searchUsersItems && !loadingRoleBindings ? (
              <>
                <Icon iconName="AddGroup" style={{ fontSize: 48, padding: 20 }} />
                {`No Orchestrator users are assigned to this workspace.\nStart with adding someone.`}
              </>
            ) : (
              <Loader label="Loading workspace users" />
            )}
          </div>
        )}
      </div>
    </NavigationWrapper>
  );
};

export default AccessControl;
