import { Loader, LoaderType } from '@h2oai/ui-kit';
import { SyntheticEvent } from 'react';

import { isNotEmpty } from '../../../../utils/utils';
import { FluentNumberField } from '../FluentNumberField/FluentNumberField';
import { LabelIconTooltip } from '../LabelIconTooltip/LabelIconTooltip';

/* Remove the suffix or any other text after the numbers, or return undefined if not a number
 * Borrowed from https://developer.microsoft.com/en-us/fluentui#/controls/web/spinbutton
 * (see "SpinButton with suffix and custom handlers" code example)
 */
const getNumericPart = (value: string): number | undefined => {
  const valueRegex = /^(\d+(\.\d+)?).*/;
  if (valueRegex.test(value)) {
    const numericValue = Number(value.replace(valueRegex, '$1'));
    return isNaN(numericValue) ? undefined : numericValue;
  }
  return undefined;
};

export default function SpinnerWithTooltip(props: {
  onChange: any;
  min?: number | string | null;
  max?: number | string | null;
  value: number;
  tooltip?: string;
  suffix?: string;
  disabled?: boolean;
  id?: string;
  loading?: boolean;
}) {
  const { onChange, value, suffix, tooltip, min, max, disabled, id, loading } = props;
  const minInt = !isNotEmpty(min) ? 0 : Number(min),
    maxInt = !isNotEmpty(max) ? 9999999999 : Number(max),
    onIncrement = (value: string): number | void => {
      /** Increment the value (or return nothing to keep the previous value if invalid) */
      const numericValue = getNumericPart(value);
      return numericValue !== undefined ? Math.min(numericValue + 1, maxInt) : undefined;
    },
    onDecrement = (value: string): number | void => {
      /** Decrement the value (or return nothing to keep the previous value if invalid) */
      const numericValue = getNumericPart(value);
      return numericValue !== undefined ? Math.max(numericValue - 1, minInt) : undefined;
    },
    onChangeInput = (e: SyntheticEvent<HTMLElement, Event>, value?: string | number) => {
      if (typeof value === 'undefined') return;
      const valueAsInt = typeof value === 'string' ? parseInt(value) : value;
      if (!isNaN(valueAsInt)) {
        if (valueAsInt < minInt) {
          onChange(e, minInt);
        } else if (valueAsInt > maxInt) {
          onChange(e, maxInt);
        } else {
          onChange(e, valueAsInt);
        }
      }
    };
  const inputValue = value > minInt ? value : minInt;

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', position: 'relative' }}>
      {loading ? (
        <Loader
          type={LoaderType.progressIndicator}
          style={{ position: 'absolute', width: '100%', top: '30px' }}
          styles={{
            root: { position: 'absolute', width: '100%', zIndex: 1, height: '2px' },
            itemProgress: { height: '2px' },
            progressTrack: { height: '2px', backgroundColor: 'transparent' },
          }}
        />
      ) : null}

      <FluentNumberField
        id={id}
        onChange={onChangeInput}
        onIncrement={onIncrement}
        onDecrement={onDecrement}
        value={suffix ? `${inputValue} ${suffix}` : `${inputValue}`}
        styles={{ root: { width: 116 } }}
        disabled={disabled}
      />
      {tooltip && <LabelIconTooltip tooltip={tooltip} />}
    </div>
  );
}
