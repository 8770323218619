import { Stack } from '@fluentui/react';
import { Fragment, ReactNode, useEffect, useMemo, useReducer, useState } from 'react';

import { EntitiesMap } from '../../../aiem/entity/services';
import { Entity, EntityActionType, EntityField, EntityFieldType, EntityType } from '../../../aiem/entity/types';
import { isNotEmpty } from '../../../utils/utils';
import { ValidationAction, ValidationReducerFunction, validationReducer } from './AddEditModelForm';
import {
  BooleanEntityModelField,
  EntityFieldInputProps,
  FormRow,
  NumberEntityModelField,
  SelectEnumEntityModelField,
  StringArrayEntityModelField,
} from './BasicEntityModelComponents';
import { ConstraintFieldsTable } from './ConstraintSetModelField';
import { DurationModelField } from './DurationModelField';
import { EntityDisplayAndId } from './EntityDisplayAndId';
import { KeyValuePairEntityModelField } from './KeyValuePairModelField';
import { EntityModelTypes, ExpandedEntityWithState } from './ListWithCollapsedSettings/types';
import { YamlEntityModelField } from './YamlEntityModelField';

export type EntityModelFieldListProps<EntityModel extends EntityModelTypes> = {
  entityType: EntityType;
  onChange: (model: EntityModel, isValid?: boolean) => void;
  model?: EntityModel;
  isCreate?: boolean;
  expandedEntityProps?: ExpandedEntityWithState<EntityModel>;
};

const constraintFields = [EntityFieldType.ConstraintDuration, EntityFieldType.ConstraintNumeric];

export const LargeAddEditForm = <EntityModel extends EntityModelTypes>(
  props: EntityModelFieldListProps<EntityModel>
) => {
  const { entityType, model: originalModel, isCreate = false } = props;
  const entity = EntitiesMap.get(entityType) as Entity<EntityModel>;
  const { fields } = entity;
  const [validationState, validationDispatch] = useReducer<ValidationReducerFunction>(validationReducer, {
    requiredFields: !isCreate,
    validId: !isCreate,
    isNew: isCreate,
  });

  const displayAndIdFilter = ({ type }: EntityField<EntityModel>) =>
    [EntityFieldType.DisplayOnDisplayAndId, EntityFieldType.IdOnDisplayAndId].includes(type);

  const [valid, setValid] = useState<boolean>();
  const [model, setModel] = useState<EntityModel | undefined>(originalModel ? { ...originalModel } : undefined);
  const displayAndIdFields = useMemo(() => {
    const result: { display: EntityField<EntityModel> | null; id: EntityField<EntityModel> | null } = {
      display: null,
      id: null,
    };

    fields.forEach((field) => {
      if ([EntityFieldType.DisplayOnDisplayAndId, EntityFieldType.IdOnDisplayAndId].includes(field.type)) {
        if (field.type === EntityFieldType.DisplayOnDisplayAndId) result.display = field;
        if (field.type === EntityFieldType.IdOnDisplayAndId) result.id = field;
      }
    });

    return result as { display: EntityField<EntityModel>; id: EntityField<EntityModel> };
  }, [fields]);
  const nonIdOrDisplayFields = useMemo(() => {
    return fields.filter(({ type }) => !displayAndIdFilter({ type } as EntityField<EntityModel>));
  }, [fields, displayAndIdFilter]);
  const requiredFields = useMemo(() => {
    return fields.filter((field) => field.required).map((field) => field.name as keyof EntityModel);
  }, [fields]);

  const onChange = (fieldName: keyof EntityModel, value: any) => {
    if (!model) return;
    setValid(false);
    const partial: Partial<EntityModel> = {};
    partial[fieldName] = value;
    const isRequiredFieldFilled = requiredFields.includes(fieldName) ? isNotEmpty(value) : true;
    const newModel = { ...model, ...partial };
    validationDispatch({
      type: ValidationAction.REQUIRED_FIELDS,
      value: requiredFields.every((field) => newModel[field]) && isRequiredFieldFilled,
    });
    validationDispatch({
      type: ValidationAction.IS_NEW,
      value: isCreate || JSON.stringify(model) !== JSON.stringify(newModel),
    });
    setModel({ ...model, ...partial });
    props.onChange(newModel, valid);
  };

  const getModelFieldList = () => {
    if (!model) return;
    return nonIdOrDisplayFields
      .filter(({ type }) => !constraintFields.includes(type))
      .map((field) => {
        const { type, name } = field;
        const props: EntityFieldInputProps<EntityModel> = {
          key: `${String(name)}-form-row`,
          entityType,
          field,
          model,
          onChange,
          isCreate: true,
        };

        let modelField: ReactNode | undefined;
        switch (type) {
          case EntityFieldType.StringArray:
            modelField = <StringArrayEntityModelField {...props} largeLabel />;
            break;
          case EntityFieldType.Boolean:
            modelField = <BooleanEntityModelField {...props} largeLabel />;
            break;
          case EntityFieldType.KeyValuePair:
            modelField = (
              <KeyValuePairEntityModelField {...props} large rootStyles={{ maxWidth: '100%' }} editorWidth={450} />
            );
            break;
          case EntityFieldType.Duration:
            modelField = <DurationModelField {...props} />;
            break;
          case EntityFieldType.Yaml:
            modelField = <YamlEntityModelField {...props} />;
            break;
          case EntityFieldType.Hidden:
            modelField = undefined;
            break;
          case EntityFieldType.Number:
            modelField = <NumberEntityModelField {...props} largeLabel />;
            break;
          case EntityFieldType.ConstraintDuration:
          case EntityFieldType.ConstraintNumeric:
            return <ConstraintFieldsTable {...props} fields={[field]} />;
          case EntityFieldType.SelectEnum:
            modelField = <SelectEnumEntityModelField {...props} largeLabel />;
            break;
          default:
            modelField = (
              <FormRow>{`${String(name)} is either not implemented or needs support extension here`}</FormRow>
            );
        }
        return <Fragment key={`${String(name)}-form-row`}>{modelField}</Fragment>;
      });
  };

  useEffect(() => {
    setValid(validationState.requiredFields && validationState.validId && validationState.isNew);
  }, [validationState, valid]);

  return (
    <Stack style={{ overflow: 'hidden' }}>
      {displayAndIdFields.display && displayAndIdFields.id && (
        <EntityDisplayAndId
          model={model}
          onDisplayNameChange={(value: string) => onChange(displayAndIdFields.display.name, value)}
          onIdChange={(value: string) => onChange(displayAndIdFields.id.name, value)}
          validate={(isValid: boolean) => {
            validationDispatch({ type: ValidationAction.VALID_ID, value: isValid });
          }}
          entityType={entityType}
          actionType={EntityActionType.Create}
          editableId={isCreate}
        />
      )}
      <ConstraintFieldsTable
        entityType={entityType}
        fields={fields.filter(({ type }) => constraintFields.includes(type))}
        model={model as EntityModel}
        onChange={onChange}
      />
      {getModelFieldList()}
    </Stack>
  );
};
